import Header from '@/components/Header/Header';
import { Outlet } from 'react-router-dom';
import { FC, memo, Suspense } from 'react';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import useHeaderHeight from '@/hooks/useHeaderHeight';
import Loader from '@/components/Loader/Loader';
import { ProjectFormDialogProvider } from '@/providers/ProjectFormDialogProvider';
import EmptyDocumentProjectProvider from '@/views/Project/providers/EmptyDocumentProjectProvider';
import KnowledgeProvider from '@/views/Knowledge/KnowledgeProvider';
import GlobalDocumentsUpload from '@/containers/GlobalDocumentsUpload';

const Root: FC = memo(function Root() {
  const { pageTitle } = usePageTitle();
  const headerHeight = useHeaderHeight();

  return (
    <EmptyDocumentProjectProvider>
      <KnowledgeProvider>
        <ProjectFormDialogProvider>
          <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            <Header />
            <Box component="main" sx={{ height: `calc(100vh - ${headerHeight}px)`, zIndex: 100, position: 'relative' }}>
              <Suspense fallback={<Loader id="root-Suspense" />}>
                <Outlet />
              </Suspense>
            </Box>

            <Box
              id="root-portal"
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 10000,
                pointerEvents: 'none',
              }}
            />
            {false && <GlobalDocumentsUpload />}
          </Box>
        </ProjectFormDialogProvider>
      </KnowledgeProvider>
    </EmptyDocumentProjectProvider>
  );
});

export default Root;
