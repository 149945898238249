import { AxiosError } from 'axios';
import { AnnotationBackendJSON } from 'pspdfkit';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { deepClone } from '@mui/x-data-grid/internals';
import { getSessionToken } from '@descope/react-sdk';
import {
  AnnotationsAndEquipmentResponse,
  batchDeleteEquipmentPiece,
  batchModifyEquipmentList,
  EquipmentDescriptionSearch,
  EquipmentPiece,
  getOrganizationEquipment,
  getProjectsIdDocumentsDocumentIdAnnotationsAndEquip,
  postProjectsIdDocumentsDocumentIdAnnotations,
  updateEquipmentPrice,
} from '@/api/generated';
import { removeClientDataFromAnnotations } from '@/utils/removeClientDataFromAnnotations';
import { getDefaultEquipmentStyle } from '@/store/utils/getDefaultEquipmentStyle';
import { convertAnnotationToEquipmentItem } from '@/store/utils/convertAnnotationToEquipmentItem';
import { createEquipmentFromConstructionItems } from '@/store/utils/createEquipmentFromConstructionItems';
import { setAnnotations, setCategories, setEquipments } from '@/store/pdfEditorSlice';
import { KeyProps, getKeySlugAndId } from '@/store/utils/getKeySlugAndId';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';
import { isAutoCountingAnnotation } from '@/containers/DocumentEditor/utils/isAutoCountingAnnotation';

type MagicSourceEventData = {
  status: 'success' | 'error';
  equipment_piece: EquipmentPiece;
  annotations: AnnotationBackendJSON[];
};

type CreateAnnotationsPayload = KeyProps & {
  annotations: AnnotationBackendJSON[];
  organizationId: string;
  modifyInsteadOfCreation?: boolean;
};

type UpdateAnnotationsPayload = CreateAnnotationsPayload;

type UpdateAnnotationsCategoryPayload = KeyProps & {
  annotations: AnnotationBackendJSON[];
  categoryData: {
    description: string;
    equipment_id: string;
    price_per_unit?: number;
    pound_per_ft?: number;
  };
  moveFromEquipmentIds: string[];
};

type DeleteAnnotationsPayload = KeyProps & {
  annotationsIds: string[];
};

type InitialLoadPayload = KeyProps;

type WaitForAutoCountReadyPayload = KeyProps & {
  annotation: AnnotationBackendJSON;
};

type UpdateEquipmentPricePayload = KeyProps & {
  equipmentId: string;
  price: number;
  description: string;
};

type DeleteEquipmentsPricePayload = KeyProps & {
  equipmentsIds: string[];
};

export const waitForAutoCountReady = createAppAsyncThunk<undefined, WaitForAutoCountReadyPayload>(
  'pdfEditor/waitForAutoCountReady',
  // @ts-expect-error
  (data, api) => {
    const { slug, documentId, annotation } = data;

    const equipmentId = annotation.customData!.groupAnnotationKey as string;
    const urlParams = new URLSearchParams();
    urlParams.set('annotation_id', annotation.id);
    urlParams.set('equipment_id', equipmentId);
    const sessionToken = getSessionToken();

    fetchEventSource(`${process.env.VITE_API_BASE_URL}/v1/projects/${slug}/equipment/${documentId}/magicwand?${urlParams}`, {
      headers: { Authorization: `Bearer ${sessionToken}` },
      onmessage: async event => {
        const { annotations } = JSON.parse(event.data) as MagicSourceEventData;
        api.dispatch(setAnnotations({ slug, documentId, annotations }));

        const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];
        const updatedEquipments = deepClone(getState().equipments);
        const prevEquipment = updatedEquipments[equipmentId];
        prevEquipment.constructing_equipment_items.push(
          ...annotations.map(ann =>
            convertAnnotationToEquipmentItem(ann, {
              description: prevEquipment.description,
              pricePerUnit: prevEquipment.price_per_unit,
              equipmentId,
              weightInPounds: 0,
            }),
          ),
        );
        updatedEquipments[equipmentId] = createEquipmentFromConstructionItems(prevEquipment.constructing_equipment_items, {
          equipmentId,
          prevEquipment,
          defaultAnnotationStyle: prevEquipment.defaultAnnotationStyle!,
          description: prevEquipment.description,
          pricePerUnit: prevEquipment.price_per_unit,
          isReady: true,
        });

        api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));

        batchModifyEquipmentList(slug, documentId, {
          eventType: 'update',
          equipment_ids: [equipmentId],
          equipment_pieces: [updatedEquipments[equipmentId]],
        });
      },
    });
  },
);

export const fetchAnnotationsAndEquipments = createAppAsyncThunk<
  AnnotationsAndEquipmentResponse,
  InitialLoadPayload,
  { rejectValue: AxiosError | Error }
>('pdfEditor/annotationsAndEquipments', async (data, api) => {
  const response = await getProjectsIdDocumentsDocumentIdAnnotationsAndEquip(data.slug, data.documentId);
  const annotations = response.annotations as AnnotationBackendJSON[];

  const keyProps = { slug: data.slug, documentId: data.documentId };
  api.dispatch(setAnnotations({ ...keyProps, annotations }));
  api.dispatch(setEquipments({ ...keyProps, equipments: response.equipment_list }));

  response.equipment_list
    .filter(equipment => !equipment.isReady)
    .map(equipment => {
      const [item] = equipment.constructing_equipment_items!;
      const autoCountAnnotation = annotations.find(
        annotation => annotation.id === item._id && isAutoCountingAnnotation(annotation),
      );
      if (!autoCountAnnotation) return;

      api.dispatch(waitForAutoCountReady({ ...keyProps, annotation: autoCountAnnotation }));
    });

  return response;
});

export const deleteEquipments = createAppAsyncThunk<undefined, DeleteEquipmentsPricePayload, { rejectValue: AxiosError }>(
  'pdfEditor/deleteEquipments',
  async (data, api) => {
    const { slug, documentId, equipmentsIds } = data;

    try {
      const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];
      const updatedEquipments = deepClone(getState().equipments);
      equipmentsIds.forEach(equipmentId => delete updatedEquipments[equipmentId]);
      api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));

      const { annotations: updatedAnnotations } = await batchDeleteEquipmentPiece(slug, documentId, equipmentsIds);
      api.dispatch(setAnnotations({ slug, documentId, annotations: updatedAnnotations as AnnotationBackendJSON[] }));
    } catch (error) {
      console.error('Error while deleting equipments', error);
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const updateEquipment = createAppAsyncThunk<undefined, UpdateEquipmentPricePayload, { rejectValue: AxiosError }>(
  'pdfEditor/updateEquipment',
  async (data, api) => {
    const { slug, documentId, equipmentId, price, description } = data;
    const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];

    try {
      await updateEquipmentPrice(
        slug,
        equipmentId,
        { equipment_id: equipmentId, price_per_unit: price, description },
        { document_id: documentId },
      );

      const updatedEquipments = cloneDeep(getState().equipments);
      if (!updatedEquipments[equipmentId].constructing_equipment_items) return;

      const prevEquipment = updatedEquipments[equipmentId];
      updatedEquipments[equipmentId].constructing_equipment_items.forEach(item => {
        item.price_per_unit = price;
        item.total_price = price;
      });
      updatedEquipments[equipmentId] = createEquipmentFromConstructionItems(
        updatedEquipments[equipmentId].constructing_equipment_items,
        {
          equipmentId,
          prevEquipment,
          defaultAnnotationStyle: prevEquipment.defaultAnnotationStyle!,
          description,
          pricePerUnit: price,
        },
      );

      api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));
    } catch (error) {
      console.error('Error while updating equipment price', error);
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const createAnnotations = createAppAsyncThunk<undefined, CreateAnnotationsPayload, { rejectValue: AxiosError }>(
  'pdfEditor/createAnnotations',
  async (data, api) => {
    const { slug, documentId, annotations: eventAnnotations, organizationId, modifyInsteadOfCreation } = data;
    const eventAnnotationIds = uniq(eventAnnotations.map(annotation => annotation.id));
    if (!eventAnnotationIds.length) return;

    const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];
    const getCategory = async (categoryId: string) => {
      const cachedCategory = api.getState().pdfEditor.categories[categoryId];
      if (cachedCategory) return cachedCategory;

      const loadedCategory = await getOrganizationEquipment(organizationId, categoryId);
      api.dispatch(setCategories([loadedCategory as EquipmentDescriptionSearch]));
      return loadedCategory;
    };

    try {
      const oldAnnotations = removeClientDataFromAnnotations(cloneDeep(getState()?.annotations ?? []));
      const newAnnotations = modifyInsteadOfCreation
        ? oldAnnotations.map(
            annotation => eventAnnotations.find(eventAnnotation => eventAnnotation.id === annotation.id) ?? annotation,
          )
        : [...oldAnnotations, ...eventAnnotations];
      api.dispatch(setAnnotations({ slug, documentId, annotations: newAnnotations }));
      await postProjectsIdDocumentsDocumentIdAnnotations(slug, documentId!, {
        eventType: 'create' as const,
        eventAnnotationIds,
        annotations: newAnnotations,
      });

      eventAnnotations
        .filter(isAutoCountingAnnotation)
        .forEach(autoCountAnnotation =>
          api.dispatch(waitForAutoCountReady({ slug, documentId, annotation: autoCountAnnotation })),
        );

      const annotationsByKeyMap = groupBy(eventAnnotations, 'customData.groupAnnotationKey');
      const annotationsWithEquipment = Object.entries(annotationsByKeyMap).filter(([key]) => !!key && key !== 'undefined');
      if (!annotationsWithEquipment.length) return;

      const nextEquipments = await Promise.all(
        annotationsWithEquipment.map(async ([equipmentId, annotations]) => {
          const { description, price_per_unit: pricePerUnit, pound_per_ft: weightInPounds } = await getCategory(equipmentId);

          const state = getState();
          const prevEquipment = state.equipments[equipmentId];
          const defaultAnnotationStyle = getDefaultEquipmentStyle(annotations[0]);

          const newConstructionItems = annotations.map(annotation =>
            convertAnnotationToEquipmentItem(annotation, { equipmentId, description, pricePerUnit, weightInPounds }),
          );
          return createEquipmentFromConstructionItems(
            [...(prevEquipment?.constructing_equipment_items ?? []), ...newConstructionItems],
            { equipmentId, prevEquipment, defaultAnnotationStyle, description, pricePerUnit },
          );
        }),
      );

      const state = getState();
      const updatedEquipments = cloneDeep(state.equipments);
      const equipmentsToSave: EquipmentPiece[] = [];
      const equipmentsToUpdate: EquipmentPiece[] = [];
      nextEquipments.forEach(nextEquipment => {
        if (state.equipments[nextEquipment._id]) {
          equipmentsToUpdate.push(nextEquipment);
        } else {
          equipmentsToSave.push(nextEquipment);
        }
        updatedEquipments[nextEquipment._id] = nextEquipment;
      });

      api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));

      if (equipmentsToSave.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'create',
          equipment_ids: equipmentsToSave.map(equipment => equipment._id),
          equipment_pieces: equipmentsToSave,
        });
      }

      if (equipmentsToUpdate.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'update',
          equipment_ids: equipmentsToUpdate.map(equipment => equipment._id),
          equipment_pieces: equipmentsToUpdate,
        });
      }
    } catch (error) {
      console.error('Error while creating annotations', error);
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const updateAnnotationsCategory = createAppAsyncThunk<
  undefined,
  UpdateAnnotationsCategoryPayload,
  { rejectValue: AxiosError }
>('pdfEditor/updateAnnotationsCategory', async (data, api) => {
  const { slug, documentId, annotations: eventAnnotations, categoryData, moveFromEquipmentIds } = data;
  const {
    description,
    equipment_id: moveToEquipmentId,
    price_per_unit: pricePerUnit = 0,
    pound_per_ft: weightInPounds = 0,
  } = categoryData;
  const eventAnnotationIds = uniq(eventAnnotations.map(annotation => annotation.id));
  if (!eventAnnotationIds.length) return;

  const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];

  try {
    const oldAnnotations = removeClientDataFromAnnotations(cloneDeep(getState()?.annotations ?? []));
    const newAnnotations = oldAnnotations.map(annotation => {
      if (!eventAnnotationIds.includes(annotation.id)) return annotation;

      return {
        ...cloneDeep(annotation),
        customData: { ...annotation.customData, groupAnnotationKey: moveToEquipmentId },
      };
    });

    api.dispatch(setAnnotations({ slug, documentId, annotations: newAnnotations }));
    await postProjectsIdDocumentsDocumentIdAnnotations(slug, documentId!, {
      eventType: 'update' as const,
      eventAnnotationIds,
      annotations: newAnnotations,
    });

    const state = getState();
    const updatedEquipments = cloneDeep(state.equipments);
    const prevMoveToEquipment = updatedEquipments[moveToEquipmentId];
    const defaultAnnotationStyle = getDefaultEquipmentStyle(eventAnnotations[0]);

    const equipmentsToSave: EquipmentPiece[] = [];
    const equipmentsToUpdate: EquipmentPiece[] = [];
    const equipmentsToDelete: string[] = [];

    const moveToEquipmentExists = !!updatedEquipments[moveToEquipmentId];
    const movedToItems = updatedEquipments[moveToEquipmentId]?.constructing_equipment_items ?? [];
    movedToItems.push(
      ...eventAnnotations.map(eventAnnotation =>
        convertAnnotationToEquipmentItem(eventAnnotation, {
          equipmentId: moveToEquipmentId,
          description,
          pricePerUnit: pricePerUnit!,
          weightInPounds,
        }),
      ),
    );
    const moveToEquipment = createEquipmentFromConstructionItems(movedToItems, {
      equipmentId: moveToEquipmentId,
      prevEquipment: prevMoveToEquipment,
      defaultAnnotationStyle,
      description,
      pricePerUnit: pricePerUnit!,
    });
    updatedEquipments[moveToEquipmentId] = moveToEquipment;
    if (moveToEquipmentExists) {
      equipmentsToUpdate.push(moveToEquipment);
    } else {
      equipmentsToSave.push(moveToEquipment);
    }

    moveFromEquipmentIds.forEach(moveFromEquipmentId => {
      const movedFromEquipment = updatedEquipments[moveFromEquipmentId];
      const movedFromItems = movedFromEquipment.constructing_equipment_items!.filter(
        item => !eventAnnotationIds.includes(item._id!),
      );

      if (movedFromItems.length === 0) {
        delete updatedEquipments[moveFromEquipmentId];
        equipmentsToDelete.push(moveFromEquipmentId);
      } else {
        updatedEquipments[moveFromEquipmentId] = createEquipmentFromConstructionItems(movedFromItems, {
          equipmentId: moveFromEquipmentId,
          prevEquipment: updatedEquipments[moveFromEquipmentId],
          defaultAnnotationStyle: updatedEquipments[moveFromEquipmentId].defaultAnnotationStyle!,
          description: updatedEquipments[moveFromEquipmentId].description,
          pricePerUnit: updatedEquipments[moveFromEquipmentId].price_per_unit ?? 0,
        });
        equipmentsToUpdate.push(movedFromEquipment);
      }
    });

    api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));

    if (equipmentsToDelete.length) {
      await batchModifyEquipmentList(slug, documentId, {
        eventType: 'delete',
        equipment_ids: equipmentsToDelete,
      });
    }

    if (equipmentsToSave.length) {
      await batchModifyEquipmentList(slug, documentId, {
        eventType: 'create',
        equipment_ids: equipmentsToSave.map(equipment => equipment._id),
        equipment_pieces: equipmentsToSave,
      });
    }

    if (equipmentsToUpdate.length) {
      await batchModifyEquipmentList(slug, documentId, {
        eventType: 'update',
        equipment_ids: equipmentsToUpdate.map(equipment => equipment._id),
        equipment_pieces: equipmentsToUpdate,
      });
    }
  } catch (error) {
    console.error('Error while updating annotations', error);
    return api.rejectWithValue(error as AxiosError);
  }
});

export const updateAnnotations = createAppAsyncThunk<undefined, UpdateAnnotationsPayload, { rejectValue: AxiosError }>(
  'pdfEditor/updateAnnotations',
  async (data, api) => {
    const { slug, documentId, annotations: eventAnnotations, organizationId } = data;
    const eventAnnotationIds = uniq(eventAnnotations.map(annotation => annotation.id));
    if (!eventAnnotationIds.length) return;

    const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];

    try {
      const oldAnnotations = removeClientDataFromAnnotations(cloneDeep(getState()?.annotations ?? []));
      const magicWandAnnotationsToRun: AnnotationBackendJSON[] = [];
      eventAnnotations.forEach(eventAnnotation => {
        if (!oldAnnotations.find(oldAnnotation => oldAnnotation.id === eventAnnotation.id)) {
          magicWandAnnotationsToRun.push(eventAnnotation);
          oldAnnotations.push(eventAnnotation);
        }
      });

      const newAnnotations = oldAnnotations.map(
        annotation => eventAnnotations.find(eventAnnotation => eventAnnotation.id === annotation.id) ?? annotation,
      );

      api.dispatch(setAnnotations({ slug, documentId, annotations: newAnnotations }));
      await postProjectsIdDocumentsDocumentIdAnnotations(slug, documentId!, {
        eventType: 'update' as const,
        eventAnnotationIds,
        annotations: newAnnotations,
      });

      const annotationsByKeyMap = groupBy(eventAnnotations, 'customData.groupAnnotationKey');
      const annotationsWithEquipment = Object.entries(annotationsByKeyMap).filter(([key]) => !!key && key !== 'undefined');
      if (!annotationsWithEquipment.length) return;

      const annotationsWithChangedEquipment = eventAnnotations.reduce<{ [annotationId: string]: string }>(
        (acc, eventAnnotation) => {
          const groupKey = eventAnnotation.customData?.groupAnnotationKey;
          if (!groupKey) return acc;

          const oldAnnotation = oldAnnotations.find(annotation => eventAnnotation.id === annotation.id);
          if (!oldAnnotation) {
            console.error("We have annotation to update though we don't have it in annotations list");
            return acc;
          }

          const oldGroupKey = oldAnnotation.customData?.groupAnnotationKey as string | undefined;
          if (oldGroupKey && oldGroupKey !== groupKey) {
            acc[eventAnnotation.id] = oldGroupKey;
          }

          return acc;
        },
        {},
      );

      const nextEquipments = await Promise.all(
        annotationsWithEquipment.map(async ([equipmentId, updatedAnnotations]) => {
          const {
            description,
            price_per_unit: pricePerUnit,
            pound_per_ft: weightInPounds,
          } = await getOrganizationEquipment(organizationId, equipmentId);

          const state = getState();
          const prevEquipment = state.equipments[equipmentId];
          const defaultAnnotationStyle = getDefaultEquipmentStyle(updatedAnnotations[0]);

          const updatedConstructionItems = (prevEquipment?.constructing_equipment_items ?? []).map(item => {
            const annotationItem = updatedAnnotations.find(annotation => item._id === annotation.id);
            return annotationItem
              ? convertAnnotationToEquipmentItem(annotationItem, { equipmentId, description, pricePerUnit, weightInPounds })
              : item;
          });

          const magicAnnotationToAddItem = magicWandAnnotationsToRun.find(
            magicAnnotation => equipmentId === magicAnnotation.customData?.groupAnnotationKey,
          );
          if (magicAnnotationToAddItem) {
            updatedConstructionItems.push(
              convertAnnotationToEquipmentItem(magicAnnotationToAddItem, {
                equipmentId,
                description,
                pricePerUnit,
                weightInPounds,
              }),
            );
          }

          updatedAnnotations.forEach(updatedAnnotation => {
            // If we move annotation from one equipment to another we need to add new item since it doesn't exists.
            if (annotationsWithChangedEquipment[updatedAnnotation.id]) {
              updatedConstructionItems.push(
                convertAnnotationToEquipmentItem(updatedAnnotation, { equipmentId, description, pricePerUnit, weightInPounds }),
              );
            }
          });

          return createEquipmentFromConstructionItems(updatedConstructionItems, {
            equipmentId,
            prevEquipment,
            defaultAnnotationStyle,
            description,
            pricePerUnit,
          });
        }),
      );

      const state = getState();
      const updatedEquipments = cloneDeep(state.equipments);
      const equipmentsToSave: EquipmentPiece[] = [];
      const equipmentsToUpdate: EquipmentPiece[] = [];
      const equipmentsToDelete: string[] = [];

      Object.entries(annotationsWithChangedEquipment).forEach(([annotationId, groupKey]) => {
        updatedEquipments[groupKey].constructing_equipment_items = updatedEquipments[
          groupKey
        ].constructing_equipment_items?.filter(item => item._id !== annotationId);
        updatedEquipments[groupKey].constructing_annotation_ids = updatedEquipments[groupKey].constructing_annotation_ids?.filter(
          id => id !== annotationId,
        );

        if (updatedEquipments[groupKey]?.constructing_equipment_items?.length) {
          equipmentsToUpdate.push(updatedEquipments[groupKey]);
        } else {
          delete updatedEquipments[groupKey];
          equipmentsToDelete.push(groupKey);
        }
      });

      nextEquipments.forEach(nextEquipment => {
        if (state.equipments[nextEquipment._id]) {
          equipmentsToUpdate.push(nextEquipment);
        } else {
          equipmentsToSave.push(nextEquipment);
        }
        updatedEquipments[nextEquipment._id] = nextEquipment;
      });

      api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(updatedEquipments) }));

      if (equipmentsToDelete.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'delete',
          equipment_ids: equipmentsToDelete,
        });
      }

      if (equipmentsToSave.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'create',
          equipment_ids: equipmentsToSave.map(equipment => equipment._id),
          equipment_pieces: equipmentsToSave,
        });
      }

      if (equipmentsToUpdate.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'update',
          equipment_ids: equipmentsToUpdate.map(equipment => equipment._id),
          equipment_pieces: equipmentsToUpdate,
        });
      }

      magicWandAnnotationsToRun.forEach(magicWandAnnotation => {
        api.dispatch(waitForAutoCountReady({ slug, documentId, annotation: magicWandAnnotation }));
      });
    } catch (error) {
      console.error('Error while updating annotations', error);
      return api.rejectWithValue(error as AxiosError);
    }
  },
);

export const deleteAnnotations = createAppAsyncThunk<undefined, DeleteAnnotationsPayload, { rejectValue: AxiosError }>(
  'pdfEditor/deleteAnnotations',
  async (data, api) => {
    const { slug, documentId, annotationsIds } = data;
    if (!annotationsIds.length) return;

    const getState = () => api.getState().pdfEditor.projects[getKeySlugAndId(data)];

    try {
      const oldAnnotations = removeClientDataFromAnnotations(cloneDeep(getState()?.annotations ?? []));
      const newAnnotations = oldAnnotations.filter(oldAnnotation => !annotationsIds.includes(oldAnnotation.id));
      await postProjectsIdDocumentsDocumentIdAnnotations(slug, documentId!, {
        eventType: 'delete' as const,
        eventAnnotationIds: annotationsIds,
        annotations: newAnnotations,
      });

      api.dispatch(setAnnotations({ slug, documentId, annotations: newAnnotations }));

      const equipmentsToUpdate: EquipmentPiece[] = [];
      const equipmentsToDelete: string[] = [];
      const nextEquipments = Object.fromEntries<EquipmentPiece>(
        Object.entries<EquipmentPiece | undefined>(getState().equipments)
          .map(([key, prevEquipment]) => {
            if (!prevEquipment) return null;

            const updatedItems =
              prevEquipment.constructing_equipment_items?.filter(item => !annotationsIds.includes(item._id)) ?? [];
            if (updatedItems.length === 0) {
              equipmentsToDelete.push(prevEquipment._id!);
              return null;
            }

            if (updatedItems.length === prevEquipment.constructing_equipment_items?.length) {
              return [key, prevEquipment] as const;
            }

            const equipmentId = prevEquipment._id ?? crypto.randomUUID();
            const description = prevEquipment.description ?? '';
            const pricePerUnit = prevEquipment?.price_per_unit ?? 0;
            const updatedEquipment = createEquipmentFromConstructionItems(updatedItems, {
              prevEquipment,
              equipmentId,
              description,
              pricePerUnit,
              defaultAnnotationStyle: prevEquipment.defaultAnnotationStyle!,
            });
            equipmentsToUpdate.push(updatedEquipment);

            return [key, updatedEquipment] as const;
          })
          .filter(item => !!item),
      );

      api.dispatch(setEquipments({ slug, documentId, equipments: Object.values(nextEquipments) }));

      if (equipmentsToDelete.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'delete',
          equipment_ids: equipmentsToDelete,
        });
      }

      if (equipmentsToUpdate.length) {
        await batchModifyEquipmentList(slug, documentId, {
          eventType: 'update',
          equipment_ids: equipmentsToUpdate.map(equipment => equipment._id),
          equipment_pieces: equipmentsToUpdate,
        });
      }
    } catch (error) {
      console.error('Error while deleting annotations', error);
      return api.rejectWithValue(error as AxiosError);
    }
  },
);
