/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PellesAI backend service | Staging
 * OpenAPI spec version: v0.1.335-114-g7e0c81bb-magicwand-with-sse
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { customAxios } from './customAxios';
export type DailyReportParams = {
organizations_to_skip?: string;
days_back?: number;
};

export type Login200 = { [key: string]: unknown };

export type LoginParams = {
access_key: string;
};

export type UploadVectorDbToBlobParams = {
directory: string;
};

export type DeleteFilesFromBlobParams = {
directory: string;
};

export type UpdateDocumentPagesNamesParams = {
document_id: string;
};

export type ChatWithImageParams = {
query: string;
document_id?: unknown;
page_number?: unknown;
};

export type UploadFileWithVdbResearchParams = {
document_id: string;
};

export type TestParams = {
slug?: string;
};

export type ImportAutodeskFileParams = {
versioned_file_id?: string;
is_latest_version?: boolean;
};

export type GetAutodeskFilesParams = {
hub_id: string;
project_id: string;
folder_id?: string;
};

export type GetAutodeskProjectsParams = {
hub_id: string;
};

export type ListFilesParams = {
folder_id?: string;
};

export type ImportDocumentParams = {
versioned_file_id?: string;
is_latest_version?: boolean;
};

export type ImportSharepointFileParams = {
versioned_file_id?: string;
is_latest_version?: boolean;
};

export type GetSharepointFilesParams = {
site_id: string;
folder_id?: string;
};

export type ImportMicrosoftFileParams = {
versioned_file_id?: string;
is_latest_version?: boolean;
};

export type GetMicrosoftFilesParams = {
folder_id?: string;
};

export type GetLearningCenterItemsByTypeParams = {
item_type: LearningCenterItemType;
};

export type GetMentionsByTypeParams = {
id?: string | null;
mention_type?: MentionType;
};

export type UpdateCompareResultDone200 = ExactCompare | SemanticCompare;

export type GetCompareById201 = ExactCompare | OverlayCompare | SemanticCompare;

export type DeleteFileParams = {
file_id: string;
scan_filesystem?: boolean;
};

export type UpdateFilesystemObjectBody = Folder | File;

export type UpdateFilesystemBatchBodyItem = Folder | File;

export type DeleteFolderParams = {
folder_id: string;
scan_filesystem?: boolean;
};

export type GetOrganizationEquipmentDescriptionParams = {
annotation_type: PSPDFKitAnnotation;
description?: string;
limit?: number;
offset?: number;
};

export type GetRecentItemsParams = {
days_back?: number;
max_items?: number;
offset?: number;
};

export type GetUserEventsParams = {
user_id: string;
start_date?: string;
end_date?: string;
};

export type GetProjectEventsParams = {
project_id: string;
start_date?: string;
end_date?: string;
};

export type GetCompareQuestionsSuggestionsParams = {
question?: string;
limit?: number;
offset?: number;
slug?: string;
};

export type RemoveSuggestedActionFromFavoritesParams = {
suggested_action_id: string;
slug?: string;
};

export type SetSuggestedActionsFavoritesParams = {
slug?: string;
};

export type EditSuggestedActionFavoriteParams = {
slug?: string;
};

export type AddSuggestedActionToFavoritesParams = {
slug?: string;
};

export type GetSuggestedActionsFavoritesParams = {
slug?: string;
};

export type GetHomepageActionsParams = {
slug: string;
};

export type RoundToRectParams = {
diameter: number;
height?: number;
};

export type RectToRoundParams = {
width: number;
height: number;
};

export type CleanDeletedDocumentsFromProjectParams = {
slug: string;
skip_document_id?: string[];
};

export type DeleteDocumentFromVectorstoreParams = {
slug: string;
document_id: string;
};

export type DeleteDocumentParams = {
slug: string;
document_id: string;
};

export type DeleteAllProjectsFromUserParams = {
user_id: string;
slug?: string;
};

export type DeleteProjectParams = {
slug: string;
};

export type RerunOrgDocsParams = {
org_id: string;
update_pages_names?: boolean;
};

export type UpdateOrgQuotaParams = {
org_id: string;
};

export type GetOrgQuotaStatusParams = {
org_id: string;
};

export type GetOrganizationTagsOrgIdParams = {
tag_name?: string;
type?: TagType;
subtype?: string;
};

export type GetOrganizationMapping200 = {[key: string]: string};

export type UpdateEquipmentPriceParams = {
document_id: string;
};

export type AutoDetectEquipmentStatusParams = {
document_id: string;
};

export type AutoDetectEquipmentParams = {
document_id: unknown;
};

export type RunMagicwandSseParams = {
annotation_id: string;
equipment_id: string;
};

export type DeleteEquipmentPieceParams = {
equipment_id: string;
};

export type ConvertPdfToPagesParams = {
document_id: unknown;
format?: unknown;
};

export type SetPageIsPrivateParams = {
is_private: boolean;
};

export type DuplicatePageParams = {
to_page_type: PageType;
};

export type GetProjectsSlugAutomateThreadParams = {
thread_id?: string;
};

export type PostProjectsSlugAutomateChatWithAutomateNewQuestionParams = {
question: string;
thread_id?: string;
};

export type SearchUsersParams = {
limit?: number;
offset?: number;
};

export type GetDocumentStatusParams = {
project_id: string;
};

export type RerunDocsParams = {
project_id?: string;
document_id?: string;
};

export type RenamePageParams = {
page_number: number;
new_name: string;
};

export type RenameDocumentParams = {
new_name: string;
};

export type RemoveFromFavoritesParams = {
page_number: number;
};

export type AddToFavoritesParams = {
page_number: number;
};

export type UpdateAnnotationsListInDoc201 = { [key: string]: unknown };

export type PostProjectsIdDocumentsDocumentIdAnnotations201 = { [key: string]: unknown };

export type GetProjectsIdDocumentsDocumentIdAnnotations200Item = { [key: string]: unknown };

export type GetProjectsIdDocumentsDocumentIdParams = {
document_id: string;
signed?: boolean;
sign_urls?: boolean;
pages_number?: number[];
};

export type UploadFileWithVdbParams = {
parentId?: string;
order?: number;
versioned_file_id?: string;
is_latest_version?: boolean;
};

export type RefreshQueriesAnswerParams = {
extend_context?: boolean;
};

export type PostProjectsIdQueriesQueryIdSuggestParams = {
query: string;
};

export type PostProjectsIdQueriesQueryIdMatchMatchIdParams = {
feedback: Feedback;
};

export type PostProjectsIdQueriesQueryIdFeedbackParams = {
feedback: Feedback;
};

export type PostProjectsIdQueriesQueryIdStatusParams = {
query_status: QueryStatus;
};

export type PullAndExecuteChecklistByIdBody = string[] | null;

export type PostProjectsIdQueriesNewQuestionParams = {
query_mode?: QueryMode;
};

export type GetDocumentQuotaStatusParams = {
include_deleted?: boolean;
};

export type UnshareProjectParams = {
user_id: string;
};

export type GetProjectsParams = {
sortBy?: string;
nameFilter?: string;
statusFilter?: Status;
typeFilter?: string;
scopeFilter?: string;
ownerFilter?: string;
cityFilter?: string;
stateFilter?: string;
searchFilter?: string;
sortDir?: string;
offset?: number;
limit?: number;
};

export type ModelsThreadMessageUseWeb = boolean | null;

export type ModelsThreadMessageCreator = BaseUser | AutomateUser;

export interface ModelsThreadMessage {
  content: string;
  created_at?: string;
  creator: ModelsThreadMessageCreator;
  run_id?: string;
  type?: string;
  use_web?: ModelsThreadMessageUseWeb;
}

export interface ModelsAutomateMessage {
  content: string;
  created_at?: string;
  creator: BaseUser;
  run_id?: string;
  type?: string;
}

export type AnnotationEventType = typeof AnnotationEventType[keyof typeof AnnotationEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationEventType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
} as const;

export interface Version {
  id: string;
  last_modified?: string;
  version_number: number;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserUpdateUsername = string | null;

export type UserUpdateTitle = string | null;

export type UserUpdateTags = Tag[] | null;

export type UserUpdateProjects = string[] | null;

export type UserUpdateLastName = string | null;

export type UserUpdateFirstName = string | null;

export interface UserUpdate {
  firstName?: UserUpdateFirstName;
  lastName?: UserUpdateLastName;
  projects?: UserUpdateProjects;
  tags?: UserUpdateTags;
  title?: UserUpdateTitle;
  username?: UserUpdateUsername;
}

export type UserResponseProjects = string[] | null;

export type UserResponseHashedPassword = string | null;

export interface UserOrganization {
  id: string;
  logo: OrgLogoResponse;
  name: string;
  slug: string;
}

export interface UserResponse {
  _id?: string;
  account_type?: AccountType;
  algo_settings?: AlgorithmSettings;
  avatar?: string;
  compare_questions?: string[];
  email: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName: string;
  hashed_password?: UserResponseHashedPassword;
  isAdmin?: boolean;
  lastName: string;
  organization: UserOrganization;
  projects?: UserResponseProjects;
  tags?: Tag[];
  title?: string;
  username: string;
}

export type UserProjects = string[] | null;

export type UserHashedPassword = string | null;

export interface User {
  _id?: string;
  account_type?: AccountType;
  algo_settings?: AlgorithmSettings;
  avatar?: string;
  compare_questions?: string[];
  email: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName: string;
  hashed_password?: UserHashedPassword;
  isAdmin?: boolean;
  lastName: string;
  projects?: UserProjects;
  tags?: Tag[];
  title?: string;
  username: string;
}

export type UnitType = typeof UnitType[keyof typeof UnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitType = {
  pieces: 'pieces',
  linear: 'linear',
  area: 'area',
} as const;

export type UnitSystem = typeof UnitSystem[keyof typeof UnitSystem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitSystem = {
  dimensionless: 'dimensionless',
  metric: 'metric',
  imperial: 'imperial',
} as const;

export interface ToolsListObject {
  description: string;
  name: string;
}

export type ThreadMetadataTitle = string | null;

export type ThreadMetadataProjectId = string | null;

export type ThreadMetadataOrgId = string | null;

export type ThreadMetadataDescription = string | null;

export interface ThreadMetadata {
  _id?: string;
  description?: ThreadMetadataDescription;
  last_modified?: string;
  org_id?: ThreadMetadataOrgId;
  org_slug: string;
  project_id?: ThreadMetadataProjectId;
  thread_id: string;
  title?: ThreadMetadataTitle;
}

export type ThreadTitle = string | null;

export type ThreadProjectId = string | null;

export type ThreadOrgId = string | null;

export type ThreadDescription = string | null;

export interface Thread {
  _id?: string;
  description?: ThreadDescription;
  last_modified?: string;
  messages?: ModelsThreadMessage[];
  org_id?: ThreadOrgId;
  org_slug: string;
  project_id?: ThreadProjectId;
  thread_id: string;
  title?: ThreadTitle;
}

export interface TemplateParams {
  template_id: string;
}

export type TagType = typeof TagType[keyof typeof TagType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagType = {
  persona: 'persona',
  query: 'query',
  equipment: 'equipment',
  project: 'project',
} as const;

export type TagTagIcon = string | null;

export type TagSubType = string | null;

export type TagDescription = string | null;

export interface Tag {
  color?: string;
  createdAt?: string;
  createdBy?: string;
  description?: TagDescription;
  name: string;
  subType?: TagSubType;
  tag_icon?: TagTagIcon;
  type: TagType;
}

export type SuggestionType = typeof SuggestionType[keyof typeof SuggestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SuggestionType = {
  question: 'question',
  action: 'action',
} as const;

export interface SuggestedActionResponse {
  /** The properties to be used for the action */
  action_props: ActionProps;
  /** The text to display to the user for the suggested action */
  display_text: string;
  /** The type of action to suggest from the list of possible actions */
  type: ActionType;
}

/**
 * The order of the favorite action in the list of favorites
 */
export type SuggestedActionFavoriteOrder = number | null;

export type SuggestedActionFavoriteActionProps = ActionProps | null;

export interface SuggestedActionFavorite {
  _id?: string;
  action_props?: SuggestedActionFavoriteActionProps;
  /** The text to display to the user for the suggested action */
  display_text: string;
  /** Whether the action is a favorite action */
  is_favorite?: boolean;
  /** The order of the favorite action in the list of favorites */
  order?: SuggestedActionFavoriteOrder;
  /** The type of action to suggest from the list of possible actions */
  type: ActionType;
}

export type Status = typeof Status[keyof typeof Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  draft: 'draft',
  active: 'active',
  done: 'done',
} as const;

export interface State {
  context?: Context;
  source: Source;
}

export type StakeholderPhone = string | null;

export type StakeholderName = string | null;

export type StakeholderEmail = string | null;

export type StakeholderCompany = string | null;

export interface Stakeholder {
  company?: StakeholderCompany;
  email?: StakeholderEmail;
  name?: StakeholderName;
  phone?: StakeholderPhone;
  role: string;
}

export type Source = typeof Source[keyof typeof Source];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Source = {
  double_check: 'double_check',
  automate: 'automate',
  create: 'create',
  org_knowledge: 'org_knowledge',
  count_and_measure: 'count_and_measure',
  create_follow_ups: 'create_follow_ups',
  homepage: 'homepage',
} as const;

export interface Site {
  hostname?: string;
  id: string;
  name: string;
}

export type SheetMetadataUrl = string | null;

export type SheetMetadataSheetNumber = string | null;

export type SheetMetadataPageName = string | null;

export type SheetMetadataPageDimensions = PageDimensions | null;

export type SheetMetadataOutlineSections = string[] | null;

export interface SheetMetadata {
  outline_sections?: SheetMetadataOutlineSections;
  page_dimensions?: SheetMetadataPageDimensions;
  page_name?: SheetMetadataPageName;
  sheet_number?: SheetMetadataSheetNumber;
  url?: SheetMetadataUrl;
}

export type SemanticCompareSettingsQuestions = string[] | null;

export type SemanticCompareSettingsFeelingLucky = boolean | null;

export interface SemanticCompareSettings {
  document_a: CompareDocument;
  document_b: CompareDocument;
  feeling_lucky?: SemanticCompareSettingsFeelingLucky;
  questions?: SemanticCompareSettingsQuestions;
}

export type SemanticCompareResultSummary = string | null;

export type SemanticCompareResultSeverity = string | null;

export type SemanticCompareResultIsChange = boolean | null;

export type SemanticCompareResultClassification = string | null;

export interface SemanticCompareResult {
  classification?: SemanticCompareResultClassification;
  document_a_matches?: Match[];
  document_b_matches?: Match[];
  id?: string;
  is_change?: SemanticCompareResultIsChange;
  isDone?: boolean;
  severity?: SemanticCompareResultSeverity;
  summary?: SemanticCompareResultSummary;
  tags?: Tag[];
}

export type SemanticCompareCreateDescription = string | null;

export interface SemanticCompareCreate {
  description?: SemanticCompareCreateDescription;
  name: string;
  settings: SemanticCompareSettings;
  type?: CompareType;
}

export type SemanticCompareVersion = number | null;

export type SemanticCompareType = typeof SemanticCompareType[keyof typeof SemanticCompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SemanticCompareType = {
  semantic: 'semantic',
} as const;

export type SemanticCompareIsPrivate = boolean | null;

export type SemanticCompareDescription = string | null;

export interface SemanticCompare {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: SemanticCompareDescription;
  isPrivate?: SemanticCompareIsPrivate;
  isReady?: boolean;
  last_modified?: string;
  name: string;
  order?: number;
  project_id: string;
  results: SemanticCompareResult[];
  settings: SemanticCompareSettings;
  status?: CompareStatus;
  tags?: Tag[];
  type: SemanticCompareType;
  version?: SemanticCompareVersion;
}

export type Role = typeof Role[keyof typeof Role];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  viewer: 'viewer',
  editor: 'editor',
} as const;

export interface ReadyForQueries {
  docs_in_progress?: DocInProcess[];
  isReady: boolean;
}

export type RagModel = typeof RagModel[keyof typeof RagModel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RagModel = {
  openai: 'openai',
  anthropic: 'anthropic',
  azure: 'azure',
  llama: 'llama',
  mistral: 'mistral',
  pelles: 'pelles',
} as const;

export interface QuotaTracking {
  max?: number;
  used?: number;
}

export type QuotaMaxSizePerProjectMb = number | null;

export type QuotaMaxProjects = number | null;

export type QuotaMaxDocumentsPerProject = number | null;

export type QuotaAiTokens = number | null;

export interface Quota {
  ai_tokens?: QuotaAiTokens;
  max_documents_per_project?: QuotaMaxDocumentsPerProject;
  max_projects?: QuotaMaxProjects;
  max_size_per_project_mb?: QuotaMaxSizePerProjectMb;
}

export interface QuestionSuggestions {
  suggestions: string[];
}

export interface QuestionReceive {
  mentions?: Mention[];
  question: string;
  reference?: string;
}

export interface Question {
  score?: number;
  suggestion_type?: SuggestionType;
  text: string;
}

export type QueryStatus = typeof QueryStatus[keyof typeof QueryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryStatus = {
  open: 'open',
  done: 'done',
} as const;

export type QueryQuestionTags = Tag[] | null;

export type QueryQuestionReferenceIsAnomaly = boolean | null;

export type QueryQuestionReference = string | null;

export interface QueryQuestion {
  _id?: string;
  question: string;
  reference?: QueryQuestionReference;
  referenceIsAnomaly?: QueryQuestionReferenceIsAnomaly;
  tags?: QueryQuestionTags;
}

export type QueryPriority = typeof QueryPriority[keyof typeof QueryPriority];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryPriority = {
  low: 'low',
  mid: 'mid',
  high: 'high',
} as const;

export type QueryMode = typeof QueryMode[keyof typeof QueryMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryMode = {
  quick: 'quick',
  thorough: 'thorough',
  concise: 'concise',
} as const;

export type QueryLiteEnrichedQuestion = string | null;

export type QueryLiteCreatedBy = BaseUser | string | null;

export type QueryLiteAnswer = string | null;

export interface QueryLite {
  _id?: string;
  algo_settings?: AlgorithmSettings;
  answer?: QueryLiteAnswer;
  createdBy?: QueryLiteCreatedBy;
  enriched_question?: QueryLiteEnrichedQuestion;
  followup_questions?: FollowupQuestions;
  isReady?: boolean;
  last_modified?: string;
  matches?: MetaMatch[];
  mentions?: Mention[];
  priority?: QueryPriority;
  question: string;
  status?: QueryStatus;
  tags?: Tag[];
}

export type QueryEnrichedQuestion = string | null;

export type QueryCreatedBy = BaseUser | string | null;

export type QueryAnswer = string | null;

export interface Query {
  _id?: string;
  algo_settings?: AlgorithmSettings;
  answer?: QueryAnswer;
  createdBy?: QueryCreatedBy;
  enriched_question?: QueryEnrichedQuestion;
  followup_questions?: FollowupQuestions;
  isReady?: boolean;
  last_modified?: string;
  matches?: Match[];
  mentions?: Mention[];
  priority?: QueryPriority;
  question: string;
  status?: QueryStatus;
  tags?: Tag[];
}

export type ProjectsListFiltersAnyOf = { [key: string]: unknown };

export type ProjectsListFilters = ProjectsListFiltersAnyOf | null;

export interface ProjectsList {
  filters?: ProjectsListFilters;
  projects?: ProjectResponse[];
  total?: number;
}

export type ProjectUpdateUserId = string | null;

export type ProjectUpdateUser = string | null;

export type ProjectUpdateUnitSystem = UnitSystem | null;

export type ProjectUpdateType = string | null;

export type ProjectUpdateTakeoffEstimatedCost = number | null;

export type ProjectUpdateTags = Tag[] | null;

export type ProjectUpdateSummary = string | null;

export type ProjectUpdateStatus = Status | null;

export type ProjectUpdateStatisticAnyOf = { [key: string]: unknown };

export type ProjectUpdateStatistic = ProjectUpdateStatisticAnyOf | null;

export type ProjectUpdateStakeholders = Stakeholder[] | null;

export type ProjectUpdateSlug = string | null;

export type ProjectUpdateScope = string[] | null;

export type ProjectUpdateProjectSize = string | null;

export type ProjectUpdateOwner = string | null;

export type ProjectUpdateOrganizationId = string | null;

export type ProjectUpdateNotesAnyOf = {[key: string]: Note};

export type ProjectUpdateNotes = ProjectUpdateNotesAnyOf | null;

export type ProjectUpdateName = string | null;

export type ProjectUpdateMemos = Memo[] | null;

export type ProjectUpdateLocation = ProjectLocation | null;

export type ProjectUpdateIsKnowledgeBase = boolean | null;

export type ProjectUpdateImage = string | null;

export type ProjectUpdateDeliveryMethod = string | null;

export type ProjectUpdateDefaultDocumentId = string | null;

export type ProjectUpdateCollaborateWith = BaseUser[] | null;

export type ProjectUpdateBidDueDate = string | null;

export type ProjectUpdateAddress = string | null;

export interface ProjectUpdate {
  address?: ProjectUpdateAddress;
  bidDueDate?: ProjectUpdateBidDueDate;
  collaborate_with?: ProjectUpdateCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectUpdateDefaultDocumentId;
  delivery_method?: ProjectUpdateDeliveryMethod;
  image?: ProjectUpdateImage;
  is_knowledge_base?: ProjectUpdateIsKnowledgeBase;
  last_modified?: string;
  location?: ProjectUpdateLocation;
  memos?: ProjectUpdateMemos;
  name?: ProjectUpdateName;
  notes?: ProjectUpdateNotes;
  organization_id?: ProjectUpdateOrganizationId;
  owner?: ProjectUpdateOwner;
  project_size?: ProjectUpdateProjectSize;
  scope?: ProjectUpdateScope;
  slug?: ProjectUpdateSlug;
  stakeholders?: ProjectUpdateStakeholders;
  statistic?: ProjectUpdateStatistic;
  status?: ProjectUpdateStatus;
  summary?: ProjectUpdateSummary;
  tags?: ProjectUpdateTags;
  takeoff_estimated_cost?: ProjectUpdateTakeoffEstimatedCost;
  type?: ProjectUpdateType;
  unit_system?: ProjectUpdateUnitSystem;
  user?: ProjectUpdateUser;
  user_id?: ProjectUpdateUserId;
}

export type ProjectResponseUserId = string | null;

export type ProjectResponseUser = string | null;

export type ProjectResponseUnitSystem = UnitSystem | null;

export type ProjectResponseType = string | null;

export type ProjectResponseTags = Tag[] | null;

export type ProjectResponseSummary = string | null;

export type ProjectResponseStatus = Status | null;

export type ProjectResponseStatistic = { [key: string]: unknown };

export type ProjectResponseStakeholders = Stakeholder[] | null;

export type ProjectResponseScope = string[] | null;

export type ProjectResponseOwner = string | null;

export type ProjectResponseOrganizationId = string | null;

export type ProjectResponseNotesAnyOf = {[key: string]: Note};

export type ProjectResponseNotes = ProjectResponseNotesAnyOf | null;

export type ProjectResponseName = string | null;

export type ProjectResponseMemos = Memo[] | null;

export type ProjectResponseLocation = ProjectLocation | null;

export type ProjectResponseIsKnowledgeBase = boolean | null;

export type ProjectResponseImage = string | null;

export type ProjectResponseDefaultDocumentId = string | null;

export type ProjectResponseCollaborateWith = BaseUser[] | null;

export type ProjectResponseBidDueDate = string | null;

export type ProjectResponseAddress = string | null;

export interface ProjectResponse {
  _id: string;
  address?: ProjectResponseAddress;
  bidDueDate?: ProjectResponseBidDueDate;
  collaborate_with?: ProjectResponseCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectResponseDefaultDocumentId;
  delivery_method?: string;
  image?: ProjectResponseImage;
  is_knowledge_base?: ProjectResponseIsKnowledgeBase;
  last_modified?: string;
  location?: ProjectResponseLocation;
  memos?: ProjectResponseMemos;
  name?: ProjectResponseName;
  notes?: ProjectResponseNotes;
  organization_id?: ProjectResponseOrganizationId;
  owner?: ProjectResponseOwner;
  project_size?: string;
  scope?: ProjectResponseScope;
  slug: string;
  stakeholders?: ProjectResponseStakeholders;
  statistic?: ProjectResponseStatistic;
  status?: ProjectResponseStatus;
  summary?: ProjectResponseSummary;
  tags?: ProjectResponseTags;
  takeoff_estimated_cost?: number;
  type?: ProjectResponseType;
  unit_system?: ProjectResponseUnitSystem;
  user?: ProjectResponseUser;
  user_id?: ProjectResponseUserId;
}

export type ProjectLocationZip = string | null;

export type ProjectLocationStreet = string | null;

export type ProjectLocationNumber = number | null;

export type ProjectLocationLng = number | null;

export type ProjectLocationLat = number | null;

export type ProjectLocationCity = string | null;

export interface ProjectLocation {
  city?: ProjectLocationCity;
  country?: string;
  lat?: ProjectLocationLat;
  lng?: ProjectLocationLng;
  number?: ProjectLocationNumber;
  state?: string;
  street?: ProjectLocationStreet;
  zip?: ProjectLocationZip;
}

export type ProjectFullUserId = string | null;

export type ProjectFullUser = string | null;

export type ProjectFullUnitSystem = UnitSystem | null;

export type ProjectFullType = string | null;

export type ProjectFullTags = Tag[] | null;

export type ProjectFullSummary = string | null;

export type ProjectFullStatus = Status | null;

export type ProjectFullStatistic = { [key: string]: unknown };

export type ProjectFullStakeholders = Stakeholder[] | null;

export type ProjectFullScope = string[] | null;

export type ProjectFullOwner = string | null;

export type ProjectFullOrganizationId = string | null;

export interface ProjectFull {
  _id: string;
  address?: ProjectFullAddress;
  bidDueDate?: ProjectFullBidDueDate;
  collaborate_with?: ProjectFullCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectFullDefaultDocumentId;
  delivery_method?: string;
  documents?: DocumentMetadata[];
  filesystem?: FileSystem;
  image?: ProjectFullImage;
  is_knowledge_base?: ProjectFullIsKnowledgeBase;
  last_event?: ProjectFullLastEvent;
  last_modified?: string;
  location?: ProjectFullLocation;
  memos?: Memo[];
  name?: ProjectFullName;
  notes?: ProjectFullNotes;
  organization_id?: ProjectFullOrganizationId;
  owner?: ProjectFullOwner;
  project_size?: string;
  scope?: ProjectFullScope;
  slug: string;
  stakeholders?: ProjectFullStakeholders;
  statistic?: ProjectFullStatistic;
  status?: ProjectFullStatus;
  summary?: ProjectFullSummary;
  tags?: ProjectFullTags;
  takeoff_estimated_cost?: number;
  type?: ProjectFullType;
  unit_system?: ProjectFullUnitSystem;
  user?: ProjectFullUser;
  user_id?: ProjectFullUserId;
}

export type ProjectFullNotesAnyOf = {[key: string]: Note};

export type ProjectFullNotes = ProjectFullNotesAnyOf | null;

export type ProjectFullName = string | null;

export type ProjectFullLocation = ProjectLocation | null;

export type ProjectFullLastEventAnyOf = { [key: string]: unknown };

export type ProjectFullLastEvent = ProjectFullLastEventAnyOf | null;

export type ProjectFullIsKnowledgeBase = boolean | null;

export type ProjectFullImage = string | null;

export type ProjectFullDefaultDocumentId = string | null;

export type ProjectFullCollaborateWith = BaseUser[] | null;

export type ProjectFullBidDueDate = string | null;

export type ProjectFullAddress = string | null;

export type ProjectCreateUserId = string | null;

export type ProjectCreateUser = string | null;

export type ProjectCreateUnitSystem = UnitSystem | null;

export type ProjectCreateType = string | null;

export type ProjectCreateTags = Tag[] | null;

export type ProjectCreateSummary = string | null;

export type ProjectCreateStatus = Status | null;

export type ProjectCreateStatistic = { [key: string]: unknown };

export type ProjectCreateStakeholders = Stakeholder[] | null;

export type ProjectCreateSlug = string | null;

export type ProjectCreateScope = string[] | null;

export type ProjectCreateQueries = Query[] | null;

export type ProjectCreateOwner = string | null;

export type ProjectCreateOrganizationId = string | null;

export type ProjectCreateNotesAnyOf = {[key: string]: Note};

export type ProjectCreateNotes = ProjectCreateNotesAnyOf | null;

export type ProjectCreateName = string | null;

export type ProjectCreateMemos = Memo[] | null;

export type ProjectCreateLocation = ProjectLocation | null;

export type ProjectCreateIsDeleted = boolean | null;

export type ProjectCreateIsKnowledgeBase = boolean | null;

export type ProjectCreateImage = string | null;

export type ProjectCreateDocuments = Document[] | null;

export type ProjectCreateDefaultDocumentId = string | null;

export type ProjectCreateCollaborateWith = BaseUser[] | null;

export type ProjectCreateBidDueDate = string | null;

export type ProjectCreateAddress = string | null;

export interface ProjectCreate {
  _id?: string;
  address?: ProjectCreateAddress;
  bidDueDate?: ProjectCreateBidDueDate;
  collaborate_with?: ProjectCreateCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectCreateDefaultDocumentId;
  delivery_method?: string;
  documents?: ProjectCreateDocuments;
  image?: ProjectCreateImage;
  is_knowledge_base?: ProjectCreateIsKnowledgeBase;
  isDeleted?: ProjectCreateIsDeleted;
  last_modified?: string;
  location?: ProjectCreateLocation;
  memos?: ProjectCreateMemos;
  name?: ProjectCreateName;
  notes?: ProjectCreateNotes;
  organization_id?: ProjectCreateOrganizationId;
  owner?: ProjectCreateOwner;
  project_size?: string;
  queries?: ProjectCreateQueries;
  scope?: ProjectCreateScope;
  slug?: ProjectCreateSlug;
  stakeholders?: ProjectCreateStakeholders;
  statistic?: ProjectCreateStatistic;
  status?: ProjectCreateStatus;
  summary?: ProjectCreateSummary;
  tags?: ProjectCreateTags;
  takeoff_estimated_cost?: number;
  type?: ProjectCreateType;
  unit_system?: ProjectCreateUnitSystem;
  user?: ProjectCreateUser;
  user_id?: ProjectCreateUserId;
}

export type Parser = typeof Parser[keyof typeof Parser];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Parser = {
  pelles_parser: 'pelles_parser',
  llama_parser: 'llama_parser',
  simple_parser: 'simple_parser',
  all: 'all',
} as const;

export type PagesUpdateParentId = string | null;

export type PagesUpdateOrder = number | null;

export type PagesUpdateDescription = string | null;

export interface PagesUpdate {
  description?: PagesUpdateDescription;
  name: string;
  order?: PagesUpdateOrder;
  page_id: string;
  page_type?: PagesUpdatePageType;
  parentId?: PagesUpdateParentId;
}

export type PageUpdateParentId = string | null;

export type PageUpdatePageType = PageType | null;

export type PageUpdateOrder = number | null;

export type PageUpdateName = string | null;

export type PageUpdateMarkdown = string | null;

export type PageUpdateDescription = string | null;

export type PageUpdateCreatedBy = string | null;

export type PageUpdateContentAnyOfItem = { [key: string]: unknown };

export type PageUpdateContent = PageUpdateContentAnyOfItem[] | null;

export interface PageUpdate {
  content?: PageUpdateContent;
  created_by?: PageUpdateCreatedBy;
  description?: PageUpdateDescription;
  last_modified?: string;
  markdown?: PageUpdateMarkdown;
  name?: PageUpdateName;
  order?: PageUpdateOrder;
  page_type?: PageUpdatePageType;
  parentId?: PageUpdateParentId;
}

export type PageType = typeof PageType[keyof typeof PageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageType = {
  page: 'page',
  template: 'template',
  folder: 'folder',
} as const;

export type PagesUpdatePageType = PageType | null;

export type PageSelection = typeof PageSelection[keyof typeof PageSelection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageSelection = {
  all: 'all',
  selected: 'selected',
  favorites: 'favorites',
} as const;

export type PageResponseVersion = number | null;

export type PageResponseRole = Role | null;

export type PageResponseParentId = string | null;

export type PageResponsePageType = PageType | null;

export type PageResponsePageIcon = string | null;

export type PageResponseOrgId = string | null;

export type PageResponseMarkdown = string | null;

export type PageResponseIsPrivate = boolean | null;

export type PageResponseDescription = string | null;

export interface PageResponse {
  _id?: string;
  content?: PageResponseContent;
  created_at?: string;
  created_by: string;
  description?: PageResponseDescription;
  isPrivate?: PageResponseIsPrivate;
  last_modified?: string;
  markdown?: PageResponseMarkdown;
  name: string;
  order?: number;
  org_id?: PageResponseOrgId;
  page_icon?: PageResponsePageIcon;
  page_type?: PageResponsePageType;
  parentId?: PageResponseParentId;
  project_id: string;
  role?: PageResponseRole;
  version?: PageResponseVersion;
}

export type PageResponseContentAnyOfItem = { [key: string]: unknown };

export type PageResponseContent = PageResponseContentAnyOfItem[] | null;

export type PageMetadataVersion = number | null;

export type PageMetadataRole = Role | null;

export type PageMetadataParentId = string | null;

export type PageMetadataPageType = PageType | null;

export type PageMetadataPageIcon = string | null;

export type PageMetadataOrgId = string | null;

export type PageMetadataIsPrivate = boolean | null;

export type PageMetadataDescription = string | null;

export interface PageMetadata {
  _id?: string;
  created_at?: string;
  created_by: string;
  description?: PageMetadataDescription;
  isPrivate?: PageMetadataIsPrivate;
  last_modified?: string;
  name: string;
  order?: number;
  org_id?: PageMetadataOrgId;
  page_icon?: PageMetadataPageIcon;
  page_type?: PageMetadataPageType;
  parentId?: PageMetadataParentId;
  project_id: string;
  role?: PageMetadataRole;
  version?: PageMetadataVersion;
}

export type PageEditActionParams = ChangePageLengthParams | ChangeReadingLevelParams | TemplateParams | null;

export interface PageEditAction {
  action: PageActionType;
  params?: PageEditActionParams;
}

export type PageDimensionsWidth = number | null;

export type PageDimensionsHeight = number | null;

export interface PageDimensions {
  height?: PageDimensionsHeight;
  width?: PageDimensionsWidth;
}

export type PageCreateParentId = string | null;

export type PageCreatePageType = PageType | null;

export type PageCreateOrder = number | null;

export type PageCreateMarkdown = string | null;

export type PageCreateDescription = string | null;

export interface PageCreate {
  content?: PageCreateContent;
  description?: PageCreateDescription;
  markdown?: PageCreateMarkdown;
  name: string;
  order?: PageCreateOrder;
  page_type?: PageCreatePageType;
  parentId?: PageCreateParentId;
}

export type PageCreateContentAnyOfItem = { [key: string]: unknown };

export type PageCreateContent = PageCreateContentAnyOfItem[] | null;

export type PageConvertMarkdown = string | null;

export interface PageConvert {
  markdown?: PageConvertMarkdown;
  name: string;
}

export type PageActionType = typeof PageActionType[keyof typeof PageActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageActionType = {
  change_page_length: 'change_page_length',
  change_page_reading_level: 'change_page_reading_level',
  touch_up: 'touch_up',
} as const;

export type PSPDFKitAnnotation = typeof PSPDFKitAnnotation[keyof typeof PSPDFKitAnnotation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PSPDFKitAnnotation = {
  'pspdfkit/shape/rectangle': 'pspdfkit/shape/rectangle',
  'pspdfkit/shape/line': 'pspdfkit/shape/line',
  'pspdfkit/shape/ellipse': 'pspdfkit/shape/ellipse',
  'pspdfkit/shape/polygon': 'pspdfkit/shape/polygon',
  'pspdfkit/shape/polyline': 'pspdfkit/shape/polyline',
  'pspdfkit/stamp': 'pspdfkit/stamp',
  'pspdfkit/image': 'pspdfkit/image',
  'multi-count': 'multi-count',
  'magic-wand': 'magic-wand',
  pieces: 'pieces',
  area: 'area',
  linear: 'linear',
} as const;

export type OverlayCompareUpdateType = typeof OverlayCompareUpdateType[keyof typeof OverlayCompareUpdateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverlayCompareUpdateType = {
  overlay: 'overlay',
} as const;

export type OverlayCompareUpdateSettings = OverlayCompareSettings | null;

export type OverlayCompareUpdateName = string | null;

export type OverlayCompareUpdateDescription = string | null;

export interface OverlayCompareUpdate {
  description?: OverlayCompareUpdateDescription;
  last_modified?: string;
  name?: OverlayCompareUpdateName;
  settings?: OverlayCompareUpdateSettings;
  type: OverlayCompareUpdateType;
}

export interface OverlayCompareSettings {
  auto_alignment?: boolean;
  document_a: CompareDocument;
  document_b: CompareDocument;
}

export type OverlayCompareCreateType = typeof OverlayCompareCreateType[keyof typeof OverlayCompareCreateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverlayCompareCreateType = {
  overlay: 'overlay',
} as const;

export type OverlayCompareCreateDescription = string | null;

export interface OverlayCompareCreate {
  description?: OverlayCompareCreateDescription;
  name: string;
  settings: OverlayCompareSettings;
  type: OverlayCompareCreateType;
}

export type OverlayCompareVersion = number | null;

export type OverlayCompareType = typeof OverlayCompareType[keyof typeof OverlayCompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverlayCompareType = {
  overlay: 'overlay',
} as const;

export type OverlayCompareIsPrivate = boolean | null;

export type OverlayCompareDescription = string | null;

export interface OverlayCompare {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: OverlayCompareDescription;
  isPrivate?: OverlayCompareIsPrivate;
  isReady?: boolean;
  last_modified?: string;
  name: string;
  order?: number;
  project_id: string;
  settings: OverlayCompareSettings;
  status?: CompareStatus;
  tags?: Tag[];
  type: OverlayCompareType;
  version?: OverlayCompareVersion;
}

export type OrganizationProjects = string[] | null;

export type OrganizationOrganizationLogo = string | null;

export type OrganizationOrgLogo = OrgLogo | null;

export type OrganizationKnowledgeBase = KnowledgeBase | null;

export type OrganizationIntergrationsSettings = {[key: string]: string};

export type OrganizationDescription = string | null;

export interface Organization {
  _id?: string;
  catalog?: EquipmentPiecePrice[];
  checklists?: Checklist[];
  description?: OrganizationDescription;
  intergrations_settings?: OrganizationIntergrationsSettings;
  knowledge_base?: OrganizationKnowledgeBase;
  last_modified?: string;
  name: string;
  org_logo?: OrganizationOrgLogo;
  organization_logo?: OrganizationOrganizationLogo;
  projects?: OrganizationProjects;
  quota?: Quota;
  tags?: Tag[];
}

export type OrgLogoResponseWidth = number | null;

export type OrgLogoResponseHeight = number | null;

export interface OrgLogoResponse {
  file_name: string;
  height?: OrgLogoResponseHeight;
  url: string;
  width?: OrgLogoResponseWidth;
}

export type OrgLogoWidth = number | null;

export type OrgLogoHeight = number | null;

export interface OrgLogo {
  file_name: string;
  height?: OrgLogoHeight;
  width?: OrgLogoWidth;
}

export type NoteUpdateText = string | null;

export type NoteUpdateColor = string | null;

export interface NoteUpdate {
  color?: NoteUpdateColor;
  last_modified?: string;
  text?: NoteUpdateText;
}

export type NoteTags = string[] | null;

export type NoteShared = boolean | null;

export type NoteQueryRefs = string[] | null;

export type NoteIsDeleted = boolean | null;

export type NoteDocumentId = string | null;

export type NoteCreatedBy = string | null;

export type NoteColor = string | null;

export type NoteAnnotation = Annotation | null;

export interface Note {
  _id: string;
  annotation?: NoteAnnotation;
  color?: NoteColor;
  createdBy?: NoteCreatedBy;
  document_id?: NoteDocumentId;
  isDeleted?: NoteIsDeleted;
  last_modified?: string;
  query_refs?: NoteQueryRefs;
  shared?: NoteShared;
  tags?: NoteTags;
  text?: string;
}

export interface MicrosoftFile {
  file_name: string;
  file_url: string;
}

export type MetaMatchScore = number | null;

export type MetaMatchNote = string | null;

export type MetaMatchContent = string | null;

export interface MetaMatch {
  content?: MetaMatchContent;
  document_id: string;
  flags?: string[];
  id?: string;
  note?: MetaMatchNote;
  page: number;
  score?: MetaMatchScore;
}

export type MentionType = typeof MentionType[keyof typeof MentionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MentionType = {
  document: 'document',
  person: 'person',
  project: 'project',
  create_page: 'create_page',
  tool: 'tool',
  org_document: 'org_document',
} as const;

export type MentionPages = number[] | null;

export type MentionDescription = string | null;

export interface Mention {
  description?: MentionDescription;
  id: string;
  mention_type: MentionType;
  name: string;
  pages?: MentionPages;
}

export type MemoUpdateTitle = string | null;

export type MemoUpdateContent = string | null;

export interface MemoUpdate {
  content?: MemoUpdateContent;
  last_modified?: string;
  title?: MemoUpdateTitle;
}

/**
 * Memo Meta Is used for sharing the memo as a memos list
 */
export interface MemoMeta {
  created_at?: string;
  created_by: string;
  id: string;
  last_modified?: string;
  title: string;
}

export interface MemoCreate {
  content: string;
  title: string;
}

/**
 * Memo is the complete memo content
 */
export interface Memo {
  content: string;
  created_at?: string;
  created_by: string;
  id?: string;
  last_modified?: string;
  title: string;
}

export type MatchScore = number | null;

export type MatchNote = string | null;

export type MatchContent = string | null;

export type MatchAnnotations = MatchAnnotationsAnyOfItem[] | null;

export interface Match {
  annotations?: MatchAnnotations;
  content?: MatchContent;
  document_id: string;
  flags?: string[];
  id?: string;
  note?: MatchNote;
  page: number;
  score?: MatchScore;
}

export type MatchAnnotationsAnyOfItemAnyOf = { [key: string]: unknown };

export interface MarkupHighlight {
  _id?: string;
  bbox: number[];
  blendMode?: string;
  color?: string;
  createdAt?: string;
  opacity?: number;
  pageIndex: number;
  rects: number[][];
  type?: string;
  updatedAt?: string;
  v?: number;
}

export type MatchAnnotationsAnyOfItem = MatchAnnotationsAnyOfItemAnyOf | MarkupHighlight;

/**
 * The thumbnail to be used for the action
 */
export type MainPageActionThumbnail = string | null;

/**
 * The sub title to be used for the action
 */
export type MainPageActionSubTitle = string | null;

export interface MainPageAction {
  /** The properties to be used for the action */
  action_props: ActionProps;
  /** The text to display to the user for the suggested action */
  display_text: string;
  /** The physical address of the project */
  location?: MainPageActionLocation;
  /** The slug to be used for the action */
  slug: string;
  /** The sub title to be used for the action */
  sub_title?: MainPageActionSubTitle;
  /** The thumbnail to be used for the action */
  thumbnail?: MainPageActionThumbnail;
  /** The time stamp to be used for the action */
  time_stamp: string;
  /** The type of action to suggest from the list of possible actions */
  type: ActionType;
}

export type MainPageActionLocationAnyOf = { [key: string]: unknown };

/**
 * The physical address of the project
 */
export type MainPageActionLocation = MainPageActionLocationAnyOf | null;

export type LearningCenterItemType = typeof LearningCenterItemType[keyof typeof LearningCenterItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LearningCenterItemType = {
  youtube: 'youtube',
  web_page: 'web_page',
} as const;

export interface LearningCenterItem {
  _id?: string;
  description?: string;
  duration?: Duration;
  image?: string;
  item_type: LearningCenterItemType;
  name: string;
  url: string;
}

export interface KnowledgeBase {
  id: string;
  slug: string;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface GoogleFile {
  file_id: string;
  file_name: string;
  mime_type: string;
}

export interface FollowupQuestions {
  questions?: string[];
}

export type FolderIcon = string | null;

export interface Folder {
  icon?: FolderIcon;
  id?: string;
  isFolder?: boolean;
  name: string;
  order?: number;
  parentId?: string;
}

export interface File {
  file_type?: string;
  id: string;
  isFolder?: boolean;
  order?: number;
  parentId?: string;
  versions?: Version[];
}

export type FileSystemRoot = {[key: string]: File | Folder};

export interface FileSystem {
  root?: FileSystemRoot;
}

export type FeedbackType = typeof FeedbackType[keyof typeof FeedbackType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackType = {
  bug: 'bug',
  feature_request: 'feature_request',
  general: 'general',
} as const;

export type Feedback = typeof Feedback[keyof typeof Feedback];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Feedback = {
  positive: 'positive',
  negative: 'negative',
  na: 'na',
} as const;

export interface FeedBack {
  createdAt?: string;
  text: string;
  type?: FeedbackType;
}

export type ExactCompareResultSummary = string | null;

export type ExactCompareResultSeverity = string | null;

export type ExactCompareResultClassification = string | null;

export type ExactCompareResultChangeType = DiffChangeType | null;

export interface ExactCompareResult {
  change_type?: ExactCompareResultChangeType;
  classification?: ExactCompareResultClassification;
  document_a_matches?: Match[];
  document_b_matches?: Match[];
  id?: string;
  isDone?: boolean;
  severity?: ExactCompareResultSeverity;
  summary?: ExactCompareResultSummary;
  tags?: Tag[];
}

export type ExactCompareCreateType = typeof ExactCompareCreateType[keyof typeof ExactCompareCreateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExactCompareCreateType = {
  exact: 'exact',
} as const;

export type ExactCompareCreateDescription = string | null;

export interface ExactCompareCreate {
  description?: ExactCompareCreateDescription;
  name: string;
  settings: CompareSettings;
  type: ExactCompareCreateType;
}

export type ExactCompareVersion = number | null;

export type ExactCompareType = typeof ExactCompareType[keyof typeof ExactCompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExactCompareType = {
  exact: 'exact',
} as const;

export type ExactCompareIsPrivate = boolean | null;

export type ExactCompareDescription = string | null;

export interface ExactCompare {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: ExactCompareDescription;
  isPrivate?: ExactCompareIsPrivate;
  isReady?: boolean;
  last_modified?: string;
  name: string;
  order?: number;
  project_id: string;
  results: ExactCompareResult[];
  settings: CompareSettings;
  status?: CompareStatus;
  tags?: Tag[];
  type: ExactCompareType;
  version?: ExactCompareVersion;
}

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  create: 'create',
  edit: 'edit',
  takeoff: 'takeoff',
  run: 'run',
  delete: 'delete',
  compare: 'compare',
  view: 'view',
} as const;

export type EventSubType = typeof EventSubType[keyof typeof EventSubType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventSubType = {
  upload: 'upload',
  Query: 'Query',
  rename: 'rename',
  share: 'share',
  unshare: 'unshare',
  delete: 'delete',
  annotate: 'annotate',
  page_naming: 'page_naming',
  auto_detect: 'auto_detect',
  note: 'note',
  delete_doc: 'delete_doc',
  update: 'update',
  checklist: 'checklist',
  feedback: 'feedback',
  tag: 'tag',
  update_answer: 'update_answer',
  page: 'page',
  project: 'project',
  equipment_item: 'equipment_item',
  batch: 'batch',
  single: 'single',
  status: 'status',
  duplicate: 'duplicate',
  semantic: 'semantic',
  exact: 'exact',
  overlay: 'overlay',
} as const;

export type EventRecordInfo = { [key: string]: unknown };

export type EventRecordEventSubType = EventSubType | null;

export type EventCategory = typeof EventCategory[keyof typeof EventCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventCategory = {
  checklist: 'checklist',
  equipment: 'equipment',
  feedback: 'feedback',
  note: 'note',
  organization: 'organization',
  user: 'user',
  project: 'project',
  document: 'document',
  query: 'query',
  page: 'page',
  takeoff: 'takeoff',
  compare: 'compare',
} as const;

export interface EventRecord {
  _id?: string;
  event_category: EventCategory;
  event_sub_type?: EventRecordEventSubType;
  event_type: EventType;
  info?: EventRecordInfo;
  org_id: string;
  project_id?: string;
  timestamp?: string;
  user_id: string;
}

export type EquipmentPiecePriceUpdateDescription = string | null;

export interface EquipmentPiecePriceUpdate {
  description?: EquipmentPiecePriceUpdateDescription;
  equipment_id: string;
  price_per_unit: number;
  updatedAt?: string;
}

export interface EquipmentPiecePriceCreate {
  description?: string;
  type: string;
}

export type EquipmentPiecePriceUnitType = UnitType | null;

export type EquipmentPiecePriceDescription = string | null;

export type EquipmentPiecePriceCreatedBy = string | null;

export interface EquipmentPiecePrice {
  _id?: string;
  createdBy?: EquipmentPiecePriceCreatedBy;
  currency?: string;
  description?: EquipmentPiecePriceDescription;
  description_options?: string[];
  equipment_id?: string;
  pound_per_ft?: number;
  price_per_unit: number;
  unit: string;
  unitType?: EquipmentPiecePriceUnitType;
  updatedAt?: string;
}

export type EquipmentPieceUnitSystem = UnitSystem | null;

export type EquipmentPieceTags = string[] | null;

export type EquipmentPiecePricePerUnit = number | null;

export type EquipmentPieceCustomData = { [key: string]: unknown };

export type EquipmentPieceConstructingEquipmentItems = ConstructingEquipmentPiece[] | null;

export type EquipmentPieceConstructingAnnotationIds = string[] | null;

export type EquipmentPieceCalculatedAttributes = { [key: string]: unknown };

export interface EquipmentPiece {
  _id: string;
  autoDetected?: boolean;
  calculated_attributes?: EquipmentPieceCalculatedAttributes;
  color: string;
  constructing_annotation_ids?: EquipmentPieceConstructingAnnotationIds;
  constructing_equipment_items?: EquipmentPieceConstructingEquipmentItems;
  count?: number;
  createdAt?: string;
  customData?: EquipmentPieceCustomData;
  defaultAnnotationStyle?: DefaultAnnotationStyle;
  description: string;
  description_options?: string[];
  icon: EquipmentPieceIcon;
  iconColors: string[];
  isReady?: boolean;
  name: string;
  price_per_unit?: EquipmentPiecePricePerUnit;
  tags?: EquipmentPieceTags;
  total_price?: number;
  type?: string;
  unit: string;
  unitSystem?: EquipmentPieceUnitSystem;
  unitType: UnitType;
  updatedAt?: string;
}

export type EquipmentListEventType = typeof EquipmentListEventType[keyof typeof EquipmentListEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EquipmentListEventType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
} as const;

export interface EquipmentListUpdate {
  equipment_ids: string[];
  equipment_pieces?: EquipmentPiece[];
  eventType: EquipmentListEventType;
}

export type EquipmentIcon = typeof EquipmentIcon[keyof typeof EquipmentIcon];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EquipmentIcon = {
  multi_count: 'multi_count',
  area: 'area',
  linear: 'linear',
  auto_count: 'auto_count',
} as const;

export type EquipmentPieceIcon = EquipmentIcon | string;

export interface EquipmentDescriptionSearch {
  description: string;
  equipment_id: string;
}

export interface Duration {
  minutes?: number;
  seconds: number;
}

export interface DocumentsQuotaStatus {
  documents_count?: QuotaTracking;
  total_documents_size?: QuotaTracking;
}

export type DocumentPageTitleAnnotationAnnotation = { [key: string]: unknown };

export interface DocumentPageTitleAnnotation {
  annotation: DocumentPageTitleAnnotationAnnotation;
}

export type DocumentMetadataUpdateScale = number | null;

export type DocumentMetadataUpdatePath = string[] | null;

export type DocumentMetadataUpdatePageNames = string[] | null;

export type DocumentMetadataUpdateFilename = string | null;

export type DocumentMetadataUpdateCollaborateWith = string[] | null;

export interface DocumentMetadataUpdate {
  collaborate_with?: DocumentMetadataUpdateCollaborateWith;
  filename?: DocumentMetadataUpdateFilename;
  last_modified?: string;
  pageNames?: DocumentMetadataUpdatePageNames;
  path?: DocumentMetadataUpdatePath;
  scale?: DocumentMetadataUpdateScale;
}

export type DocumentMetadataUrls = string[] | null;

export type DocumentMetadataUrl = string | null;

export type DocumentMetadataSheetsMetadataAnyOf = {[key: string]: SheetMetadata};

export type DocumentMetadataSheetsMetadata = DocumentMetadataSheetsMetadataAnyOf | null;

export type DocumentMetadataSheetNumbersAnyOfItem = string | null;

export type DocumentMetadataSheetNumbers = DocumentMetadataSheetNumbersAnyOfItem[] | null;

export type DocumentMetadataScale = number | null;

export type DocumentMetadataPath = string[] | null;

export type DocumentMetadataPageNames = string[] | null;

export type DocumentMetadataOriginalFileUrl = string | null;

export type DocumentMetadataOriginalFileType = AcceptedFileTypes | null;

export type DocumentMetadataNumberOfPages = number | null;

export type DocumentMetadataIsUploaded = boolean | null;

export type DocumentMetadataFileSize = number | null;

export type DocumentMetadataFileHash = string | null;

export type DocumentMetadataFavoritePages = number[] | null;

export type DocumentMetadataCollaborateWith = string[] | null;

export interface DocumentMetadata {
  _id?: string;
  collaborate_with?: DocumentMetadataCollaborateWith;
  favorite_pages?: DocumentMetadataFavoritePages;
  file_hash?: DocumentMetadataFileHash;
  file_size?: DocumentMetadataFileSize;
  filename: string;
  isUploaded?: DocumentMetadataIsUploaded;
  last_modified?: string;
  numberOfPages?: DocumentMetadataNumberOfPages;
  original_file_type?: DocumentMetadataOriginalFileType;
  original_file_url?: DocumentMetadataOriginalFileUrl;
  original_filename: string;
  pageNames?: DocumentMetadataPageNames;
  path?: DocumentMetadataPath;
  scale?: DocumentMetadataScale;
  sheetNumbers?: DocumentMetadataSheetNumbers;
  sheets_metadata?: DocumentMetadataSheetsMetadata;
  url?: DocumentMetadataUrl;
  urls?: DocumentMetadataUrls;
}

export interface DocumentIngestionStatusResponse {
  document_id: string;
  document_ready: boolean;
  drawings: number;
  project_id: string;
  questions: number;
  sheet_metadata: number;
  tables: number;
  texts: number;
}

export type DocumentEquipmentAnnotationAnnotation = { [key: string]: unknown };

export interface DocumentEquipmentAnnotation {
  annotation: DocumentEquipmentAnnotationAnnotation;
}

export type DocumentAnnotationAddAnnotationsAnyOfItem = { [key: string]: unknown };

export type DocumentAnnotationAddAnnotations = DocumentAnnotationAddAnnotationsAnyOfItem[] | null;

export interface DocumentAnnotationAdd {
  annotations?: DocumentAnnotationAddAnnotations;
}

export type DocumentUrls = string[] | null;

export type DocumentUrl = string | null;

export type DocumentSheetsMetadataAnyOf = {[key: string]: SheetMetadata};

export type DocumentSheetsMetadata = DocumentSheetsMetadataAnyOf | null;

export type DocumentSheetNumbersAnyOfItem = string | null;

export type DocumentSheetNumbers = DocumentSheetNumbersAnyOfItem[] | null;

export type DocumentScale = number | null;

export type DocumentPath = string[] | null;

export type DocumentPageNames = string[] | null;

export type DocumentOriginalFileUrl = string | null;

export type DocumentOriginalFileType = AcceptedFileTypes | null;

export type DocumentNumberOfPages = number | null;

export type DocumentIsUploaded = boolean | null;

export type DocumentFileSize = number | null;

export type DocumentFileHash = string | null;

export type DocumentFavoritePages = number[] | null;

export type DocumentEquipmentList = EquipmentPiece[] | null;

export type DocumentCollaborateWith = string[] | null;

export type DocumentAnnotationsAnyOfItem = { [key: string]: unknown };

export type DocumentAnnotations = DocumentAnnotationsAnyOfItem[] | null;

export interface Document {
  _id?: string;
  annotations?: DocumentAnnotations;
  collaborate_with?: DocumentCollaborateWith;
  equipment_list?: DocumentEquipmentList;
  favorite_pages?: DocumentFavoritePages;
  file_hash?: DocumentFileHash;
  file_size?: DocumentFileSize;
  filename: string;
  isUploaded?: DocumentIsUploaded;
  last_modified?: string;
  numberOfPages?: DocumentNumberOfPages;
  original_file_type?: DocumentOriginalFileType;
  original_file_url?: DocumentOriginalFileUrl;
  original_filename: string;
  pageNames?: DocumentPageNames;
  path?: DocumentPath;
  scale?: DocumentScale;
  sheetNumbers?: DocumentSheetNumbers;
  sheets_metadata?: DocumentSheetsMetadata;
  url?: DocumentUrl;
  urls?: DocumentUrls;
}

export interface DocInProcess {
  id: string;
  name: string;
}

export type DiffChangeType = typeof DiffChangeType[keyof typeof DiffChangeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffChangeType = {
  added: 'added',
  deleted: 'deleted',
  modified: 'modified',
} as const;

export type DefaultAnnotationStyleType = string | null;

export type DefaultAnnotationStyleStrokeWidth = number | null;

export type DefaultAnnotationStyleStrokeColor = string | null;

export type DefaultAnnotationStyleOpacity = number | null;

export type DefaultAnnotationStyleLineCapsAnyOf = { [key: string]: unknown };

export type DefaultAnnotationStyleLineCaps = DefaultAnnotationStyleLineCapsAnyOf | null;

export type DefaultAnnotationStyleFillColor = string | null;

export interface DefaultAnnotationStyle {
  fillColor?: DefaultAnnotationStyleFillColor;
  lineCaps?: DefaultAnnotationStyleLineCaps;
  opacity?: DefaultAnnotationStyleOpacity;
  strokeColor?: DefaultAnnotationStyleStrokeColor;
  strokeWidth?: DefaultAnnotationStyleStrokeWidth;
  type?: DefaultAnnotationStyleType;
}

export type ContextText = string | null;

export interface Context {
  equipment_id?: string;
  page_id?: string;
  query_id?: string;
  text?: ContextText;
}

export type ConstructingEquipmentPiecePricePerUnit = number | null;

export type ConstructingEquipmentPieceIcon = EquipmentIcon | string;

export type ConstructingEquipmentPieceCustomData = { [key: string]: unknown };

export type ConstructingEquipmentPieceCreatedBy = string | null;

export type ConstructingEquipmentPieceCalculatedAttributes = { [key: string]: unknown };

export interface ConstructingEquipmentPiece {
  _id: string;
  autoDetected?: boolean;
  calculated_attributes?: ConstructingEquipmentPieceCalculatedAttributes;
  color: string;
  count?: number;
  createdAt?: string;
  createdBy?: ConstructingEquipmentPieceCreatedBy;
  customData?: ConstructingEquipmentPieceCustomData;
  description: string;
  description_options?: string[];
  icon: ConstructingEquipmentPieceIcon;
  iconColors: string[];
  name: string;
  parent_equipment_id: string;
  price_per_unit?: ConstructingEquipmentPiecePricePerUnit;
  total_price?: number;
  unit: string;
  unitType: UnitType;
  updatedAt?: string;
}

export type CompareType = typeof CompareType[keyof typeof CompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompareType = {
  exact: 'exact',
  overlay: 'overlay',
  semantic: 'semantic',
} as const;

export type CompareStatus = typeof CompareStatus[keyof typeof CompareStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompareStatus = {
  open: 'open',
  done: 'done',
  in_progress: 'in_progress',
  error: 'error',
} as const;

export interface CompareSettings {
  document_a: CompareDocument;
  document_b: CompareDocument;
}

export interface CompareResultsUpdate {
  id: string;
  isDone: boolean;
}

export interface CompareResultsUpdateList {
  results: CompareResultsUpdate[];
}

export type CompareResultUpdateName = string | null;

export type CompareResultUpdateDescription = string | null;

export interface CompareResultUpdate {
  description?: CompareResultUpdateDescription;
  name?: CompareResultUpdateName;
}

export type CompareDocumentPages = number[] | null;

export interface CompareDocument {
  document_id: string;
  kb_document?: boolean;
  page_selection?: PageSelection;
  pages?: CompareDocumentPages;
}

export type CompareVersion = number | null;

export type CompareIsPrivate = boolean | null;

export type CompareDescription = string | null;

export interface Compare {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: CompareDescription;
  isPrivate?: CompareIsPrivate;
  isReady?: boolean;
  last_modified?: string;
  name: string;
  order?: number;
  project_id: string;
  status?: CompareStatus;
  tags?: Tag[];
  type: CompareType;
  version?: CompareVersion;
}

export type ChecklistDescription = string | null;

export interface Checklist {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: ChecklistDescription;
  is_default?: boolean;
  last_modified?: string;
  name: string;
  queries?: QueryQuestion[];
}

export interface ChangeReadingLevelParams {
  change_percent: number;
}

export interface ChangePageLengthParams {
  change_percent: number;
}

export interface CatalogItemResponse {
  isExist: boolean;
  item: EquipmentPiecePrice;
}

export interface BodyUploadFileWithVdbV1ProjectsSlugUploadPost {
  file: Blob;
}

export interface BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost {
  file: Blob;
}

export interface BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost {
  file: Blob;
}

export interface BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost {
  added_mentions?: Mention[];
  query_ids: string[];
}

export interface BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost {
  file: Blob;
}

export interface BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost {
  question_receive: QuestionReceive;
  thread_id?: string;
  use_web?: boolean;
}

export interface BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost {
  document_id?: string;
  page_id?: string;
  question_receive: QuestionReceive;
}

export interface BaseUser {
  _id?: string;
  avatar?: string;
  compare_questions?: string[];
  email: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName: string;
  lastName: string;
  tags?: Tag[];
  title?: string;
  username: string;
}

export interface AutomateUser {
  _id?: string;
  avatar?: string;
  compare_questions?: string[];
  email?: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName?: string;
  lastName?: string;
  tags?: Tag[];
  title?: string;
  username?: string;
}

export interface Automate {
  _id?: string;
  last_modified?: string;
  messages?: ModelsAutomateMessage[];
  project_id: string;
  thread_id: string;
}

export interface AutodeskFile {
  id: string;
  name: string;
  project_id: string;
  type: string;
}

export type AnnotationsEventAnnotationsItem = { [key: string]: unknown };

export interface AnnotationsEvent {
  annotations?: AnnotationsEventAnnotationsItem[];
  eventAnnotationIds: string[];
  eventType: AnnotationEventType;
}

export type AnnotationsAndEquipmentResponseAnnotationsItem = { [key: string]: unknown };

export interface AnnotationsAndEquipmentResponse {
  annotations: AnnotationsAndEquipmentResponseAnnotationsItem[];
  equipment_list: EquipmentPiece[];
}

export type AnnotationAnnotation = { [key: string]: unknown };

export interface Annotation {
  annotation?: AnnotationAnnotation;
}

export interface AlgorithmSettings {
  k?: number;
  lookout_model?: RagModel;
  parser?: Parser;
}

export type ActionType = typeof ActionType[keyof typeof ActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  open_doublecheck: 'open_doublecheck',
  run_checklist: 'run_checklist',
  new_create_page: 'new_create_page',
  open_create_page: 'open_create_page',
  open_edit_project: 'open_edit_project',
  new_takeoff: 'new_takeoff',
  continue_takeoff: 'continue_takeoff',
  open_orgKB: 'open_orgKB',
  open_upload_files_modal: 'open_upload_files_modal',
  share_project: 'share_project',
  run_compare: 'run_compare',
  view_document: 'view_document',
} as const;

/**
 * The doc page_num to be used for the action
 */
export type ActionPropsPageIndex = number | null;

/**
 * create page_id if relevant
 */
export type ActionPropsPageId = string | null;

/**
 * The document ID to be used for the action
 */
export type ActionPropsDocId = string | null;

/**
 * compare_id if relevant
 */
export type ActionPropsCompareId = string | null;

export interface ActionProps {
  /** compare_id if relevant */
  compare_id?: ActionPropsCompareId;
  /** The document ID to be used for the action */
  doc_id?: ActionPropsDocId;
  /** create page_id if relevant */
  page_id?: ActionPropsPageId;
  /** The doc page_num to be used for the action */
  page_index?: ActionPropsPageIndex;
}

export type AccountType = typeof AccountType[keyof typeof AccountType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountType = {
  lite: 'lite',
  pro: 'pro',
  enterprise: 'enterprise',
} as const;

export type AcceptedFileTypes = typeof AcceptedFileTypes[keyof typeof AcceptedFileTypes];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcceptedFileTypes = {
  pdf: '.pdf',
  ppt: '.ppt',
  pptx: '.pptx',
  xls: '.xls',
  xlsx: '.xlsx',
  docx: '.docx',
  doc: '.doc',
  txt: '.txt',
  png: '.png',
  jpeg: '.jpeg',
  jpg: '.jpg',
  csv: '.csv',
} as const;




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Get Metrics
 */
export const getMetrics = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/metrics`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMetricsQueryKey = () => {
    return [`/metrics`] as const;
    }

    
export const getGetMetricsQueryOptions = <TData = Awaited<ReturnType<typeof getMetrics>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMetrics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMetricsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetrics>>> = ({ signal }) => getMetrics(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMetrics>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getMetrics>>>
export type GetMetricsQueryError = unknown

/**
 * @summary Get Metrics
 */
export const useGetMetrics = <TData = Awaited<ReturnType<typeof getMetrics>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMetrics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMetricsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Reset Cache
 */
export const resetCache = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/reset_cache`, method: 'GET', signal
    },
      options);
    }
  

export const getResetCacheQueryKey = () => {
    return [`/v1/projects/reset_cache`] as const;
    }

    
export const getResetCacheQueryOptions = <TData = Awaited<ReturnType<typeof resetCache>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resetCache>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getResetCacheQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof resetCache>>> = ({ signal }) => resetCache(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof resetCache>>, TError, TData> & { queryKey: QueryKey }
}

export type ResetCacheQueryResult = NonNullable<Awaited<ReturnType<typeof resetCache>>>
export type ResetCacheQueryError = unknown

/**
 * @summary Reset Cache
 */
export const useResetCache = <TData = Awaited<ReturnType<typeof resetCache>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resetCache>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getResetCacheQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects
 */
export const postProjects = (
    projectCreate: ProjectCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectFull>(
      {url: `/v1/projects/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectCreate
    },
      options);
    }
  


export const getPostProjectsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ProjectCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ProjectCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjects>>, {data: ProjectCreate}> = (props) => {
          const {data} = props ?? {};

          return  postProjects(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjects>>>
    export type PostProjectsMutationBody = ProjectCreate
    export type PostProjectsMutationError = HTTPValidationError

    /**
 * @summary Post Projects
 */
export const usePostProjects = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ProjectCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjects>>,
        TError,
        {data: ProjectCreate},
        TContext
      > => {

      const mutationOptions = getPostProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects
 */
export const getProjects = (
    params?: GetProjectsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<ProjectsList>(
      {url: `/v1/projects/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProjectsQueryKey = (params?: GetProjectsParams,) => {
    return [`/v1/projects/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getProjects>>, TError = HTTPValidationError>(params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjects>>> = ({ signal }) => getProjects(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjects>>>
export type GetProjectsQueryError = HTTPValidationError

/**
 * @summary Get Projects
 */
export const useGetProjects = <TData = Awaited<ReturnType<typeof getProjects>>, TError = HTTPValidationError>(
 params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id
 */
export const getProjectsId = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<ProjectFull>(
      {url: `/v1/projects/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}`] as const;
    }

    
export const getGetProjectsIdQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsId>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsId>>> = ({ signal }) => getProjectsId(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsId>>>
export type GetProjectsIdQueryError = HTTPValidationError

/**
 * @summary Get Projects Id
 */
export const useGetProjectsId = <TData = Awaited<ReturnType<typeof getProjectsId>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Edit Project
 */
export const editProject = (
    slug: string,
    projectUpdate: ProjectUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectFull>(
      {url: `/v1/projects/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectUpdate
    },
      options);
    }
  


export const getEditProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editProject>>, TError,{slug: string;data: ProjectUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof editProject>>, TError,{slug: string;data: ProjectUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editProject>>, {slug: string;data: ProjectUpdate}> = (props) => {
          const {slug,data} = props ?? {};

          return  editProject(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditProjectMutationResult = NonNullable<Awaited<ReturnType<typeof editProject>>>
    export type EditProjectMutationBody = ProjectUpdate
    export type EditProjectMutationError = HTTPValidationError

    /**
 * @summary Edit Project
 */
export const useEditProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editProject>>, TError,{slug: string;data: ProjectUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof editProject>>,
        TError,
        {slug: string;data: ProjectUpdate},
        TContext
      > => {

      const mutationOptions = getEditProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Share Project
 */
export const shareProject = (
    slug: string,
    baseUser: BaseUser,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectResponse>(
      {url: `/v1/projects/${slug}/share`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseUser
    },
      options);
    }
  


export const getShareProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shareProject>>, TError,{slug: string;data: BaseUser}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof shareProject>>, TError,{slug: string;data: BaseUser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof shareProject>>, {slug: string;data: BaseUser}> = (props) => {
          const {slug,data} = props ?? {};

          return  shareProject(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ShareProjectMutationResult = NonNullable<Awaited<ReturnType<typeof shareProject>>>
    export type ShareProjectMutationBody = BaseUser
    export type ShareProjectMutationError = HTTPValidationError

    /**
 * @summary Share Project
 */
export const useShareProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof shareProject>>, TError,{slug: string;data: BaseUser}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof shareProject>>,
        TError,
        {slug: string;data: BaseUser},
        TContext
      > => {

      const mutationOptions = getShareProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Unshare Project
 */
export const unshareProject = (
    slug: string,
    params: UnshareProjectParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectResponse>(
      {url: `/v1/projects/${slug}/unshare`, method: 'POST',
        params
    },
      options);
    }
  


export const getUnshareProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unshareProject>>, TError,{slug: string;params: UnshareProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof unshareProject>>, TError,{slug: string;params: UnshareProjectParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unshareProject>>, {slug: string;params: UnshareProjectParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  unshareProject(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnshareProjectMutationResult = NonNullable<Awaited<ReturnType<typeof unshareProject>>>
    
    export type UnshareProjectMutationError = HTTPValidationError

    /**
 * @summary Unshare Project
 */
export const useUnshareProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unshareProject>>, TError,{slug: string;params: UnshareProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof unshareProject>>,
        TError,
        {slug: string;params: UnshareProjectParams},
        TContext
      > => {

      const mutationOptions = getUnshareProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Change Project Status
 */
export const changeProjectStatus = (
    slug: string,
    status: Status,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectResponse>(
      {url: `/v1/projects/${slug}/update-status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: status
    },
      options);
    }
  


export const getChangeProjectStatusMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeProjectStatus>>, TError,{slug: string;data: Status}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof changeProjectStatus>>, TError,{slug: string;data: Status}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeProjectStatus>>, {slug: string;data: Status}> = (props) => {
          const {slug,data} = props ?? {};

          return  changeProjectStatus(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeProjectStatusMutationResult = NonNullable<Awaited<ReturnType<typeof changeProjectStatus>>>
    export type ChangeProjectStatusMutationBody = Status
    export type ChangeProjectStatusMutationError = HTTPValidationError

    /**
 * @summary Change Project Status
 */
export const useChangeProjectStatus = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeProjectStatus>>, TError,{slug: string;data: Status}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof changeProjectStatus>>,
        TError,
        {slug: string;data: Status},
        TContext
      > => {

      const mutationOptions = getChangeProjectStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get allowance (currently only count and total size of docs) for a given project
 * @summary Get Document Quota Status
 */
export const getDocumentQuotaStatus = (
    slug: string,
    params?: GetDocumentQuotaStatusParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<DocumentsQuotaStatus>(
      {url: `/v1/projects${slug}/quota/status`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDocumentQuotaStatusQueryKey = (slug: string,
    params?: GetDocumentQuotaStatusParams,) => {
    return [`/v1/projects${slug}/quota/status`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDocumentQuotaStatusQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentQuotaStatus>>, TError = HTTPValidationError>(slug: string,
    params?: GetDocumentQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentQuotaStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentQuotaStatusQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentQuotaStatus>>> = ({ signal }) => getDocumentQuotaStatus(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentQuotaStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentQuotaStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentQuotaStatus>>>
export type GetDocumentQuotaStatusQueryError = HTTPValidationError

/**
 * @summary Get Document Quota Status
 */
export const useGetDocumentQuotaStatus = <TData = Awaited<ReturnType<typeof getDocumentQuotaStatus>>, TError = HTTPValidationError>(
 slug: string,
    params?: GetDocumentQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentQuotaStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocumentQuotaStatusQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Add Tag
 */
export const addTag = (
    projectId: string,
    tag: Tag,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectFull>(
      {url: `/v1/projects/${projectId}/tags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
      options);
    }
  


export const getAddTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTag>>, TError,{projectId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addTag>>, TError,{projectId: string;data: Tag}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTag>>, {projectId: string;data: Tag}> = (props) => {
          const {projectId,data} = props ?? {};

          return  addTag(projectId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddTagMutationResult = NonNullable<Awaited<ReturnType<typeof addTag>>>
    export type AddTagMutationBody = Tag
    export type AddTagMutationError = HTTPValidationError

    /**
 * @summary Add Tag
 */
export const useAddTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTag>>, TError,{projectId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof addTag>>,
        TError,
        {projectId: string;data: Tag},
        TContext
      > => {

      const mutationOptions = getAddTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Remove Tag
 */
export const removeTag = (
    projectId: string,
    tagName: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ProjectFull>(
      {url: `/v1/projects/${projectId}/tags/${tagName}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTag>>, TError,{projectId: string;tagName: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeTag>>, TError,{projectId: string;tagName: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeTag>>, {projectId: string;tagName: string}> = (props) => {
          const {projectId,tagName} = props ?? {};

          return  removeTag(projectId,tagName,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveTagMutationResult = NonNullable<Awaited<ReturnType<typeof removeTag>>>
    
    export type RemoveTagMutationError = HTTPValidationError

    /**
 * @summary Remove Tag
 */
export const useRemoveTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeTag>>, TError,{projectId: string;tagName: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof removeTag>>,
        TError,
        {projectId: string;tagName: string},
        TContext
      > => {

      const mutationOptions = getRemoveTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries New Question
 */
export const postProjectsIdQueriesNewQuestion = (
    slug: string,
    questionReceive: QuestionReceive,
    params?: PostProjectsIdQueriesNewQuestionParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Query>(
      {url: `/v1/projects/${slug}/queries/new_question`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: questionReceive,
        params
    },
      options);
    }
  


export const getPostProjectsIdQueriesNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>, TError,{slug: string;data: QuestionReceive;params?: PostProjectsIdQueriesNewQuestionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>, TError,{slug: string;data: QuestionReceive;params?: PostProjectsIdQueriesNewQuestionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>, {slug: string;data: QuestionReceive;params?: PostProjectsIdQueriesNewQuestionParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  postProjectsIdQueriesNewQuestion(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesNewQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>>
    export type PostProjectsIdQueriesNewQuestionMutationBody = QuestionReceive
    export type PostProjectsIdQueriesNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries New Question
 */
export const usePostProjectsIdQueriesNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>, TError,{slug: string;data: QuestionReceive;params?: PostProjectsIdQueriesNewQuestionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdQueriesNewQuestion>>,
        TError,
        {slug: string;data: QuestionReceive;params?: PostProjectsIdQueriesNewQuestionParams},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdQueriesNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Checklist Queries From Csv
 */
export const checklistQueriesFromCsv = (
    slug: string,
    bodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost,
 options?: SecondParameter<typeof customAxios>,) => {
      
      const formData = new FormData();
formData.append('file', bodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost.file)

      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/new_checklist_from_csv`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getChecklistQueriesFromCsvMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checklistQueriesFromCsv>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof checklistQueriesFromCsv>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checklistQueriesFromCsv>>, {slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}> = (props) => {
          const {slug,data} = props ?? {};

          return  checklistQueriesFromCsv(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChecklistQueriesFromCsvMutationResult = NonNullable<Awaited<ReturnType<typeof checklistQueriesFromCsv>>>
    export type ChecklistQueriesFromCsvMutationBody = BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost
    export type ChecklistQueriesFromCsvMutationError = HTTPValidationError

    /**
 * @summary Checklist Queries From Csv
 */
export const useChecklistQueriesFromCsv = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checklistQueriesFromCsv>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof checklistQueriesFromCsv>>,
        TError,
        {slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost},
        TContext
      > => {

      const mutationOptions = getChecklistQueriesFromCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pull And Execute Checklist By Id
 */
export const pullAndExecuteChecklistById = (
    slug: string,
    pullAndExecuteChecklistByIdBody: PullAndExecuteChecklistByIdBody,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/id/checklist`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pullAndExecuteChecklistByIdBody
    },
      options);
    }
  


export const getPullAndExecuteChecklistByIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pullAndExecuteChecklistById>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof pullAndExecuteChecklistById>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pullAndExecuteChecklistById>>, {slug: string;data: PullAndExecuteChecklistByIdBody}> = (props) => {
          const {slug,data} = props ?? {};

          return  pullAndExecuteChecklistById(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PullAndExecuteChecklistByIdMutationResult = NonNullable<Awaited<ReturnType<typeof pullAndExecuteChecklistById>>>
    export type PullAndExecuteChecklistByIdMutationBody = PullAndExecuteChecklistByIdBody
    export type PullAndExecuteChecklistByIdMutationError = HTTPValidationError

    /**
 * @summary Pull And Execute Checklist By Id
 */
export const usePullAndExecuteChecklistById = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pullAndExecuteChecklistById>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof pullAndExecuteChecklistById>>,
        TError,
        {slug: string;data: PullAndExecuteChecklistByIdBody},
        TContext
      > => {

      const mutationOptions = getPullAndExecuteChecklistByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Queries
 */
export const getProjectsIdQueries = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<QueryLite[]>(
      {url: `/v1/projects/${slug}/queries/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdQueriesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/queries/`] as const;
    }

    
export const getGetProjectsIdQueriesQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdQueries>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdQueries>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdQueriesQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdQueries>>> = ({ signal }) => getProjectsIdQueries(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdQueries>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdQueriesQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdQueries>>>
export type GetProjectsIdQueriesQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Queries
 */
export const useGetProjectsIdQueries = <TData = Awaited<ReturnType<typeof getProjectsIdQueries>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdQueries>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdQueriesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects Id Queries Query Id Status
 */
export const postProjectsIdQueriesQueryIdStatus = (
    slug: string,
    queryId: string,
    params: PostProjectsIdQueriesQueryIdStatusParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/status`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostProjectsIdQueriesQueryIdStatusMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>, {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}> = (props) => {
          const {slug,queryId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdStatus(slug,queryId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdStatusMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>>
    
    export type PostProjectsIdQueriesQueryIdStatusMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Status
 */
export const usePostProjectsIdQueriesQueryIdStatus = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdStatus>>,
        TError,
        {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdQueriesQueryIdStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Delete
 */
export const postProjectsIdQueriesQueryIdDelete = (
    slug: string,
    queryId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/delete`, method: 'POST'
    },
      options);
    }
  


export const getPostProjectsIdQueriesQueryIdDeleteMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>, TError,{slug: string;queryId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>, TError,{slug: string;queryId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>, {slug: string;queryId: string}> = (props) => {
          const {slug,queryId} = props ?? {};

          return  postProjectsIdQueriesQueryIdDelete(slug,queryId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>>
    
    export type PostProjectsIdQueriesQueryIdDeleteMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Delete
 */
export const usePostProjectsIdQueriesQueryIdDelete = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>, TError,{slug: string;queryId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdDelete>>,
        TError,
        {slug: string;queryId: string},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdQueriesQueryIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Delete Project Query
 */
export const batchDeleteProjectQuery = (
    slug: string,
    batchDeleteProjectQueryBody: string[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/delete/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteProjectQueryBody
    },
      options);
    }
  


export const getBatchDeleteProjectQueryMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteProjectQuery>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof batchDeleteProjectQuery>>, TError,{slug: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDeleteProjectQuery>>, {slug: string;data: string[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  batchDeleteProjectQuery(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteProjectQueryMutationResult = NonNullable<Awaited<ReturnType<typeof batchDeleteProjectQuery>>>
    export type BatchDeleteProjectQueryMutationBody = string[]
    export type BatchDeleteProjectQueryMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Project Query
 */
export const useBatchDeleteProjectQuery = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteProjectQuery>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof batchDeleteProjectQuery>>,
        TError,
        {slug: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getBatchDeleteProjectQueryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Feedback
 */
export const postProjectsIdQueriesQueryIdFeedback = (
    slug: string,
    queryId: string,
    params: PostProjectsIdQueriesQueryIdFeedbackParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/feedback`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostProjectsIdQueriesQueryIdFeedbackMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>, {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}> = (props) => {
          const {slug,queryId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdFeedback(slug,queryId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>>
    
    export type PostProjectsIdQueriesQueryIdFeedbackMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Feedback
 */
export const usePostProjectsIdQueriesQueryIdFeedback = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdFeedback>>,
        TError,
        {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdQueriesQueryIdFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Match Match Id
 */
export const postProjectsIdQueriesQueryIdMatchMatchId = (
    slug: string,
    queryId: string,
    matchId: string,
    params: PostProjectsIdQueriesQueryIdMatchMatchIdParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/match/${matchId}/feedback`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostProjectsIdQueriesQueryIdMatchMatchIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>, {slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}> = (props) => {
          const {slug,queryId,matchId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdMatchMatchId(slug,queryId,matchId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdMatchMatchIdMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>>
    
    export type PostProjectsIdQueriesQueryIdMatchMatchIdMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Match Match Id
 */
export const usePostProjectsIdQueriesQueryIdMatchMatchId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdMatchMatchId>>,
        TError,
        {slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdQueriesQueryIdMatchMatchIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Put Projects Id Queries Query Id Tag
 */
export const putProjectsIdQueriesQueryIdTag = (
    slug: string,
    queryId: string,
    tag: Tag,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/tags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
      options);
    }
  


export const getPutProjectsIdQueriesQueryIdTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>, TError,{slug: string;queryId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>, TError,{slug: string;queryId: string;data: Tag}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>, {slug: string;queryId: string;data: Tag}> = (props) => {
          const {slug,queryId,data} = props ?? {};

          return  putProjectsIdQueriesQueryIdTag(slug,queryId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutProjectsIdQueriesQueryIdTagMutationResult = NonNullable<Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>>
    export type PutProjectsIdQueriesQueryIdTagMutationBody = Tag
    export type PutProjectsIdQueriesQueryIdTagMutationError = HTTPValidationError

    /**
 * @summary Put Projects Id Queries Query Id Tag
 */
export const usePutProjectsIdQueriesQueryIdTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>, TError,{slug: string;queryId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof putProjectsIdQueriesQueryIdTag>>,
        TError,
        {slug: string;queryId: string;data: Tag},
        TContext
      > => {

      const mutationOptions = getPutProjectsIdQueriesQueryIdTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Projects Id Queries Query Id Tag Tag
 */
export const deleteProjectsIdQueriesQueryIdTagTag = (
    slug: string,
    queryId: string,
    tag: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/tags/${tag}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectsIdQueriesQueryIdTagTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>, TError,{slug: string;queryId: string;tag: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>, TError,{slug: string;queryId: string;tag: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>, {slug: string;queryId: string;tag: string}> = (props) => {
          const {slug,queryId,tag} = props ?? {};

          return  deleteProjectsIdQueriesQueryIdTagTag(slug,queryId,tag,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdQueriesQueryIdTagTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>>
    
    export type DeleteProjectsIdQueriesQueryIdTagTagMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Queries Query Id Tag Tag
 */
export const useDeleteProjectsIdQueriesQueryIdTagTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>, TError,{slug: string;queryId: string;tag: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsIdQueriesQueryIdTagTag>>,
        TError,
        {slug: string;queryId: string;tag: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsIdQueriesQueryIdTagTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Edit Answer
 */
export const editAnswer = (
    slug: string,
    queryId: string,
    editAnswerBody: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/${queryId}/answer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: editAnswerBody
    },
      options);
    }
  


export const getEditAnswerMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editAnswer>>, TError,{slug: string;queryId: string;data: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof editAnswer>>, TError,{slug: string;queryId: string;data: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editAnswer>>, {slug: string;queryId: string;data: string}> = (props) => {
          const {slug,queryId,data} = props ?? {};

          return  editAnswer(slug,queryId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof editAnswer>>>
    export type EditAnswerMutationBody = string
    export type EditAnswerMutationError = HTTPValidationError

    /**
 * @summary Edit Answer
 */
export const useEditAnswer = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editAnswer>>, TError,{slug: string;queryId: string;data: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof editAnswer>>,
        TError,
        {slug: string;queryId: string;data: string},
        TContext
      > => {

      const mutationOptions = getEditAnswerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Query By Id
 */
export const getQueryById = (
    slug: string,
    queryId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Query>(
      {url: `/v1/projects/${slug}/queries/${queryId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetQueryByIdQueryKey = (slug: string,
    queryId: string,) => {
    return [`/v1/projects/${slug}/queries/${queryId}/`] as const;
    }

    
export const getGetQueryByIdQueryOptions = <TData = Awaited<ReturnType<typeof getQueryById>>, TError = HTTPValidationError>(slug: string,
    queryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getQueryById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQueryByIdQueryKey(slug,queryId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQueryById>>> = ({ signal }) => getQueryById(slug,queryId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && queryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getQueryById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetQueryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getQueryById>>>
export type GetQueryByIdQueryError = HTTPValidationError

/**
 * @summary Get Query By Id
 */
export const useGetQueryById = <TData = Awaited<ReturnType<typeof getQueryById>>, TError = HTTPValidationError>(
 slug: string,
    queryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getQueryById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQueryByIdQueryOptions(slug,queryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects Id Queries Query Id Suggest
 */
export const postProjectsIdQueriesQueryIdSuggest = (
    slug: string,
    params: PostProjectsIdQueriesQueryIdSuggestParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<QuestionSuggestions>(
      {url: `/v1/projects/${slug}/queries/suggestions/generator`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getPostProjectsIdQueriesQueryIdSuggestQueryKey = (slug: string,
    params: PostProjectsIdQueriesQueryIdSuggestParams,) => {
    return [`/v1/projects/${slug}/queries/suggestions/generator`, ...(params ? [params]: [])] as const;
    }

    
export const getPostProjectsIdQueriesQueryIdSuggestQueryOptions = <TData = Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>, TError = HTTPValidationError>(slug: string,
    params: PostProjectsIdQueriesQueryIdSuggestParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPostProjectsIdQueriesQueryIdSuggestQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>> = ({ signal }) => postProjectsIdQueriesQueryIdSuggest(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>, TError, TData> & { queryKey: QueryKey }
}

export type PostProjectsIdQueriesQueryIdSuggestQueryResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>>
export type PostProjectsIdQueriesQueryIdSuggestQueryError = HTTPValidationError

/**
 * @summary Post Projects Id Queries Query Id Suggest
 */
export const usePostProjectsIdQueriesQueryIdSuggest = <TData = Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>, TError = HTTPValidationError>(
 slug: string,
    params: PostProjectsIdQueriesQueryIdSuggestParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof postProjectsIdQueriesQueryIdSuggest>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPostProjectsIdQueriesQueryIdSuggestQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Refresh Queries Answer
 */
export const refreshQueriesAnswer = (
    slug: string,
    bodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost,
    params?: RefreshQueriesAnswerParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/queries/refresh`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost,
        params
    },
      options);
    }
  


export const getRefreshQueriesAnswerMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshQueriesAnswer>>, TError,{slug: string;data: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost;params?: RefreshQueriesAnswerParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof refreshQueriesAnswer>>, TError,{slug: string;data: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost;params?: RefreshQueriesAnswerParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshQueriesAnswer>>, {slug: string;data: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost;params?: RefreshQueriesAnswerParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  refreshQueriesAnswer(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefreshQueriesAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof refreshQueriesAnswer>>>
    export type RefreshQueriesAnswerMutationBody = BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost
    export type RefreshQueriesAnswerMutationError = HTTPValidationError

    /**
 * @summary Refresh Queries Answer
 */
export const useRefreshQueriesAnswer = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshQueriesAnswer>>, TError,{slug: string;data: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost;params?: RefreshQueriesAnswerParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof refreshQueriesAnswer>>,
        TError,
        {slug: string;data: BodyRefreshQueriesAnswerV1ProjectsSlugQueriesRefreshPost;params?: RefreshQueriesAnswerParams},
        TContext
      > => {

      const mutationOptions = getRefreshQueriesAnswerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Upload File With Vdb
 */
export const uploadFileWithVdb = (
    slug: string,
    bodyUploadFileWithVdbV1ProjectsSlugUploadPost: BodyUploadFileWithVdbV1ProjectsSlugUploadPost,
    params?: UploadFileWithVdbParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadFileWithVdbV1ProjectsSlugUploadPost.file)

      return customAxios<Document>(
      {url: `/v1/projects/${slug}/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      options);
    }
  


export const getUploadFileWithVdbMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdb>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdb>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFileWithVdb>>, {slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  uploadFileWithVdb(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadFileWithVdbMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFileWithVdb>>>
    export type UploadFileWithVdbMutationBody = BodyUploadFileWithVdbV1ProjectsSlugUploadPost
    export type UploadFileWithVdbMutationError = HTTPValidationError

    /**
 * @summary Upload File With Vdb
 */
export const useUploadFileWithVdb = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdb>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadFileWithVdb>>,
        TError,
        {slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams},
        TContext
      > => {

      const mutationOptions = getUploadFileWithVdbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Documents Document Id
 */
export const getProjectsIdDocumentsDocumentId = (
    slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/documents`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProjectsIdDocumentsDocumentIdQueryKey = (slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams,) => {
    return [`/v1/projects/${slug}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsIdDocumentsDocumentIdQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>, TError = HTTPValidationError>(slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>> = ({ signal }) => getProjectsIdDocumentsDocumentId(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>>
export type GetProjectsIdDocumentsDocumentIdQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id
 */
export const useGetProjectsIdDocumentsDocumentId = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>, TError = HTTPValidationError>(
 slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdDocumentsDocumentIdQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents
 */
export const getProjectsIdDocuments = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<DocumentMetadata[]>(
      {url: `/v1/projects/${slug}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdDocumentsQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/`] as const;
    }

    
export const getGetProjectsIdDocumentsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdDocuments>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdDocuments>>> = ({ signal }) => getProjectsIdDocuments(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocuments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocuments>>>
export type GetProjectsIdDocumentsQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents
 */
export const useGetProjectsIdDocuments = <TData = Awaited<ReturnType<typeof getProjectsIdDocuments>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocuments>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdDocumentsQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents Document Id Annotations
 */
export const getProjectsIdDocumentsDocumentIdAnnotations = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<GetProjectsIdDocumentsDocumentIdAnnotations200Item[]>(
      {url: `/v1/projects/${slug}/${documentId}/annotations`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdDocumentsDocumentIdAnnotationsQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/annotations`] as const;
    }

    
export const getGetProjectsIdDocumentsDocumentIdAnnotationsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdAnnotationsQueryKey(slug,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>> = ({ signal }) => getProjectsIdDocumentsDocumentIdAnnotations(slug,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdAnnotationsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>>
export type GetProjectsIdDocumentsDocumentIdAnnotationsQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id Annotations
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotations = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdDocumentsDocumentIdAnnotationsQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * OLD/NEW endpoint for setting annotations to a document (currently used but not for long)
 * @summary Post Projects Id Documents Document Id Annotations
 */
export const postProjectsIdDocumentsDocumentIdAnnotations = (
    slug: string,
    documentId: string,
    annotationsEvent: AnnotationsEvent,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PostProjectsIdDocumentsDocumentIdAnnotations201>(
      {url: `/v1/projects/${slug}/${documentId}/annotations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: annotationsEvent
    },
      options);
    }
  


export const getPostProjectsIdDocumentsDocumentIdAnnotationsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>, {slug: string;documentId: string;data: AnnotationsEvent}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  postProjectsIdDocumentsDocumentIdAnnotations(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>>
    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationBody = AnnotationsEvent
    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Documents Document Id Annotations
 */
export const usePostProjectsIdDocumentsDocumentIdAnnotations = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdAnnotations>>,
        TError,
        {slug: string;documentId: string;data: AnnotationsEvent},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdDocumentsDocumentIdAnnotationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Documents Document Id Annotations And Equip
 */
export const getProjectsIdDocumentsDocumentIdAnnotationsAndEquip = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<AnnotationsAndEquipmentResponse>(
      {url: `/v1/projects/${slug}/${documentId}/annotations_and_equipment`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/annotations_and_equipment`] as const;
    }

    
export const getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey(slug,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>> = ({ signal }) => getProjectsIdDocumentsDocumentIdAnnotationsAndEquip(slug,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>>
export type GetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id Annotations And Equip
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquip = <TData = Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationsAndEquip>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Annotations endpoint for new takeoff schema (done on FE)
We now only store the annotations, without any further parsing
 * @summary Update Annotations List In Doc
 */
export const updateAnnotationsListInDoc = (
    slug: string,
    documentId: string,
    annotationsEvent: AnnotationsEvent,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<UpdateAnnotationsListInDoc201>(
      {url: `/v1/projects/${slug}/${documentId}/annotations/update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: annotationsEvent
    },
      options);
    }
  


export const getUpdateAnnotationsListInDocMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAnnotationsListInDoc>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAnnotationsListInDoc>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAnnotationsListInDoc>>, {slug: string;documentId: string;data: AnnotationsEvent}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  updateAnnotationsListInDoc(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAnnotationsListInDocMutationResult = NonNullable<Awaited<ReturnType<typeof updateAnnotationsListInDoc>>>
    export type UpdateAnnotationsListInDocMutationBody = AnnotationsEvent
    export type UpdateAnnotationsListInDocMutationError = HTTPValidationError

    /**
 * @summary Update Annotations List In Doc
 */
export const useUpdateAnnotationsListInDoc = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAnnotationsListInDoc>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateAnnotationsListInDoc>>,
        TError,
        {slug: string;documentId: string;data: AnnotationsEvent},
        TContext
      > => {

      const mutationOptions = getUpdateAnnotationsListInDocMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Documents Document Id Lock Annotation
 */
export const getProjectsIdDocumentsDocumentIdLockAnnotation = (
    slug: string,
    documentId: string,
    documentAnnotationAdd: DocumentAnnotationAdd,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Document>(
      {url: `/v1/projects/${slug}/${documentId}/lock_annotation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentAnnotationAdd
    },
      options);
    }
  


export const getGetProjectsIdDocumentsDocumentIdLockAnnotationMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>, {slug: string;documentId: string;data: DocumentAnnotationAdd}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  getProjectsIdDocumentsDocumentIdLockAnnotation(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>>
    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationBody = DocumentAnnotationAdd
    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationError = HTTPValidationError

    /**
 * @summary Get Projects Id Documents Document Id Lock Annotation
 */
export const useGetProjectsIdDocumentsDocumentIdLockAnnotation = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdLockAnnotation>>,
        TError,
        {slug: string;documentId: string;data: DocumentAnnotationAdd},
        TContext
      > => {

      const mutationOptions = getGetProjectsIdDocumentsDocumentIdLockAnnotationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Documents Document Id Update
 */
export const postProjectsIdDocumentsDocumentIdUpdate = (
    slug: string,
    documentId: string,
    documentMetadataUpdate: DocumentMetadataUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentMetadataUpdate
    },
      options);
    }
  


export const getPostProjectsIdDocumentsDocumentIdUpdateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>, {slug: string;documentId: string;data: DocumentMetadataUpdate}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  postProjectsIdDocumentsDocumentIdUpdate(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdDocumentsDocumentIdUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>>
    export type PostProjectsIdDocumentsDocumentIdUpdateMutationBody = DocumentMetadataUpdate
    export type PostProjectsIdDocumentsDocumentIdUpdateMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Documents Document Id Update
 */
export const usePostProjectsIdDocumentsDocumentIdUpdate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdDocumentsDocumentIdUpdate>>,
        TError,
        {slug: string;documentId: string;data: DocumentMetadataUpdate},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdDocumentsDocumentIdUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Auto name pages based on repeating bbox (iterate over pages and extract text in bbox)
 * @summary Auto Name Pages
 */
export const autoNamePages = (
    slug: string,
    documentId: unknown,
    documentPageTitleAnnotation: DocumentPageTitleAnnotation,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/auto_page_naming`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentPageTitleAnnotation
    },
      options);
    }
  


export const getAutoNamePagesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof autoNamePages>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof autoNamePages>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof autoNamePages>>, {slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  autoNamePages(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutoNamePagesMutationResult = NonNullable<Awaited<ReturnType<typeof autoNamePages>>>
    export type AutoNamePagesMutationBody = DocumentPageTitleAnnotation
    export type AutoNamePagesMutationError = HTTPValidationError

    /**
 * @summary Auto Name Pages
 */
export const useAutoNamePages = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof autoNamePages>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof autoNamePages>>,
        TError,
        {slug: string;documentId: unknown;data: DocumentPageTitleAnnotation},
        TContext
      > => {

      const mutationOptions = getAutoNamePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Delete Documents
 */
export const batchDeleteDocuments = (
    slug: string,
    batchDeleteDocumentsBody: string[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteDocumentsBody
    },
      options);
    }
  


export const getBatchDeleteDocumentsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteDocuments>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof batchDeleteDocuments>>, TError,{slug: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDeleteDocuments>>, {slug: string;data: string[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  batchDeleteDocuments(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteDocumentsMutationResult = NonNullable<Awaited<ReturnType<typeof batchDeleteDocuments>>>
    export type BatchDeleteDocumentsMutationBody = string[]
    export type BatchDeleteDocumentsMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Documents
 */
export const useBatchDeleteDocuments = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteDocuments>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof batchDeleteDocuments>>,
        TError,
        {slug: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getBatchDeleteDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Projects Id Documents Document Id
 */
export const deleteProjectsIdDocumentsDocumentId = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectsIdDocumentsDocumentIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>, TError,{slug: string;documentId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>, TError,{slug: string;documentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>, {slug: string;documentId: string}> = (props) => {
          const {slug,documentId} = props ?? {};

          return  deleteProjectsIdDocumentsDocumentId(slug,documentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdDocumentsDocumentIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>>
    
    export type DeleteProjectsIdDocumentsDocumentIdMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Documents Document Id
 */
export const useDeleteProjectsIdDocumentsDocumentId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>, TError,{slug: string;documentId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsIdDocumentsDocumentId>>,
        TError,
        {slug: string;documentId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsIdDocumentsDocumentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * check if all project documents are ready to start accepting queries
 * @summary Check If All Documents Are Vectorized
 */
export const checkIfAllDocumentsAreVectorized = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<ReadyForQueries>(
      {url: `/v1/projects/${slug}/documents/ready`, method: 'GET', signal
    },
      options);
    }
  

export const getCheckIfAllDocumentsAreVectorizedQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/documents/ready`] as const;
    }

    
export const getCheckIfAllDocumentsAreVectorizedQueryOptions = <TData = Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckIfAllDocumentsAreVectorizedQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>> = ({ signal }) => checkIfAllDocumentsAreVectorized(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckIfAllDocumentsAreVectorizedQueryResult = NonNullable<Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>>
export type CheckIfAllDocumentsAreVectorizedQueryError = HTTPValidationError

/**
 * @summary Check If All Documents Are Vectorized
 */
export const useCheckIfAllDocumentsAreVectorized = <TData = Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof checkIfAllDocumentsAreVectorized>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckIfAllDocumentsAreVectorizedQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Check If Document Is Vecorized
 */
export const checkIfDocumentIsVecorized = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/ready`, method: 'GET', signal
    },
      options);
    }
  

export const getCheckIfDocumentIsVecorizedQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/ready`] as const;
    }

    
export const getCheckIfDocumentIsVecorizedQueryOptions = <TData = Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckIfDocumentIsVecorizedQueryKey(slug,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>> = ({ signal }) => checkIfDocumentIsVecorized(slug,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckIfDocumentIsVecorizedQueryResult = NonNullable<Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>>
export type CheckIfDocumentIsVecorizedQueryError = HTTPValidationError

/**
 * @summary Check If Document Is Vecorized
 */
export const useCheckIfDocumentIsVecorized = <TData = Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof checkIfDocumentIsVecorized>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCheckIfDocumentIsVecorizedQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add a page to document favorite pages list (if not already there)
 * @summary Add To Favorites
 */
export const addToFavorites = (
    slug: string,
    documentId: string,
    params: AddToFavoritesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/${documentId}/add-favorite-page`, method: 'POST',
        params
    },
      options);
    }
  


export const getAddToFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToFavorites>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addToFavorites>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addToFavorites>>, {slug: string;documentId: string;params: AddToFavoritesParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  addToFavorites(slug,documentId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToFavoritesMutationResult = NonNullable<Awaited<ReturnType<typeof addToFavorites>>>
    
    export type AddToFavoritesMutationError = HTTPValidationError

    /**
 * @summary Add To Favorites
 */
export const useAddToFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addToFavorites>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof addToFavorites>>,
        TError,
        {slug: string;documentId: string;params: AddToFavoritesParams},
        TContext
      > => {

      const mutationOptions = getAddToFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a page from document favorite pages list (if present)
 * @summary Remove From Favorites
 */
export const removeFromFavorites = (
    slug: string,
    documentId: string,
    params: RemoveFromFavoritesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/${documentId}/remove-favorite-page`, method: 'POST',
        params
    },
      options);
    }
  


export const getRemoveFromFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeFromFavorites>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeFromFavorites>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeFromFavorites>>, {slug: string;documentId: string;params: RemoveFromFavoritesParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  removeFromFavorites(slug,documentId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveFromFavoritesMutationResult = NonNullable<Awaited<ReturnType<typeof removeFromFavorites>>>
    
    export type RemoveFromFavoritesMutationError = HTTPValidationError

    /**
 * @summary Remove From Favorites
 */
export const useRemoveFromFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeFromFavorites>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof removeFromFavorites>>,
        TError,
        {slug: string;documentId: string;params: RemoveFromFavoritesParams},
        TContext
      > => {

      const mutationOptions = getRemoveFromFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rename a document
 * @summary Rename Document
 */
export const renameDocument = (
    slug: string,
    documentId: string,
    params: RenameDocumentParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/${documentId}/rename`, method: 'POST',
        params
    },
      options);
    }
  


export const getRenameDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameDocument>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof renameDocument>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameDocument>>, {slug: string;documentId: string;params: RenameDocumentParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  renameDocument(slug,documentId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof renameDocument>>>
    
    export type RenameDocumentMutationError = HTTPValidationError

    /**
 * @summary Rename Document
 */
export const useRenameDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameDocument>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof renameDocument>>,
        TError,
        {slug: string;documentId: string;params: RenameDocumentParams},
        TContext
      > => {

      const mutationOptions = getRenameDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rename a page in a document
 * @summary Rename Page
 */
export const renamePage = (
    slug: string,
    documentId: string,
    params: RenamePageParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/${documentId}/page/rename`, method: 'POST',
        params
    },
      options);
    }
  


export const getRenamePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renamePage>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof renamePage>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renamePage>>, {slug: string;documentId: string;params: RenamePageParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  renamePage(slug,documentId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenamePageMutationResult = NonNullable<Awaited<ReturnType<typeof renamePage>>>
    
    export type RenamePageMutationError = HTTPValidationError

    /**
 * @summary Rename Page
 */
export const useRenamePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renamePage>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof renamePage>>,
        TError,
        {slug: string;documentId: string;params: RenamePageParams},
        TContext
      > => {

      const mutationOptions = getRenamePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rerun document ingestion for documents that aren't ingested, set document_id to rerun a specific document
 * @summary Rerun Docs
 */
export const rerunDocs = (
    slug: string,
    params?: RerunDocsParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/rerun`, method: 'POST',
        params
    },
      options);
    }
  


export const getRerunDocsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rerunDocs>>, TError,{slug: string;params?: RerunDocsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof rerunDocs>>, TError,{slug: string;params?: RerunDocsParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof rerunDocs>>, {slug: string;params?: RerunDocsParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  rerunDocs(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RerunDocsMutationResult = NonNullable<Awaited<ReturnType<typeof rerunDocs>>>
    
    export type RerunDocsMutationError = HTTPValidationError

    /**
 * @summary Rerun Docs
 */
export const useRerunDocs = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rerunDocs>>, TError,{slug: string;params?: RerunDocsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof rerunDocs>>,
        TError,
        {slug: string;params?: RerunDocsParams},
        TContext
      > => {

      const mutationOptions = getRerunDocsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get versions of a document
 * @summary Get Document Versions
 */
export const getDocumentVersions = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/versions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentVersionsQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/versions`] as const;
    }

    
export const getGetDocumentVersionsQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentVersions>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentVersionsQueryKey(slug,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentVersions>>> = ({ signal }) => getDocumentVersions(slug,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentVersions>>>
export type GetDocumentVersionsQueryError = HTTPValidationError

/**
 * @summary Get Document Versions
 */
export const useGetDocumentVersions = <TData = Awaited<ReturnType<typeof getDocumentVersions>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocumentVersionsQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete a version of a document
 * @summary Delete Document Version
 */
export const deleteDocumentVersion = (
    slug: string,
    documentId: string,
    versionId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/versions/${versionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDocumentVersionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDocumentVersion>>, {slug: string;documentId: string;versionId: string}> = (props) => {
          const {slug,documentId,versionId} = props ?? {};

          return  deleteDocumentVersion(slug,documentId,versionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDocumentVersion>>>
    
    export type DeleteDocumentVersionMutationError = HTTPValidationError

    /**
 * @summary Delete Document Version
 */
export const useDeleteDocumentVersion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDocumentVersion>>,
        TError,
        {slug: string;documentId: string;versionId: string},
        TContext
      > => {

      const mutationOptions = getDeleteDocumentVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a version of a document
 * @summary Get Document Version
 */
export const getDocumentVersion = (
    slug: string,
    documentId: string,
    versionId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/${documentId}/versions/${versionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentVersionQueryKey = (slug: string,
    documentId: string,
    versionId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/versions/${versionId}`] as const;
    }

    
export const getGetDocumentVersionQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentVersion>>, TError = HTTPValidationError>(slug: string,
    documentId: string,
    versionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentVersionQueryKey(slug,documentId,versionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentVersion>>> = ({ signal }) => getDocumentVersion(slug,documentId,versionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId && versionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersion>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentVersion>>>
export type GetDocumentVersionQueryError = HTTPValidationError

/**
 * @summary Get Document Version
 */
export const useGetDocumentVersion = <TData = Awaited<ReturnType<typeof getDocumentVersion>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string,
    versionId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocumentVersionQueryOptions(slug,documentId,versionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * make version as the main version of the document
 * @summary Replace Main Version
 */
export const replaceMainVersion = (
    slug: string,
    documentId: string,
    versionId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<DocumentMetadata>(
      {url: `/v1/projects/${slug}/${documentId}/versions/${versionId}/replace`, method: 'POST'
    },
      options);
    }
  


export const getReplaceMainVersionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof replaceMainVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof replaceMainVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof replaceMainVersion>>, {slug: string;documentId: string;versionId: string}> = (props) => {
          const {slug,documentId,versionId} = props ?? {};

          return  replaceMainVersion(slug,documentId,versionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReplaceMainVersionMutationResult = NonNullable<Awaited<ReturnType<typeof replaceMainVersion>>>
    
    export type ReplaceMainVersionMutationError = HTTPValidationError

    /**
 * @summary Replace Main Version
 */
export const useReplaceMainVersion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof replaceMainVersion>>, TError,{slug: string;documentId: string;versionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof replaceMainVersion>>,
        TError,
        {slug: string;documentId: string;versionId: string},
        TContext
      > => {

      const mutationOptions = getReplaceMainVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Document Status
 */
export const getDocumentStatus = (
    slug: string,
    documentId: string,
    params: GetDocumentStatusParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<DocumentIngestionStatusResponse>(
      {url: `/v1/projects/${slug}/${documentId}/status`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetDocumentStatusQueryKey = (slug: string,
    documentId: string,
    params: GetDocumentStatusParams,) => {
    return [`/v1/projects/${slug}/${documentId}/status`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDocumentStatusQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentStatus>>, TError = HTTPValidationError>(slug: string,
    documentId: string,
    params: GetDocumentStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentStatusQueryKey(slug,documentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentStatus>>> = ({ signal }) => getDocumentStatus(slug,documentId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentStatus>>>
export type GetDocumentStatusQueryError = HTTPValidationError

/**
 * @summary Get Document Status
 */
export const useGetDocumentStatus = <TData = Awaited<ReturnType<typeof getDocumentStatus>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string,
    params: GetDocumentStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocumentStatusQueryOptions(slug,documentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Users Me
 */
export const getUsersMe = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<UserResponse>(
      {url: `/v1/users/me`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUsersMeQueryKey = () => {
    return [`/v1/users/me`] as const;
    }

    
export const getGetUsersMeQueryOptions = <TData = Awaited<ReturnType<typeof getUsersMe>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersMe>>> = ({ signal }) => getUsersMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersMe>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersMeQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersMe>>>
export type GetUsersMeQueryError = unknown

/**
 * @summary Get Users Me
 */
export const useGetUsersMe = <TData = Awaited<ReturnType<typeof getUsersMe>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersMe>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Users
 */
export const getUsers = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<User[]>(
      {url: `/v1/users/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUsersQueryKey = () => {
    return [`/v1/users/`] as const;
    }

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = unknown

/**
 * @summary Get Users
 */
export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Organization Users
 */
export const listOrganizationUsers = (
    orgId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<BaseUser[]>(
      {url: `/v1/users/${orgId}`, method: 'GET', signal
    },
      options);
    }
  

export const getListOrganizationUsersQueryKey = (orgId: string,) => {
    return [`/v1/users/${orgId}`] as const;
    }

    
export const getListOrganizationUsersQueryOptions = <TData = Awaited<ReturnType<typeof listOrganizationUsers>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listOrganizationUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrganizationUsersQueryKey(orgId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrganizationUsers>>> = ({ signal }) => listOrganizationUsers(orgId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listOrganizationUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type ListOrganizationUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listOrganizationUsers>>>
export type ListOrganizationUsersQueryError = HTTPValidationError

/**
 * @summary List Organization Users
 */
export const useListOrganizationUsers = <TData = Awaited<ReturnType<typeof listOrganizationUsers>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listOrganizationUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListOrganizationUsersQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Users Id
 */
export const getUsersId = (
    id: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<User>(
      {url: `/v1/users/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUsersIdQueryKey = (id: string,) => {
    return [`/v1/users/${id}`] as const;
    }

    
export const getGetUsersIdQueryOptions = <TData = Awaited<ReturnType<typeof getUsersId>>, TError = HTTPValidationError>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersId>>> = ({ signal }) => getUsersId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersId>>>
export type GetUsersIdQueryError = HTTPValidationError

/**
 * @summary Get Users Id
 */
export const useGetUsersId = <TData = Awaited<ReturnType<typeof getUsersId>>, TError = HTTPValidationError>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Put Users Id
 */
export const putUsersId = (
    id: string,
    userUpdate: UserUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<User>(
      {url: `/v1/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdate
    },
      options);
    }
  


export const getPutUsersIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUsersId>>, TError,{id: string;data: UserUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof putUsersId>>, TError,{id: string;data: UserUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUsersId>>, {id: string;data: UserUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  putUsersId(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUsersIdMutationResult = NonNullable<Awaited<ReturnType<typeof putUsersId>>>
    export type PutUsersIdMutationBody = UserUpdate
    export type PutUsersIdMutationError = HTTPValidationError

    /**
 * @summary Put Users Id
 */
export const usePutUsersId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUsersId>>, TError,{id: string;data: UserUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUsersId>>,
        TError,
        {id: string;data: UserUpdate},
        TContext
      > => {

      const mutationOptions = getPutUsersIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Search for users using query str against user names and emails, limit is the max number of results to return, offset is the number of results to skip
 * @summary Search Users
 */
export const searchUsers = (
    queryStr: string,
    params?: SearchUsersParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<BaseUser[]>(
      {url: `/v1/users/search/${queryStr}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchUsersQueryKey = (queryStr: string,
    params?: SearchUsersParams,) => {
    return [`/v1/users/search/${queryStr}`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchUsersQueryOptions = <TData = Awaited<ReturnType<typeof searchUsers>>, TError = HTTPValidationError>(queryStr: string,
    params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchUsersQueryKey(queryStr,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsers>>> = ({ signal }) => searchUsers(queryStr,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(queryStr), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchUsersQueryResult = NonNullable<Awaited<ReturnType<typeof searchUsers>>>
export type SearchUsersQueryError = HTTPValidationError

/**
 * @summary Search Users
 */
export const useSearchUsers = <TData = Awaited<ReturnType<typeof searchUsers>>, TError = HTTPValidationError>(
 queryStr: string,
    params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchUsersQueryOptions(queryStr,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Put Users Algo Settings
 */
export const putUsersAlgoSettings = (
    id: string,
    algorithmSettings: AlgorithmSettings,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/users${id}/algo_settings`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: algorithmSettings
    },
      options);
    }
  


export const getPutUsersAlgoSettingsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUsersAlgoSettings>>, TError,{id: string;data: AlgorithmSettings}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof putUsersAlgoSettings>>, TError,{id: string;data: AlgorithmSettings}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUsersAlgoSettings>>, {id: string;data: AlgorithmSettings}> = (props) => {
          const {id,data} = props ?? {};

          return  putUsersAlgoSettings(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUsersAlgoSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof putUsersAlgoSettings>>>
    export type PutUsersAlgoSettingsMutationBody = AlgorithmSettings
    export type PutUsersAlgoSettingsMutationError = HTTPValidationError

    /**
 * @summary Put Users Algo Settings
 */
export const usePutUsersAlgoSettings = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUsersAlgoSettings>>, TError,{id: string;data: AlgorithmSettings}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof putUsersAlgoSettings>>,
        TError,
        {id: string;data: AlgorithmSettings},
        TContext
      > => {

      const mutationOptions = getPutUsersAlgoSettingsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the list of notes in a project
 * @summary Get Projects Id Notes
 */
export const getProjectsIdNotes = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Note[]>(
      {url: `/v1/projects/${slug}/notes/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsIdNotesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/notes/`] as const;
    }

    
export const getGetProjectsIdNotesQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsIdNotes>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdNotesQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsIdNotes>>> = ({ signal }) => getProjectsIdNotes(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdNotes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdNotes>>>
export type GetProjectsIdNotesQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Notes
 */
export const useGetProjectsIdNotes = <TData = Awaited<ReturnType<typeof getProjectsIdNotes>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsIdNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsIdNotesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a note in a project
 * @summary Post Projects Id Notes
 */
export const postProjectsIdNotes = (
    slug: string,
    note: Note,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Note>(
      {url: `/v1/projects/${slug}/notes/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: note
    },
      options);
    }
  


export const getPostProjectsIdNotesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdNotes>>, TError,{slug: string;data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdNotes>>, TError,{slug: string;data: Note}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsIdNotes>>, {slug: string;data: Note}> = (props) => {
          const {slug,data} = props ?? {};

          return  postProjectsIdNotes(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdNotesMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsIdNotes>>>
    export type PostProjectsIdNotesMutationBody = Note
    export type PostProjectsIdNotesMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Notes
 */
export const usePostProjectsIdNotes = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsIdNotes>>, TError,{slug: string;data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsIdNotes>>,
        TError,
        {slug: string;data: Note},
        TContext
      > => {

      const mutationOptions = getPostProjectsIdNotesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a note in a project, this is a soft delete, to fully remove the note, set remove=True below
 * @summary Delete Projects Id Notes Note Id
 */
export const deleteProjectsIdNotesNoteId = (
    slug: string,
    noteId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<void>(
      {url: `/v1/projects/${slug}/notes/${noteId}/delete`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectsIdNotesNoteIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>, {slug: string;noteId: string}> = (props) => {
          const {slug,noteId} = props ?? {};

          return  deleteProjectsIdNotesNoteId(slug,noteId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdNotesNoteIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>>
    
    export type DeleteProjectsIdNotesNoteIdMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Notes Note Id
 */
export const useDeleteProjectsIdNotesNoteId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsIdNotesNoteId>>,
        TError,
        {slug: string;noteId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsIdNotesNoteIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Note updates can originate in 2 ways:
1. an implicit put request with a NoteUpdate object with the new values
2. Note was modified directly in the pspdf and comes in as an annotation, detected as "modified" in the dispatcher
 * @summary Put Projects Id Notes Note Id
 */
export const putProjectsIdNotesNoteId = (
    slug: string,
    noteId: string,
    noteUpdate: NoteUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Note>(
      {url: `/v1/projects/${slug}/notes/${noteId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: noteUpdate
    },
      options);
    }
  


export const getPutProjectsIdNotesNoteIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>, {slug: string;noteId: string;data: NoteUpdate}> = (props) => {
          const {slug,noteId,data} = props ?? {};

          return  putProjectsIdNotesNoteId(slug,noteId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutProjectsIdNotesNoteIdMutationResult = NonNullable<Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>>
    export type PutProjectsIdNotesNoteIdMutationBody = NoteUpdate
    export type PutProjectsIdNotesNoteIdMutationError = HTTPValidationError

    /**
 * @summary Put Projects Id Notes Note Id
 */
export const usePutProjectsIdNotesNoteId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof putProjectsIdNotesNoteId>>,
        TError,
        {slug: string;noteId: string;data: NoteUpdate},
        TContext
      > => {

      const mutationOptions = getPutProjectsIdNotesNoteIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Slug Automate Chat With Automate New Question
 */
export const postProjectsSlugAutomateChatWithAutomateNewQuestion = (
    slug: string,
    params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Automate>(
      {url: `/v1/projects/${slug}/automate/chat_with_automate/new_question`, method: 'POST',
        params
    },
      options);
    }
  


export const getPostProjectsSlugAutomateChatWithAutomateNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>, {slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  postProjectsSlugAutomateChatWithAutomateNewQuestion(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsSlugAutomateChatWithAutomateNewQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>>
    
    export type PostProjectsSlugAutomateChatWithAutomateNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Projects Slug Automate Chat With Automate New Question
 */
export const usePostProjectsSlugAutomateChatWithAutomateNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsSlugAutomateChatWithAutomateNewQuestion>>,
        TError,
        {slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams},
        TContext
      > => {

      const mutationOptions = getPostProjectsSlugAutomateChatWithAutomateNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Slug Automate Thread
 */
export const getProjectsSlugAutomateThread = (
    slug: string,
    params?: GetProjectsSlugAutomateThreadParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Automate>(
      {url: `/v1/projects/${slug}/automate/thread`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProjectsSlugAutomateThreadQueryKey = (slug: string,
    params?: GetProjectsSlugAutomateThreadParams,) => {
    return [`/v1/projects/${slug}/automate/thread`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsSlugAutomateThreadQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>, TError = HTTPValidationError>(slug: string,
    params?: GetProjectsSlugAutomateThreadParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsSlugAutomateThreadQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>> = ({ signal }) => getProjectsSlugAutomateThread(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsSlugAutomateThreadQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>>
export type GetProjectsSlugAutomateThreadQueryError = HTTPValidationError

/**
 * @summary Get Projects Slug Automate Thread
 */
export const useGetProjectsSlugAutomateThread = <TData = Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>, TError = HTTPValidationError>(
 slug: string,
    params?: GetProjectsSlugAutomateThreadParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsSlugAutomateThreadQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Slug Automate Latest Thread
 */
export const getProjectsSlugAutomateLatestThread = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Automate>(
      {url: `/v1/projects/${slug}/automate/latest_thread`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectsSlugAutomateLatestThreadQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/automate/latest_thread`] as const;
    }

    
export const getGetProjectsSlugAutomateLatestThreadQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsSlugAutomateLatestThreadQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>> = ({ signal }) => getProjectsSlugAutomateLatestThread(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsSlugAutomateLatestThreadQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>>
export type GetProjectsSlugAutomateLatestThreadQueryError = HTTPValidationError

/**
 * @summary Get Projects Slug Automate Latest Thread
 */
export const useGetProjectsSlugAutomateLatestThread = <TData = Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSlugAutomateLatestThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsSlugAutomateLatestThreadQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ask Automate
 */
export const askAutomate = (
    slug: string,
    bodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<string>(
      {url: `/v1/projects/${slug}/automate/new_question_wo_stream`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost
    },
      options);
    }
  


export const getAskAutomateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof askAutomate>>, TError,{slug: string;data: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof askAutomate>>, TError,{slug: string;data: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof askAutomate>>, {slug: string;data: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost}> = (props) => {
          const {slug,data} = props ?? {};

          return  askAutomate(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AskAutomateMutationResult = NonNullable<Awaited<ReturnType<typeof askAutomate>>>
    export type AskAutomateMutationBody = BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost
    export type AskAutomateMutationError = HTTPValidationError

    /**
 * @summary Ask Automate
 */
export const useAskAutomate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof askAutomate>>, TError,{slug: string;data: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof askAutomate>>,
        TError,
        {slug: string;data: BodyAskAutomateV1ProjectsSlugAutomateNewQuestionWoStreamPost},
        TContext
      > => {

      const mutationOptions = getAskAutomateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Edit a page by page ID by action
 * @summary Edit Page
 */
export const editPage = (
    slug: string,
    pageId: string,
    pageEditAction: PageEditAction,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/automate/${pageId}/page_action`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pageEditAction
    },
      options);
    }
  


export const getEditPageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editPage>>, TError,{slug: string;pageId: string;data: PageEditAction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof editPage>>, TError,{slug: string;pageId: string;data: PageEditAction}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editPage>>, {slug: string;pageId: string;data: PageEditAction}> = (props) => {
          const {slug,pageId,data} = props ?? {};

          return  editPage(slug,pageId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditPageMutationResult = NonNullable<Awaited<ReturnType<typeof editPage>>>
    export type EditPageMutationBody = PageEditAction
    export type EditPageMutationError = HTTPValidationError

    /**
 * @summary Edit Page
 */
export const useEditPage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editPage>>, TError,{slug: string;pageId: string;data: PageEditAction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof editPage>>,
        TError,
        {slug: string;pageId: string;data: PageEditAction},
        TContext
      > => {

      const mutationOptions = getEditPageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a list of pages metadata objects (all but the actual content & markdown) + organization/user templates
 * @summary Get All Pages
 */
export const getAllPages = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<PageMetadata[]>(
      {url: `/v1/projects/${slug}/pages/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllPagesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/pages/`] as const;
    }

    
export const getGetAllPagesQueryOptions = <TData = Awaited<ReturnType<typeof getAllPages>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPages>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPagesQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPages>>> = ({ signal }) => getAllPages(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllPages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPagesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllPages>>>
export type GetAllPagesQueryError = HTTPValidationError

/**
 * @summary Get All Pages
 */
export const useGetAllPages = <TData = Awaited<ReturnType<typeof getAllPages>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPages>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllPagesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new page
 * @summary Automate Page
 */
export const automatePage = (
    slug: string,
    pageCreate: PageCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PageResponse>(
      {url: `/v1/projects/${slug}/pages/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pageCreate
    },
      options);
    }
  


export const getAutomatePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof automatePage>>, TError,{slug: string;data: PageCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof automatePage>>, TError,{slug: string;data: PageCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof automatePage>>, {slug: string;data: PageCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  automatePage(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutomatePageMutationResult = NonNullable<Awaited<ReturnType<typeof automatePage>>>
    export type AutomatePageMutationBody = PageCreate
    export type AutomatePageMutationError = HTTPValidationError

    /**
 * @summary Automate Page
 */
export const useAutomatePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof automatePage>>, TError,{slug: string;data: PageCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof automatePage>>,
        TError,
        {slug: string;data: PageCreate},
        TContext
      > => {

      const mutationOptions = getAutomatePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a page by page ID (only replace non-null fields)
 * @summary Update Pages
 */
export const updatePages = (
    slug: string,
    pagesUpdate: PagesUpdate[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/pages/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pagesUpdate
    },
      options);
    }
  


export const getUpdatePagesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePages>>, TError,{slug: string;data: PagesUpdate[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePages>>, TError,{slug: string;data: PagesUpdate[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePages>>, {slug: string;data: PagesUpdate[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  updatePages(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePagesMutationResult = NonNullable<Awaited<ReturnType<typeof updatePages>>>
    export type UpdatePagesMutationBody = PagesUpdate[]
    export type UpdatePagesMutationError = HTTPValidationError

    /**
 * @summary Update Pages
 */
export const useUpdatePages = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePages>>, TError,{slug: string;data: PagesUpdate[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePages>>,
        TError,
        {slug: string;data: PagesUpdate[]},
        TContext
      > => {

      const mutationOptions = getUpdatePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a full page by page id
 * @summary Get Page By Id
 */
export const getPageById = (
    slug: string,
    pageId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<PageResponse>(
      {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPageByIdQueryKey = (slug: string,
    pageId: string,) => {
    return [`/v1/projects/${slug}/pages/${pageId}`] as const;
    }

    
export const getGetPageByIdQueryOptions = <TData = Awaited<ReturnType<typeof getPageById>>, TError = HTTPValidationError>(slug: string,
    pageId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPageByIdQueryKey(slug,pageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPageById>>> = ({ signal }) => getPageById(slug,pageId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && pageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPageById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPageByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPageById>>>
export type GetPageByIdQueryError = HTTPValidationError

/**
 * @summary Get Page By Id
 */
export const useGetPageById = <TData = Awaited<ReturnType<typeof getPageById>>, TError = HTTPValidationError>(
 slug: string,
    pageId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPageById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPageByIdQueryOptions(slug,pageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a page by page ID (only replace non-null fields)
 * @summary Update Page
 */
export const updatePage = (
    slug: string,
    pageId: string,
    pageUpdate: PageUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PageResponse>(
      {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pageUpdate
    },
      options);
    }
  


export const getUpdatePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePage>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePage>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePage>>, {slug: string;pageId: string;data: PageUpdate}> = (props) => {
          const {slug,pageId,data} = props ?? {};

          return  updatePage(slug,pageId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePageMutationResult = NonNullable<Awaited<ReturnType<typeof updatePage>>>
    export type UpdatePageMutationBody = PageUpdate
    export type UpdatePageMutationError = HTTPValidationError

    /**
 * @summary Update Page
 */
export const useUpdatePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePage>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePage>>,
        TError,
        {slug: string;pageId: string;data: PageUpdate},
        TContext
      > => {

      const mutationOptions = getUpdatePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Page
 */
export const deletePage = (
    slug: string,
    pageId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePage>>, TError,{slug: string;pageId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePage>>, TError,{slug: string;pageId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePage>>, {slug: string;pageId: string}> = (props) => {
          const {slug,pageId} = props ?? {};

          return  deletePage(slug,pageId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePageMutationResult = NonNullable<Awaited<ReturnType<typeof deletePage>>>
    
    export type DeletePageMutationError = HTTPValidationError

    /**
 * @summary Delete Page
 */
export const useDeletePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePage>>, TError,{slug: string;pageId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePage>>,
        TError,
        {slug: string;pageId: string},
        TContext
      > => {

      const mutationOptions = getDeletePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Duplicate a page by page ID (essentially its content, title and markdown fields). Make it of to_page_type type
 * @summary Duplicate Page
 */
export const duplicatePage = (
    slug: string,
    pageId: string,
    params: DuplicatePageParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PageResponse>(
      {url: `/v1/projects/${slug}/pages/${pageId}/duplicate`, method: 'POST',
        params
    },
      options);
    }
  


export const getDuplicatePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicatePage>>, TError,{slug: string;pageId: string;params: DuplicatePageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof duplicatePage>>, TError,{slug: string;pageId: string;params: DuplicatePageParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof duplicatePage>>, {slug: string;pageId: string;params: DuplicatePageParams}> = (props) => {
          const {slug,pageId,params} = props ?? {};

          return  duplicatePage(slug,pageId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DuplicatePageMutationResult = NonNullable<Awaited<ReturnType<typeof duplicatePage>>>
    
    export type DuplicatePageMutationError = HTTPValidationError

    /**
 * @summary Duplicate Page
 */
export const useDuplicatePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof duplicatePage>>, TError,{slug: string;pageId: string;params: DuplicatePageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof duplicatePage>>,
        TError,
        {slug: string;pageId: string;params: DuplicatePageParams},
        TContext
      > => {

      const mutationOptions = getDuplicatePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Set a page to private or public (is_private: bool)
 * @summary Set Page Is Private
 */
export const setPageIsPrivate = (
    slug: string,
    pageId: string,
    params: SetPageIsPrivateParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/pages/${pageId}/set_is_private`, method: 'POST',
        params
    },
      options);
    }
  


export const getSetPageIsPrivateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setPageIsPrivate>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof setPageIsPrivate>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setPageIsPrivate>>, {slug: string;pageId: string;params: SetPageIsPrivateParams}> = (props) => {
          const {slug,pageId,params} = props ?? {};

          return  setPageIsPrivate(slug,pageId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetPageIsPrivateMutationResult = NonNullable<Awaited<ReturnType<typeof setPageIsPrivate>>>
    
    export type SetPageIsPrivateMutationError = HTTPValidationError

    /**
 * @summary Set Page Is Private
 */
export const useSetPageIsPrivate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setPageIsPrivate>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof setPageIsPrivate>>,
        TError,
        {slug: string;pageId: string;params: SetPageIsPrivateParams},
        TContext
      > => {

      const mutationOptions = getSetPageIsPrivateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Convert Pdf To Pages
 */
export const convertPdfToPages = (
    slug: string,
    params: ConvertPdfToPagesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<PageConvert>(
      {url: `/v1/projects/${slug}/pages/convert_pdf`, method: 'POST',
        params
    },
      options);
    }
  


export const getConvertPdfToPagesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof convertPdfToPages>>, TError,{slug: string;params: ConvertPdfToPagesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof convertPdfToPages>>, TError,{slug: string;params: ConvertPdfToPagesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof convertPdfToPages>>, {slug: string;params: ConvertPdfToPagesParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  convertPdfToPages(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ConvertPdfToPagesMutationResult = NonNullable<Awaited<ReturnType<typeof convertPdfToPages>>>
    
    export type ConvertPdfToPagesMutationError = HTTPValidationError

    /**
 * @summary Convert Pdf To Pages
 */
export const useConvertPdfToPages = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof convertPdfToPages>>, TError,{slug: string;params: ConvertPdfToPagesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof convertPdfToPages>>,
        TError,
        {slug: string;params: ConvertPdfToPagesParams},
        TContext
      > => {

      const mutationOptions = getConvertPdfToPagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Equipment Pieces
 */
export const getAllEquipmentPieces = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EquipmentPiece[]>(
      {url: `/v1/projects/${slug}/equipment/${documentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllEquipmentPiecesQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/equipment/${documentId}`] as const;
    }

    
export const getGetAllEquipmentPiecesQueryOptions = <TData = Awaited<ReturnType<typeof getAllEquipmentPieces>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEquipmentPieces>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllEquipmentPiecesQueryKey(slug,documentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEquipmentPieces>>> = ({ signal }) => getAllEquipmentPieces(slug,documentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllEquipmentPieces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllEquipmentPiecesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEquipmentPieces>>>
export type GetAllEquipmentPiecesQueryError = HTTPValidationError

/**
 * @summary Get All Equipment Pieces
 */
export const useGetAllEquipmentPieces = <TData = Awaited<ReturnType<typeof getAllEquipmentPieces>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEquipmentPieces>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllEquipmentPiecesQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete equipment piece by id and all its associated annotations
 * @summary Delete Equipment Piece
 */
export const deleteEquipmentPiece = (
    slug: string,
    documentId: string,
    params: DeleteEquipmentPieceParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Document>(
      {url: `/v1/projects/${slug}/equipment/${documentId}`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteEquipmentPieceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentPiece>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentPiece>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEquipmentPiece>>, {slug: string;documentId: string;params: DeleteEquipmentPieceParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  deleteEquipmentPiece(slug,documentId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEquipmentPieceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEquipmentPiece>>>
    
    export type DeleteEquipmentPieceMutationError = HTTPValidationError

    /**
 * @summary Delete Equipment Piece
 */
export const useDeleteEquipmentPiece = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentPiece>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEquipmentPiece>>,
        TError,
        {slug: string;documentId: string;params: DeleteEquipmentPieceParams},
        TContext
      > => {

      const mutationOptions = getDeleteEquipmentPieceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Run Magicwand Sse
 */
export const runMagicwandSse = (
    slug: string,
    documentId: string,
    params: RunMagicwandSseParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/equipment/${documentId}/magicwand`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getRunMagicwandSseQueryKey = (slug: string,
    documentId: string,
    params: RunMagicwandSseParams,) => {
    return [`/v1/projects/${slug}/equipment/${documentId}/magicwand`, ...(params ? [params]: [])] as const;
    }

    
export const getRunMagicwandSseQueryOptions = <TData = Awaited<ReturnType<typeof runMagicwandSse>>, TError = HTTPValidationError>(slug: string,
    documentId: string,
    params: RunMagicwandSseParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof runMagicwandSse>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRunMagicwandSseQueryKey(slug,documentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof runMagicwandSse>>> = ({ signal }) => runMagicwandSse(slug,documentId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof runMagicwandSse>>, TError, TData> & { queryKey: QueryKey }
}

export type RunMagicwandSseQueryResult = NonNullable<Awaited<ReturnType<typeof runMagicwandSse>>>
export type RunMagicwandSseQueryError = HTTPValidationError

/**
 * @summary Run Magicwand Sse
 */
export const useRunMagicwandSse = <TData = Awaited<ReturnType<typeof runMagicwandSse>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string,
    params: RunMagicwandSseParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof runMagicwandSse>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRunMagicwandSseQueryOptions(slug,documentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Equipment Piece
 */
export const getEquipmentPiece = (
    slug: string,
    documentId: string,
    equipmentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EquipmentPiece>(
      {url: `/v1/projects/${slug}/equipment/${documentId}/${equipmentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEquipmentPieceQueryKey = (slug: string,
    documentId: string,
    equipmentId: string,) => {
    return [`/v1/projects/${slug}/equipment/${documentId}/${equipmentId}`] as const;
    }

    
export const getGetEquipmentPieceQueryOptions = <TData = Awaited<ReturnType<typeof getEquipmentPiece>>, TError = HTTPValidationError>(slug: string,
    documentId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEquipmentPiece>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEquipmentPieceQueryKey(slug,documentId,equipmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEquipmentPiece>>> = ({ signal }) => getEquipmentPiece(slug,documentId,equipmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId && equipmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEquipmentPiece>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEquipmentPieceQueryResult = NonNullable<Awaited<ReturnType<typeof getEquipmentPiece>>>
export type GetEquipmentPieceQueryError = HTTPValidationError

/**
 * @summary Get Equipment Piece
 */
export const useGetEquipmentPiece = <TData = Awaited<ReturnType<typeof getEquipmentPiece>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEquipmentPiece>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEquipmentPieceQueryOptions(slug,documentId,equipmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Batch delete equipment pieces by id and all their associated annotations
 * @summary Batch Delete Equipment Piece
 */
export const batchDeleteEquipmentPiece = (
    slug: string,
    documentId: string,
    batchDeleteEquipmentPieceBody: string[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Document>(
      {url: `/v1/projects/${slug}/equipment/${documentId}/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteEquipmentPieceBody
    },
      options);
    }
  


export const getBatchDeleteEquipmentPieceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>, TError,{slug: string;documentId: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>, TError,{slug: string;documentId: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>, {slug: string;documentId: string;data: string[]}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  batchDeleteEquipmentPiece(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteEquipmentPieceMutationResult = NonNullable<Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>>
    export type BatchDeleteEquipmentPieceMutationBody = string[]
    export type BatchDeleteEquipmentPieceMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Equipment Piece
 */
export const useBatchDeleteEquipmentPiece = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>, TError,{slug: string;documentId: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof batchDeleteEquipmentPiece>>,
        TError,
        {slug: string;documentId: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getBatchDeleteEquipmentPieceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Modify Equipment List
 */
export const batchModifyEquipmentList = (
    slug: string,
    documentId: string,
    equipmentListUpdate: EquipmentListUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/equipment/${documentId}/update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: equipmentListUpdate
    },
      options);
    }
  


export const getBatchModifyEquipmentListMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchModifyEquipmentList>>, TError,{slug: string;documentId: string;data: EquipmentListUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof batchModifyEquipmentList>>, TError,{slug: string;documentId: string;data: EquipmentListUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchModifyEquipmentList>>, {slug: string;documentId: string;data: EquipmentListUpdate}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  batchModifyEquipmentList(slug,documentId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchModifyEquipmentListMutationResult = NonNullable<Awaited<ReturnType<typeof batchModifyEquipmentList>>>
    export type BatchModifyEquipmentListMutationBody = EquipmentListUpdate
    export type BatchModifyEquipmentListMutationError = HTTPValidationError

    /**
 * @summary Batch Modify Equipment List
 */
export const useBatchModifyEquipmentList = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchModifyEquipmentList>>, TError,{slug: string;documentId: string;data: EquipmentListUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof batchModifyEquipmentList>>,
        TError,
        {slug: string;documentId: string;data: EquipmentListUpdate},
        TContext
      > => {

      const mutationOptions = getBatchModifyEquipmentListMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Pattern-match bbox against pdf page and add to the equipment list
Accepts a rectangle annotation, creates an empty_equipment_item (isReady=False) and adds it to the equipment list
Dispatch request the computer vision service to pattern-match the equipment item bbox in the pdf page and update the equipment item
(also appends the annotation to the document annotations list)
 * @summary Auto Detect Equipment
 */
export const autoDetectEquipment = (
    slug: string,
    documentEquipmentAnnotation: DocumentEquipmentAnnotation,
    params: AutoDetectEquipmentParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/equipment/auto_detect_equipment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentEquipmentAnnotation,
        params
    },
      options);
    }
  


export const getAutoDetectEquipmentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof autoDetectEquipment>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof autoDetectEquipment>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof autoDetectEquipment>>, {slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  autoDetectEquipment(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutoDetectEquipmentMutationResult = NonNullable<Awaited<ReturnType<typeof autoDetectEquipment>>>
    export type AutoDetectEquipmentMutationBody = DocumentEquipmentAnnotation
    export type AutoDetectEquipmentMutationError = HTTPValidationError

    /**
 * @summary Auto Detect Equipment
 */
export const useAutoDetectEquipment = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof autoDetectEquipment>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof autoDetectEquipment>>,
        TError,
        {slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams},
        TContext
      > => {

      const mutationOptions = getAutoDetectEquipmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Auto Detect Equipment Status
 */
export const autoDetectEquipmentStatus = (
    slug: string,
    equipmentId: string,
    params: AutoDetectEquipmentStatusParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/equipment/auto_detect_equipment/status/${equipmentId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAutoDetectEquipmentStatusQueryKey = (slug: string,
    equipmentId: string,
    params: AutoDetectEquipmentStatusParams,) => {
    return [`/v1/projects/${slug}/equipment/auto_detect_equipment/status/${equipmentId}`, ...(params ? [params]: [])] as const;
    }

    
export const getAutoDetectEquipmentStatusQueryOptions = <TData = Awaited<ReturnType<typeof autoDetectEquipmentStatus>>, TError = HTTPValidationError>(slug: string,
    equipmentId: string,
    params: AutoDetectEquipmentStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autoDetectEquipmentStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAutoDetectEquipmentStatusQueryKey(slug,equipmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof autoDetectEquipmentStatus>>> = ({ signal }) => autoDetectEquipmentStatus(slug,equipmentId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && equipmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof autoDetectEquipmentStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type AutoDetectEquipmentStatusQueryResult = NonNullable<Awaited<ReturnType<typeof autoDetectEquipmentStatus>>>
export type AutoDetectEquipmentStatusQueryError = HTTPValidationError

/**
 * @summary Auto Detect Equipment Status
 */
export const useAutoDetectEquipmentStatus = <TData = Awaited<ReturnType<typeof autoDetectEquipmentStatus>>, TError = HTTPValidationError>(
 slug: string,
    equipmentId: string,
    params: AutoDetectEquipmentStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof autoDetectEquipmentStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAutoDetectEquipmentStatusQueryOptions(slug,equipmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update equipment price or description by ID, note equipment_price_item atm is unique per organization (not project or user)
 * @summary Update Equipment Price
 */
export const updateEquipmentPrice = (
    slug: string,
    equipmentId: string,
    equipmentPiecePriceUpdate: EquipmentPiecePriceUpdate,
    params: UpdateEquipmentPriceParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/equipment/catalog/${equipmentId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: equipmentPiecePriceUpdate,
        params
    },
      options);
    }
  


export const getUpdateEquipmentPriceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentPrice>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentPrice>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEquipmentPrice>>, {slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}> = (props) => {
          const {slug,equipmentId,data,params} = props ?? {};

          return  updateEquipmentPrice(slug,equipmentId,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEquipmentPriceMutationResult = NonNullable<Awaited<ReturnType<typeof updateEquipmentPrice>>>
    export type UpdateEquipmentPriceMutationBody = EquipmentPiecePriceUpdate
    export type UpdateEquipmentPriceMutationError = HTTPValidationError

    /**
 * @summary Update Equipment Price
 */
export const useUpdateEquipmentPrice = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentPrice>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateEquipmentPrice>>,
        TError,
        {slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams},
        TContext
      > => {

      const mutationOptions = getUpdateEquipmentPriceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Feedback
 */
export const createFeedback = (
    feedBack: FeedBack,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FeedBack>(
      {url: `/v1/feedback/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: feedBack
    },
      options);
    }
  


export const getCreateFeedbackMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeedback>>, TError,{data: FeedBack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createFeedback>>, TError,{data: FeedBack}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFeedback>>, {data: FeedBack}> = (props) => {
          const {data} = props ?? {};

          return  createFeedback(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof createFeedback>>>
    export type CreateFeedbackMutationBody = FeedBack
    export type CreateFeedbackMutationError = HTTPValidationError

    /**
 * @summary Create Feedback
 */
export const useCreateFeedback = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeedback>>, TError,{data: FeedBack}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createFeedback>>,
        TError,
        {data: FeedBack},
        TContext
      > => {

      const mutationOptions = getCreateFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all organizations in the tenant
 * @summary Get Organizations
 */
export const getOrganizations = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Organization[]>(
      {url: `/v1/organizations/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationsQueryKey = () => {
    return [`/v1/organizations/`] as const;
    }

    
export const getGetOrganizationsQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizations>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizations>>> = ({ signal }) => getOrganizations(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizations>>>
export type GetOrganizationsQueryError = unknown

/**
 * @summary Get Organizations
 */
export const useGetOrganizations = <TData = Awaited<ReturnType<typeof getOrganizations>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Organization
 */
export const createOrganization = (
    organization: Organization,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Organization>(
      {url: `/v1/organizations/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organization
    },
      options);
    }
  


export const getCreateOrganizationMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganization>>, {data: Organization}> = (props) => {
          const {data} = props ?? {};

          return  createOrganization(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganization>>>
    export type CreateOrganizationMutationBody = Organization
    export type CreateOrganizationMutationError = HTTPValidationError

    /**
 * @summary Create Organization
 */
export const useCreateOrganization = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrganization>>,
        TError,
        {data: Organization},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a mapping of org_id to organization name (for all organizations in the tenant)
 * @summary Get Organization Mapping
 */
export const getOrganizationMapping = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<GetOrganizationMapping200>(
      {url: `/v1/organizations/mapping`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationMappingQueryKey = () => {
    return [`/v1/organizations/mapping`] as const;
    }

    
export const getGetOrganizationMappingQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationMapping>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMapping>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMappingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationMapping>>> = ({ signal }) => getOrganizationMapping(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMapping>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMappingQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationMapping>>>
export type GetOrganizationMappingQueryError = unknown

/**
 * @summary Get Organization Mapping
 */
export const useGetOrganizationMapping = <TData = Awaited<ReturnType<typeof getOrganizationMapping>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMapping>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationMappingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all tags of an organization
 * @summary Get Organization Tags Org Id
 */
export const getOrganizationTagsOrgId = (
    orgId: string,
    params?: GetOrganizationTagsOrgIdParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Tag[]>(
      {url: `/v1/organizations/${orgId}/tags`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrganizationTagsOrgIdQueryKey = (orgId: string,
    params?: GetOrganizationTagsOrgIdParams,) => {
    return [`/v1/organizations/${orgId}/tags`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrganizationTagsOrgIdQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationTagsOrgId>>, TError = HTTPValidationError>(orgId: string,
    params?: GetOrganizationTagsOrgIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTagsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationTagsOrgIdQueryKey(orgId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationTagsOrgId>>> = ({ signal }) => getOrganizationTagsOrgId(orgId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTagsOrgId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationTagsOrgIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationTagsOrgId>>>
export type GetOrganizationTagsOrgIdQueryError = HTTPValidationError

/**
 * @summary Get Organization Tags Org Id
 */
export const useGetOrganizationTagsOrgId = <TData = Awaited<ReturnType<typeof getOrganizationTagsOrgId>>, TError = HTTPValidationError>(
 orgId: string,
    params?: GetOrganizationTagsOrgIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTagsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationTagsOrgIdQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new tag in an organization - only if it does not exist already (unique by name)
 * @summary Create Organization Tag
 */
export const createOrganizationTag = (
    orgId: string,
    tag: Tag,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Tag>(
      {url: `/v1/organizations/${orgId}/tags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
      options);
    }
  


export const getCreateOrganizationTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationTag>>, TError,{orgId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganizationTag>>, TError,{orgId: string;data: Tag}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganizationTag>>, {orgId: string;data: Tag}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationTag(orgId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationTagMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganizationTag>>>
    export type CreateOrganizationTagMutationBody = Tag
    export type CreateOrganizationTagMutationError = HTTPValidationError

    /**
 * @summary Create Organization Tag
 */
export const useCreateOrganizationTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationTag>>, TError,{orgId: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrganizationTag>>,
        TError,
        {orgId: string;data: Tag},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a specific tag of an organization by its name (replace it with the new one provided in the request body)
 * @summary Update Organization Tag
 */
export const updateOrganizationTag = (
    orgId: string,
    tagName: string,
    tag: Tag,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/tags/${tagName}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
      options);
    }
  


export const getUpdateOrganizationTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationTag>>, TError,{orgId: string;tagName: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationTag>>, TError,{orgId: string;tagName: string;data: Tag}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganizationTag>>, {orgId: string;tagName: string;data: Tag}> = (props) => {
          const {orgId,tagName,data} = props ?? {};

          return  updateOrganizationTag(orgId,tagName,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationTagMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganizationTag>>>
    export type UpdateOrganizationTagMutationBody = Tag
    export type UpdateOrganizationTagMutationError = HTTPValidationError

    /**
 * @summary Update Organization Tag
 */
export const useUpdateOrganizationTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationTag>>, TError,{orgId: string;tagName: string;data: Tag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateOrganizationTag>>,
        TError,
        {orgId: string;tagName: string;data: Tag},
        TContext
      > => {

      const mutationOptions = getUpdateOrganizationTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the quota status for an organization (at the moment - limiting number of projects per month)
 * @summary Get Org Quota Status
 */
export const getOrgQuotaStatus = (
    params: GetOrgQuotaStatusParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/quota/status`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrgQuotaStatusQueryKey = (params: GetOrgQuotaStatusParams,) => {
    return [`/v1/organizations/quota/status`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrgQuotaStatusQueryOptions = <TData = Awaited<ReturnType<typeof getOrgQuotaStatus>>, TError = HTTPValidationError>(params: GetOrgQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrgQuotaStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrgQuotaStatusQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrgQuotaStatus>>> = ({ signal }) => getOrgQuotaStatus(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrgQuotaStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrgQuotaStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getOrgQuotaStatus>>>
export type GetOrgQuotaStatusQueryError = HTTPValidationError

/**
 * @summary Get Org Quota Status
 */
export const useGetOrgQuotaStatus = <TData = Awaited<ReturnType<typeof getOrgQuotaStatus>>, TError = HTTPValidationError>(
 params: GetOrgQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrgQuotaStatus>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrgQuotaStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update/Create org quota
 * @summary Update Org Quota
 */
export const updateOrgQuota = (
    quota: Quota,
    params: UpdateOrgQuotaParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/quota`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: quota,
        params
    },
      options);
    }
  


export const getUpdateOrgQuotaMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrgQuota>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrgQuota>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrgQuota>>, {data: Quota;params: UpdateOrgQuotaParams}> = (props) => {
          const {data,params} = props ?? {};

          return  updateOrgQuota(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrgQuotaMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrgQuota>>>
    export type UpdateOrgQuotaMutationBody = Quota
    export type UpdateOrgQuotaMutationError = HTTPValidationError

    /**
 * @summary Update Org Quota
 */
export const useUpdateOrgQuota = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrgQuota>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateOrgQuota>>,
        TError,
        {data: Quota;params: UpdateOrgQuotaParams},
        TContext
      > => {

      const mutationOptions = getUpdateOrgQuotaMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rerun document ingestion for all documents in an organization
 * @summary Rerun Org Docs
 */
export const rerunOrgDocs = (
    params: RerunOrgDocsParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/rerun`, method: 'POST',
        params
    },
      options);
    }
  


export const getRerunOrgDocsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rerunOrgDocs>>, TError,{params: RerunOrgDocsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof rerunOrgDocs>>, TError,{params: RerunOrgDocsParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof rerunOrgDocs>>, {params: RerunOrgDocsParams}> = (props) => {
          const {params} = props ?? {};

          return  rerunOrgDocs(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RerunOrgDocsMutationResult = NonNullable<Awaited<ReturnType<typeof rerunOrgDocs>>>
    
    export type RerunOrgDocsMutationError = HTTPValidationError

    /**
 * @summary Rerun Org Docs
 */
export const useRerunOrgDocs = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rerunOrgDocs>>, TError,{params: RerunOrgDocsParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof rerunOrgDocs>>,
        TError,
        {params: RerunOrgDocsParams},
        TContext
      > => {

      const mutationOptions = getRerunOrgDocsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all checklists of an organization
 * @summary Get Organization Checklists Org Id
 */
export const getOrganizationChecklistsOrgId = (
    orgId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Checklist[]>(
      {url: `/v1/organizations/${orgId}/checklists/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationChecklistsOrgIdQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/checklists/`] as const;
    }

    
export const getGetOrganizationChecklistsOrgIdQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationChecklistsOrgIdQueryKey(orgId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>> = ({ signal }) => getOrganizationChecklistsOrgId(orgId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationChecklistsOrgIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>>
export type GetOrganizationChecklistsOrgIdQueryError = HTTPValidationError

/**
 * @summary Get Organization Checklists Org Id
 */
export const useGetOrganizationChecklistsOrgId = <TData = Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklistsOrgId>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationChecklistsOrgIdQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new checklist in an organization - only if it does not exist already (unique by id)
 * @summary Create Organization Checklist
 */
export const createOrganizationChecklist = (
    orgId: string,
    checklist: Checklist,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Checklist>(
      {url: `/v1/organizations/${orgId}/checklists/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checklist
    },
      options);
    }
  


export const getCreateOrganizationChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationChecklist>>, TError,{orgId: string;data: Checklist}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganizationChecklist>>, TError,{orgId: string;data: Checklist}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganizationChecklist>>, {orgId: string;data: Checklist}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationChecklist(orgId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationChecklistMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganizationChecklist>>>
    export type CreateOrganizationChecklistMutationBody = Checklist
    export type CreateOrganizationChecklistMutationError = HTTPValidationError

    /**
 * @summary Create Organization Checklist
 */
export const useCreateOrganizationChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationChecklist>>, TError,{orgId: string;data: Checklist}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrganizationChecklist>>,
        TError,
        {orgId: string;data: Checklist},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a specific checklist of an organization by id
 * @summary Get Organization Checklists
 */
export const getOrganizationChecklists = (
    orgId: string,
    checklistId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Checklist>(
      {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationChecklistsQueryKey = (orgId: string,
    checklistId: string,) => {
    return [`/v1/organizations/${orgId}/checklists/id/${checklistId}`] as const;
    }

    
export const getGetOrganizationChecklistsQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationChecklists>>, TError = HTTPValidationError>(orgId: string,
    checklistId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklists>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationChecklistsQueryKey(orgId,checklistId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationChecklists>>> = ({ signal }) => getOrganizationChecklists(orgId,checklistId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && checklistId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklists>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationChecklistsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationChecklists>>>
export type GetOrganizationChecklistsQueryError = HTTPValidationError

/**
 * @summary Get Organization Checklists
 */
export const useGetOrganizationChecklists = <TData = Awaited<ReturnType<typeof getOrganizationChecklists>>, TError = HTTPValidationError>(
 orgId: string,
    checklistId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationChecklists>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationChecklistsQueryOptions(orgId,checklistId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a specific checklist of an organization by id (replace it with the new one provided in the request body)
 * @summary Update Organization Checklists
 */
export const updateOrganizationChecklists = (
    orgId: string,
    checklistId: string,
    checklist: Checklist,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Checklist>(
      {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: checklist
    },
      options);
    }
  


export const getUpdateOrganizationChecklistsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationChecklists>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationChecklists>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganizationChecklists>>, {orgId: string;checklistId: string;data: Checklist}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  updateOrganizationChecklists(orgId,checklistId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationChecklistsMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganizationChecklists>>>
    export type UpdateOrganizationChecklistsMutationBody = Checklist
    export type UpdateOrganizationChecklistsMutationError = HTTPValidationError

    /**
 * @summary Update Organization Checklists
 */
export const useUpdateOrganizationChecklists = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationChecklists>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateOrganizationChecklists>>,
        TError,
        {orgId: string;checklistId: string;data: Checklist},
        TContext
      > => {

      const mutationOptions = getUpdateOrganizationChecklistsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a specific checklist of an organization by its id
 * @summary Delete Organization Checklists
 */
export const deleteOrganizationChecklists = (
    orgId: string,
    checklistId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOrganizationChecklistsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklists>>, TError,{orgId: string;checklistId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklists>>, TError,{orgId: string;checklistId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganizationChecklists>>, {orgId: string;checklistId: string}> = (props) => {
          const {orgId,checklistId} = props ?? {};

          return  deleteOrganizationChecklists(orgId,checklistId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationChecklistsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganizationChecklists>>>
    
    export type DeleteOrganizationChecklistsMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Checklists
 */
export const useDeleteOrganizationChecklists = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklists>>, TError,{orgId: string;checklistId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOrganizationChecklists>>,
        TError,
        {orgId: string;checklistId: string},
        TContext
      > => {

      const mutationOptions = getDeleteOrganizationChecklistsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a question to a specific checklist of an organization by id (only if it does not exist already in the checklist, unique by question str)
 * @summary Post Organization Checklists Question
 */
export const postOrganizationChecklistsQuestion = (
    orgId: string,
    checklistId: string,
    queryQuestion: QueryQuestion,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}/question`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
      options);
    }
  


export const getPostOrganizationChecklistsQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>, {orgId: string;checklistId: string;data: QueryQuestion}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  postOrganizationChecklistsQuestion(orgId,checklistId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOrganizationChecklistsQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>>
    export type PostOrganizationChecklistsQuestionMutationBody = QueryQuestion
    export type PostOrganizationChecklistsQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Organization Checklists Question
 */
export const usePostOrganizationChecklistsQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postOrganizationChecklistsQuestion>>,
        TError,
        {orgId: string;checklistId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = getPostOrganizationChecklistsQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a question from a specific checklist of an organization - by question str
 * @summary Delete Organization Checklists By Id Org Id Checklist Id Question
 */
export const deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion = (
    orgId: string,
    checklistId: string,
    queryQuestion: QueryQuestion,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}/question`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
      options);
    }
  


export const getDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>, {orgId: string;checklistId: string;data: QueryQuestion}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion(orgId,checklistId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>>
    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationBody = QueryQuestion
    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Checklists By Id Org Id Checklist Id Question
 */
export const useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion>>,
        TError,
        {orgId: string;checklistId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = getDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a question to the default checklist of a user
 * @summary Post Organization Checklists Org Id Default Checklist
 */
export const postOrganizationChecklistsOrgIdDefaultChecklist = (
    orgId: string,
    queryQuestion: QueryQuestion,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/checklists/add_to_default`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
      options);
    }
  


export const getPostOrganizationChecklistsOrgIdDefaultChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>, TError,{orgId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>, TError,{orgId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>, {orgId: string;data: QueryQuestion}> = (props) => {
          const {orgId,data} = props ?? {};

          return  postOrganizationChecklistsOrgIdDefaultChecklist(orgId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationResult = NonNullable<Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>>
    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationBody = QueryQuestion
    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationError = HTTPValidationError

    /**
 * @summary Post Organization Checklists Org Id Default Checklist
 */
export const usePostOrganizationChecklistsOrgIdDefaultChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>, TError,{orgId: string;data: QueryQuestion}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof postOrganizationChecklistsOrgIdDefaultChecklist>>,
        TError,
        {orgId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = getPostOrganizationChecklistsOrgIdDefaultChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Upload a checklist from a csv file
 * @summary Upload Checklist
 */
export const uploadChecklist = (
    orgId: string,
    force: boolean,
    bodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost,
 options?: SecondParameter<typeof customAxios>,) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost.file)

      return customAxios<unknown>(
      {url: `/v1/organizations/${orgId}/checklists/upload/${force}`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadChecklist>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadChecklist>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadChecklist>>, {orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}> = (props) => {
          const {orgId,force,data} = props ?? {};

          return  uploadChecklist(orgId,force,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadChecklistMutationResult = NonNullable<Awaited<ReturnType<typeof uploadChecklist>>>
    export type UploadChecklistMutationBody = BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost
    export type UploadChecklistMutationError = HTTPValidationError

    /**
 * @summary Upload Checklist
 */
export const useUploadChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadChecklist>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadChecklist>>,
        TError,
        {orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost},
        TContext
      > => {

      const mutationOptions = getUploadChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all memos of an organization
 * @summary Get Organization Memos
 */
export const getOrganizationMemos = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<MemoMeta[]>(
      {url: `/v1/organizations/${slug}/memos/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationMemosQueryKey = (slug: string,) => {
    return [`/v1/organizations/${slug}/memos/`] as const;
    }

    
export const getGetOrganizationMemosQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationMemos>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemos>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMemosQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationMemos>>> = ({ signal }) => getOrganizationMemos(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemos>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMemosQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationMemos>>>
export type GetOrganizationMemosQueryError = HTTPValidationError

/**
 * @summary Get Organization Memos
 */
export const useGetOrganizationMemos = <TData = Awaited<ReturnType<typeof getOrganizationMemos>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemos>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationMemosQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a memo for the organization
 * @summary Create Organization Memo
 */
export const createOrganizationMemo = (
    slug: string,
    memoCreate: MemoCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Memo>(
      {url: `/v1/organizations/${slug}/memos/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: memoCreate
    },
      options);
    }
  


export const getCreateOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationMemo>>, TError,{slug: string;data: MemoCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganizationMemo>>, TError,{slug: string;data: MemoCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganizationMemo>>, {slug: string;data: MemoCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  createOrganizationMemo(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganizationMemo>>>
    export type CreateOrganizationMemoMutationBody = MemoCreate
    export type CreateOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Create Organization Memo
 */
export const useCreateOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationMemo>>, TError,{slug: string;data: MemoCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrganizationMemo>>,
        TError,
        {slug: string;data: MemoCreate},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a memo of an organization
 * @summary Get Organization Memo
 */
export const getOrganizationMemo = (
    slug: string,
    memoId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Memo>(
      {url: `/v1/organizations/${slug}/memos/${memoId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationMemoQueryKey = (slug: string,
    memoId: string,) => {
    return [`/v1/organizations/${slug}/memos/${memoId}`] as const;
    }

    
export const getGetOrganizationMemoQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationMemo>>, TError = HTTPValidationError>(slug: string,
    memoId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemo>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMemoQueryKey(slug,memoId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationMemo>>> = ({ signal }) => getOrganizationMemo(slug,memoId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && memoId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMemoQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationMemo>>>
export type GetOrganizationMemoQueryError = HTTPValidationError

/**
 * @summary Get Organization Memo
 */
export const useGetOrganizationMemo = <TData = Awaited<ReturnType<typeof getOrganizationMemo>>, TError = HTTPValidationError>(
 slug: string,
    memoId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemo>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationMemoQueryOptions(slug,memoId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a memo for the organization
 * @summary Update Organization Memo
 */
export const updateOrganizationMemo = (
    slug: string,
    memoId: string,
    memoUpdate: MemoUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Memo>(
      {url: `/v1/organizations/${slug}/memos/${memoId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: memoUpdate
    },
      options);
    }
  


export const getUpdateOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemo>>, TError,{slug: string;memoId: string;data: MemoUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemo>>, TError,{slug: string;memoId: string;data: MemoUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganizationMemo>>, {slug: string;memoId: string;data: MemoUpdate}> = (props) => {
          const {slug,memoId,data} = props ?? {};

          return  updateOrganizationMemo(slug,memoId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganizationMemo>>>
    export type UpdateOrganizationMemoMutationBody = MemoUpdate
    export type UpdateOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Update Organization Memo
 */
export const useUpdateOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemo>>, TError,{slug: string;memoId: string;data: MemoUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateOrganizationMemo>>,
        TError,
        {slug: string;memoId: string;data: MemoUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a memo from the organization
 * @summary Delete Organization Memo
 */
export const deleteOrganizationMemo = (
    slug: string,
    memoId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<MemoMeta[]>(
      {url: `/v1/organizations/${slug}/memos/${memoId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationMemo>>, TError,{slug: string;memoId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationMemo>>, TError,{slug: string;memoId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganizationMemo>>, {slug: string;memoId: string}> = (props) => {
          const {slug,memoId} = props ?? {};

          return  deleteOrganizationMemo(slug,memoId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganizationMemo>>>
    
    export type DeleteOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Memo
 */
export const useDeleteOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganizationMemo>>, TError,{slug: string;memoId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOrganizationMemo>>,
        TError,
        {slug: string;memoId: string},
        TContext
      > => {

      const mutationOptions = getDeleteOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chat With Knowledge Base New Question
 */
export const chatWithKnowledgeBaseNewQuestion = (
    slug: string,
    bodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Thread>(
      {url: `/v1/organizations/${slug}/knowledge-base/chat/new_question`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost
    },
      options);
    }
  


export const getChatWithKnowledgeBaseNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>, TError,{slug: string;data: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>, TError,{slug: string;data: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>, {slug: string;data: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost}> = (props) => {
          const {slug,data} = props ?? {};

          return  chatWithKnowledgeBaseNewQuestion(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChatWithKnowledgeBaseNewQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>>
    export type ChatWithKnowledgeBaseNewQuestionMutationBody = BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost
    export type ChatWithKnowledgeBaseNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Chat With Knowledge Base New Question
 */
export const useChatWithKnowledgeBaseNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>, TError,{slug: string;data: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof chatWithKnowledgeBaseNewQuestion>>,
        TError,
        {slug: string;data: BodyChatWithKnowledgeBaseNewQuestionV1OrganizationsSlugKnowledgeBaseChatNewQuestionPost},
        TContext
      > => {

      const mutationOptions = getChatWithKnowledgeBaseNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Thread By Id
 */
export const getThreadById = (
    slug: string,
    threadId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Thread>(
      {url: `/v1/organizations/${slug}/knowledge-base/chat/threads/${threadId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetThreadByIdQueryKey = (slug: string,
    threadId: string,) => {
    return [`/v1/organizations/${slug}/knowledge-base/chat/threads/${threadId}`] as const;
    }

    
export const getGetThreadByIdQueryOptions = <TData = Awaited<ReturnType<typeof getThreadById>>, TError = HTTPValidationError>(slug: string,
    threadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetThreadByIdQueryKey(slug,threadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getThreadById>>> = ({ signal }) => getThreadById(slug,threadId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && threadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getThreadById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetThreadByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getThreadById>>>
export type GetThreadByIdQueryError = HTTPValidationError

/**
 * @summary Get Thread By Id
 */
export const useGetThreadById = <TData = Awaited<ReturnType<typeof getThreadById>>, TError = HTTPValidationError>(
 slug: string,
    threadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getThreadById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetThreadByIdQueryOptions(slug,threadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Thread
 */
export const deleteThread = (
    slug: string,
    threadId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ThreadMetadata[]>(
      {url: `/v1/organizations/${slug}/knowledge-base/chat/threads/${threadId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteThreadMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteThread>>, TError,{slug: string;threadId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteThread>>, TError,{slug: string;threadId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteThread>>, {slug: string;threadId: string}> = (props) => {
          const {slug,threadId} = props ?? {};

          return  deleteThread(slug,threadId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteThreadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteThread>>>
    
    export type DeleteThreadMutationError = HTTPValidationError

    /**
 * @summary Delete Thread
 */
export const useDeleteThread = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteThread>>, TError,{slug: string;threadId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteThread>>,
        TError,
        {slug: string;threadId: string},
        TContext
      > => {

      const mutationOptions = getDeleteThreadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Threads
 */
export const getAllThreads = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<ThreadMetadata[]>(
      {url: `/v1/organizations/${slug}/knowledge-base/chat/threads`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllThreadsQueryKey = (slug: string,) => {
    return [`/v1/organizations/${slug}/knowledge-base/chat/threads`] as const;
    }

    
export const getGetAllThreadsQueryOptions = <TData = Awaited<ReturnType<typeof getAllThreads>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllThreads>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllThreadsQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllThreads>>> = ({ signal }) => getAllThreads(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllThreads>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllThreadsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllThreads>>>
export type GetAllThreadsQueryError = HTTPValidationError

/**
 * @summary Get All Threads
 */
export const useGetAllThreads = <TData = Awaited<ReturnType<typeof getAllThreads>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllThreads>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllThreadsQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Latest Thread
 */
export const getLatestThread = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Thread>(
      {url: `/v1/organizations/${slug}/knowledge-base/chat/latest_thread`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLatestThreadQueryKey = (slug: string,) => {
    return [`/v1/organizations/${slug}/knowledge-base/chat/latest_thread`] as const;
    }

    
export const getGetLatestThreadQueryOptions = <TData = Awaited<ReturnType<typeof getLatestThread>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLatestThreadQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestThread>>> = ({ signal }) => getLatestThread(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLatestThread>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLatestThreadQueryResult = NonNullable<Awaited<ReturnType<typeof getLatestThread>>>
export type GetLatestThreadQueryError = HTTPValidationError

/**
 * @summary Get Latest Thread
 */
export const useGetLatestThread = <TData = Awaited<ReturnType<typeof getLatestThread>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLatestThread>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLatestThreadQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Project
 */
export const deleteProject = (
    params: DeleteProjectParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/danger_zone/delete_project`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{params: DeleteProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{params: DeleteProjectParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProject>>, {params: DeleteProjectParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteProject(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProject>>>
    
    export type DeleteProjectMutationError = HTTPValidationError

    /**
 * @summary Delete Project
 */
export const useDeleteProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{params: DeleteProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProject>>,
        TError,
        {params: DeleteProjectParams},
        TContext
      > => {

      const mutationOptions = getDeleteProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete All Projects From User
 */
export const deleteAllProjectsFromUser = (
    params: DeleteAllProjectsFromUserParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/danger_zone/delete_all_projects_from_user`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteAllProjectsFromUserMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAllProjectsFromUser>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAllProjectsFromUser>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAllProjectsFromUser>>, {params: DeleteAllProjectsFromUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAllProjectsFromUser(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAllProjectsFromUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAllProjectsFromUser>>>
    
    export type DeleteAllProjectsFromUserMutationError = HTTPValidationError

    /**
 * @summary Delete All Projects From User
 */
export const useDeleteAllProjectsFromUser = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAllProjectsFromUser>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAllProjectsFromUser>>,
        TError,
        {params: DeleteAllProjectsFromUserParams},
        TContext
      > => {

      const mutationOptions = getDeleteAllProjectsFromUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Document
 */
export const deleteDocument = (
    params: DeleteDocumentParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/danger_zone/delete_document`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocument>>, TError,{params: DeleteDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDocument>>, TError,{params: DeleteDocumentParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDocument>>, {params: DeleteDocumentParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteDocument(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDocument>>>
    
    export type DeleteDocumentMutationError = HTTPValidationError

    /**
 * @summary Delete Document
 */
export const useDeleteDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocument>>, TError,{params: DeleteDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDocument>>,
        TError,
        {params: DeleteDocumentParams},
        TContext
      > => {

      const mutationOptions = getDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Document From Vectorstore
 */
export const deleteDocumentFromVectorstore = (
    params: DeleteDocumentFromVectorstoreParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/danger_zone/delete_document_from_vectorstore`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteDocumentFromVectorstoreMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>, {params: DeleteDocumentFromVectorstoreParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteDocumentFromVectorstore(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentFromVectorstoreMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>>
    
    export type DeleteDocumentFromVectorstoreMutationError = HTTPValidationError

    /**
 * @summary Delete Document From Vectorstore
 */
export const useDeleteDocumentFromVectorstore = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDocumentFromVectorstore>>,
        TError,
        {params: DeleteDocumentFromVectorstoreParams},
        TContext
      > => {

      const mutationOptions = getDeleteDocumentFromVectorstoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Clean Deleted Documents From Project
 */
export const cleanDeletedDocumentsFromProject = (
    params: CleanDeletedDocumentsFromProjectParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/danger_zone/clean_deleted_documents_from_project`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getCleanDeletedDocumentsFromProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>, {params: CleanDeletedDocumentsFromProjectParams}> = (props) => {
          const {params} = props ?? {};

          return  cleanDeletedDocumentsFromProject(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CleanDeletedDocumentsFromProjectMutationResult = NonNullable<Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>>
    
    export type CleanDeletedDocumentsFromProjectMutationError = HTTPValidationError

    /**
 * @summary Clean Deleted Documents From Project
 */
export const useCleanDeletedDocumentsFromProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof cleanDeletedDocumentsFromProject>>,
        TError,
        {params: CleanDeletedDocumentsFromProjectParams},
        TContext
      > => {

      const mutationOptions = getCleanDeletedDocumentsFromProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rect To Round
 */
export const rectToRound = (
    params: RectToRoundParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<number>(
      {url: `/v1/calculator/hvac/ductulator/rect_to_round`, method: 'POST',
        params
    },
      options);
    }
  


export const getRectToRoundMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rectToRound>>, TError,{params: RectToRoundParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof rectToRound>>, TError,{params: RectToRoundParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof rectToRound>>, {params: RectToRoundParams}> = (props) => {
          const {params} = props ?? {};

          return  rectToRound(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RectToRoundMutationResult = NonNullable<Awaited<ReturnType<typeof rectToRound>>>
    
    export type RectToRoundMutationError = HTTPValidationError

    /**
 * @summary Rect To Round
 */
export const useRectToRound = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rectToRound>>, TError,{params: RectToRoundParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof rectToRound>>,
        TError,
        {params: RectToRoundParams},
        TContext
      > => {

      const mutationOptions = getRectToRoundMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Round To Rect
 */
export const roundToRect = (
    params: RoundToRectParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown[][]>(
      {url: `/v1/calculator/hvac/ductulator/round_to_rect`, method: 'POST',
        params
    },
      options);
    }
  


export const getRoundToRectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roundToRect>>, TError,{params: RoundToRectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof roundToRect>>, TError,{params: RoundToRectParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof roundToRect>>, {params: RoundToRectParams}> = (props) => {
          const {params} = props ?? {};

          return  roundToRect(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RoundToRectMutationResult = NonNullable<Awaited<ReturnType<typeof roundToRect>>>
    
    export type RoundToRectMutationError = HTTPValidationError

    /**
 * @summary Round To Rect
 */
export const useRoundToRect = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof roundToRect>>, TError,{params: RoundToRectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof roundToRect>>,
        TError,
        {params: RoundToRectParams},
        TContext
      > => {

      const mutationOptions = getRoundToRectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get follow up questions for a query according to the project (optional) and source of original query
 * @summary Get Follow Up Questions
 */
export const getFollowUpQuestions = (
    state: State,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Question[]>(
      {url: `/v1/suggestions/questions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: state
    },
      options);
    }
  


export const getGetFollowUpQuestionsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFollowUpQuestions>>, TError,{data: State}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof getFollowUpQuestions>>, TError,{data: State}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getFollowUpQuestions>>, {data: State}> = (props) => {
          const {data} = props ?? {};

          return  getFollowUpQuestions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetFollowUpQuestionsMutationResult = NonNullable<Awaited<ReturnType<typeof getFollowUpQuestions>>>
    export type GetFollowUpQuestionsMutationBody = State
    export type GetFollowUpQuestionsMutationError = HTTPValidationError

    /**
 * @summary Get Follow Up Questions
 */
export const useGetFollowUpQuestions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getFollowUpQuestions>>, TError,{data: State}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof getFollowUpQuestions>>,
        TError,
        {data: State},
        TContext
      > => {

      const mutationOptions = getGetFollowUpQuestionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get suggested actions for the new homepage (e.g. "Start a new page", "continue takeoff", etc.)
 * @summary Get Homepage Actions
 */
export const getHomepageActions = (
    params: GetHomepageActionsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<SuggestedActionResponse[]>(
      {url: `/v1/suggestions/homepage/actions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetHomepageActionsQueryKey = (params: GetHomepageActionsParams,) => {
    return [`/v1/suggestions/homepage/actions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHomepageActionsQueryOptions = <TData = Awaited<ReturnType<typeof getHomepageActions>>, TError = HTTPValidationError>(params: GetHomepageActionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHomepageActions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHomepageActionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHomepageActions>>> = ({ signal }) => getHomepageActions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHomepageActions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHomepageActionsQueryResult = NonNullable<Awaited<ReturnType<typeof getHomepageActions>>>
export type GetHomepageActionsQueryError = HTTPValidationError

/**
 * @summary Get Homepage Actions
 */
export const useGetHomepageActions = <TData = Awaited<ReturnType<typeof getHomepageActions>>, TError = HTTPValidationError>(
 params: GetHomepageActionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHomepageActions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHomepageActionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get user's favorite suggested actions
 * @summary Get Suggested Actions Favorites
 */
export const getSuggestedActionsFavorites = (
    params?: GetSuggestedActionsFavoritesParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<SuggestedActionFavorite[]>(
      {url: `/v1/suggestions/homepage/favorites`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSuggestedActionsFavoritesQueryKey = (params?: GetSuggestedActionsFavoritesParams,) => {
    return [`/v1/suggestions/homepage/favorites`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSuggestedActionsFavoritesQueryOptions = <TData = Awaited<ReturnType<typeof getSuggestedActionsFavorites>>, TError = HTTPValidationError>(params?: GetSuggestedActionsFavoritesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSuggestedActionsFavorites>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSuggestedActionsFavoritesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSuggestedActionsFavorites>>> = ({ signal }) => getSuggestedActionsFavorites(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSuggestedActionsFavorites>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSuggestedActionsFavoritesQueryResult = NonNullable<Awaited<ReturnType<typeof getSuggestedActionsFavorites>>>
export type GetSuggestedActionsFavoritesQueryError = HTTPValidationError

/**
 * @summary Get Suggested Actions Favorites
 */
export const useGetSuggestedActionsFavorites = <TData = Awaited<ReturnType<typeof getSuggestedActionsFavorites>>, TError = HTTPValidationError>(
 params?: GetSuggestedActionsFavoritesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSuggestedActionsFavorites>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSuggestedActionsFavoritesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add a suggested action to user favorites
 * @summary Add Suggested Action To Favorites
 */
export const addSuggestedActionToFavorites = (
    suggestedActionFavorite: SuggestedActionFavorite,
    params?: AddSuggestedActionToFavoritesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<SuggestedActionFavorite>(
      {url: `/v1/suggestions/homepage/add_to_favorites`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
      options);
    }
  


export const getAddSuggestedActionToFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSuggestedActionToFavorites>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addSuggestedActionToFavorites>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSuggestedActionToFavorites>>, {data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addSuggestedActionToFavorites(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddSuggestedActionToFavoritesMutationResult = NonNullable<Awaited<ReturnType<typeof addSuggestedActionToFavorites>>>
    export type AddSuggestedActionToFavoritesMutationBody = SuggestedActionFavorite
    export type AddSuggestedActionToFavoritesMutationError = HTTPValidationError

    /**
 * @summary Add Suggested Action To Favorites
 */
export const useAddSuggestedActionToFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSuggestedActionToFavorites>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof addSuggestedActionToFavorites>>,
        TError,
        {data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams},
        TContext
      > => {

      const mutationOptions = getAddSuggestedActionToFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Edit a suggested action in user favorites
 * @summary Edit Suggested Action Favorite
 */
export const editSuggestedActionFavorite = (
    suggestedActionFavorite: SuggestedActionFavorite,
    params?: EditSuggestedActionFavoriteParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<SuggestedActionFavorite>(
      {url: `/v1/suggestions/homepage/edit_favorite`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
      options);
    }
  


export const getEditSuggestedActionFavoriteMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editSuggestedActionFavorite>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof editSuggestedActionFavorite>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editSuggestedActionFavorite>>, {data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}> = (props) => {
          const {data,params} = props ?? {};

          return  editSuggestedActionFavorite(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditSuggestedActionFavoriteMutationResult = NonNullable<Awaited<ReturnType<typeof editSuggestedActionFavorite>>>
    export type EditSuggestedActionFavoriteMutationBody = SuggestedActionFavorite
    export type EditSuggestedActionFavoriteMutationError = HTTPValidationError

    /**
 * @summary Edit Suggested Action Favorite
 */
export const useEditSuggestedActionFavorite = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editSuggestedActionFavorite>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof editSuggestedActionFavorite>>,
        TError,
        {data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams},
        TContext
      > => {

      const mutationOptions = getEditSuggestedActionFavoriteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Set user's favorite suggested actions
 * @summary Set Suggested Actions Favorites
 */
export const setSuggestedActionsFavorites = (
    suggestedActionFavorite: SuggestedActionFavorite[],
    params?: SetSuggestedActionsFavoritesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<SuggestedActionFavorite[]>(
      {url: `/v1/suggestions/homepage/set_favorites`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
      options);
    }
  


export const getSetSuggestedActionsFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setSuggestedActionsFavorites>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof setSuggestedActionsFavorites>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setSuggestedActionsFavorites>>, {data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  setSuggestedActionsFavorites(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetSuggestedActionsFavoritesMutationResult = NonNullable<Awaited<ReturnType<typeof setSuggestedActionsFavorites>>>
    export type SetSuggestedActionsFavoritesMutationBody = SuggestedActionFavorite[]
    export type SetSuggestedActionsFavoritesMutationError = HTTPValidationError

    /**
 * @summary Set Suggested Actions Favorites
 */
export const useSetSuggestedActionsFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setSuggestedActionsFavorites>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof setSuggestedActionsFavorites>>,
        TError,
        {data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams},
        TContext
      > => {

      const mutationOptions = getSetSuggestedActionsFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a suggested action from user favorites
 * @summary Remove Suggested Action From Favorites
 */
export const removeSuggestedActionFromFavorites = (
    params: RemoveSuggestedActionFromFavoritesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/suggestions/homepage/remove_from_favorites`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getRemoveSuggestedActionFromFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>, {params: RemoveSuggestedActionFromFavoritesParams}> = (props) => {
          const {params} = props ?? {};

          return  removeSuggestedActionFromFavorites(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveSuggestedActionFromFavoritesMutationResult = NonNullable<Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>>
    
    export type RemoveSuggestedActionFromFavoritesMutationError = HTTPValidationError

    /**
 * @summary Remove Suggested Action From Favorites
 */
export const useRemoveSuggestedActionFromFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof removeSuggestedActionFromFavorites>>,
        TError,
        {params: RemoveSuggestedActionFromFavoritesParams},
        TContext
      > => {

      const mutationOptions = getRemoveSuggestedActionFromFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tools With Docstrings
 */
export const getToolsWithDocstrings = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<ToolsListObject[]>(
      {url: `/v1/suggestions/tools`, method: 'GET', signal
    },
      options);
    }
  

export const getGetToolsWithDocstringsQueryKey = () => {
    return [`/v1/suggestions/tools`] as const;
    }

    
export const getGetToolsWithDocstringsQueryOptions = <TData = Awaited<ReturnType<typeof getToolsWithDocstrings>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getToolsWithDocstrings>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetToolsWithDocstringsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getToolsWithDocstrings>>> = ({ signal }) => getToolsWithDocstrings(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getToolsWithDocstrings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetToolsWithDocstringsQueryResult = NonNullable<Awaited<ReturnType<typeof getToolsWithDocstrings>>>
export type GetToolsWithDocstringsQueryError = unknown

/**
 * @summary Get Tools With Docstrings
 */
export const useGetToolsWithDocstrings = <TData = Awaited<ReturnType<typeof getToolsWithDocstrings>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getToolsWithDocstrings>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetToolsWithDocstringsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Compare Questions Suggestions
 */
export const getCompareQuestionsSuggestions = (
    params?: GetCompareQuestionsSuggestionsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<string[]>(
      {url: `/v1/suggestions/compare_questions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCompareQuestionsSuggestionsQueryKey = (params?: GetCompareQuestionsSuggestionsParams,) => {
    return [`/v1/suggestions/compare_questions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCompareQuestionsSuggestionsQueryOptions = <TData = Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>, TError = HTTPValidationError>(params?: GetCompareQuestionsSuggestionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompareQuestionsSuggestionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>> = ({ signal }) => getCompareQuestionsSuggestions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCompareQuestionsSuggestionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>>
export type GetCompareQuestionsSuggestionsQueryError = HTTPValidationError

/**
 * @summary Get Compare Questions Suggestions
 */
export const useGetCompareQuestionsSuggestions = <TData = Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>, TError = HTTPValidationError>(
 params?: GetCompareQuestionsSuggestionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompareQuestionsSuggestions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCompareQuestionsSuggestionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get all events in project withing the specified date range
 * @summary Get Project Events
 */
export const getProjectEvents = (
    params: GetProjectEventsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EventRecord[]>(
      {url: `/v1/activity_log/get_project_events`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetProjectEventsQueryKey = (params: GetProjectEventsParams,) => {
    return [`/v1/activity_log/get_project_events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectEventsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectEvents>>, TError = HTTPValidationError>(params: GetProjectEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectEventsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectEvents>>> = ({ signal }) => getProjectEvents(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectEvents>>>
export type GetProjectEventsQueryError = HTTPValidationError

/**
 * @summary Get Project Events
 */
export const useGetProjectEvents = <TData = Awaited<ReturnType<typeof getProjectEvents>>, TError = HTTPValidationError>(
 params: GetProjectEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectEventsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get all events for user within the specified date range
 * @summary Get User Events
 */
export const getUserEvents = (
    params: GetUserEventsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EventRecord[]>(
      {url: `/v1/activity_log/get_user_events`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserEventsQueryKey = (params: GetUserEventsParams,) => {
    return [`/v1/activity_log/get_user_events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserEventsQueryOptions = <TData = Awaited<ReturnType<typeof getUserEvents>>, TError = HTTPValidationError>(params: GetUserEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserEventsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEvents>>> = ({ signal }) => getUserEvents(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEvents>>>
export type GetUserEventsQueryError = HTTPValidationError

/**
 * @summary Get User Events
 */
export const useGetUserEvents = <TData = Awaited<ReturnType<typeof getUserEvents>>, TError = HTTPValidationError>(
 params: GetUserEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserEvents>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserEventsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recently active projects and documents based on activity log events
 * @summary Get Recent Items
 */
export const getRecentItems = (
    params?: GetRecentItemsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<MainPageAction[]>(
      {url: `/v1/activity_log/get_recent_items`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRecentItemsQueryKey = (params?: GetRecentItemsParams,) => {
    return [`/v1/activity_log/get_recent_items`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRecentItemsQueryOptions = <TData = Awaited<ReturnType<typeof getRecentItems>>, TError = HTTPValidationError>(params?: GetRecentItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentItems>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecentItemsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentItems>>> = ({ signal }) => getRecentItems(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecentItems>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRecentItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentItems>>>
export type GetRecentItemsQueryError = HTTPValidationError

/**
 * @summary Get Recent Items
 */
export const useGetRecentItems = <TData = Awaited<ReturnType<typeof getRecentItems>>, TError = HTTPValidationError>(
 params?: GetRecentItemsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentItems>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRecentItemsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get equipment items from organization catalog by equipment_id
 * @summary Get Organization Equipment
 */
export const getOrganizationEquipment = (
    orgId: string,
    equipmentId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EquipmentPiecePrice>(
      {url: `/v1/organizations/${orgId}/catalog/equipment/${equipmentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationEquipmentQueryKey = (orgId: string,
    equipmentId: string,) => {
    return [`/v1/organizations/${orgId}/catalog/equipment/${equipmentId}`] as const;
    }

    
export const getGetOrganizationEquipmentQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationEquipment>>, TError = HTTPValidationError>(orgId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipment>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationEquipmentQueryKey(orgId,equipmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationEquipment>>> = ({ signal }) => getOrganizationEquipment(orgId,equipmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && equipmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipment>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationEquipmentQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationEquipment>>>
export type GetOrganizationEquipmentQueryError = HTTPValidationError

/**
 * @summary Get Organization Equipment
 */
export const useGetOrganizationEquipment = <TData = Awaited<ReturnType<typeof getOrganizationEquipment>>, TError = HTTPValidationError>(
 orgId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipment>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationEquipmentQueryOptions(orgId,equipmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get equipment item description from organization catalog by unit type and description
 * @summary Get Organization Equipment Description
 */
export const getOrganizationEquipmentDescription = (
    orgId: string,
    params: GetOrganizationEquipmentDescriptionParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<EquipmentDescriptionSearch[]>(
      {url: `/v1/organizations/${orgId}/catalog/equipment-description/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrganizationEquipmentDescriptionQueryKey = (orgId: string,
    params: GetOrganizationEquipmentDescriptionParams,) => {
    return [`/v1/organizations/${orgId}/catalog/equipment-description/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrganizationEquipmentDescriptionQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>, TError = HTTPValidationError>(orgId: string,
    params: GetOrganizationEquipmentDescriptionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationEquipmentDescriptionQueryKey(orgId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>> = ({ signal }) => getOrganizationEquipmentDescription(orgId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationEquipmentDescriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>>
export type GetOrganizationEquipmentDescriptionQueryError = HTTPValidationError

/**
 * @summary Get Organization Equipment Description
 */
export const useGetOrganizationEquipmentDescription = <TData = Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>, TError = HTTPValidationError>(
 orgId: string,
    params: GetOrganizationEquipmentDescriptionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationEquipmentDescription>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganizationEquipmentDescriptionQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new equipment item in an organization - only if it does not exist already (unique by equipment_id and description)
 * @summary Create Organization Equipment
 */
export const createOrganizationEquipment = (
    orgId: string,
    equipmentPiecePriceCreate: EquipmentPiecePriceCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<CatalogItemResponse>(
      {url: `/v1/organizations/${orgId}/catalog/equipment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: equipmentPiecePriceCreate
    },
      options);
    }
  


export const getCreateOrganizationEquipmentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationEquipment>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganizationEquipment>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganizationEquipment>>, {orgId: string;data: EquipmentPiecePriceCreate}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationEquipment(orgId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationEquipmentMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganizationEquipment>>>
    export type CreateOrganizationEquipmentMutationBody = EquipmentPiecePriceCreate
    export type CreateOrganizationEquipmentMutationError = HTTPValidationError

    /**
 * @summary Create Organization Equipment
 */
export const useCreateOrganizationEquipment = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganizationEquipment>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOrganizationEquipment>>,
        TError,
        {orgId: string;data: EquipmentPiecePriceCreate},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationEquipmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a project filesystem structure
 * @summary Get Filesystem
 */
export const getFilesystem = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFilesystemQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/filesystem/`] as const;
    }

    
export const getGetFilesystemQueryOptions = <TData = Awaited<ReturnType<typeof getFilesystem>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilesystem>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFilesystemQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilesystem>>> = ({ signal }) => getFilesystem(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFilesystem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFilesystemQueryResult = NonNullable<Awaited<ReturnType<typeof getFilesystem>>>
export type GetFilesystemQueryError = HTTPValidationError

/**
 * @summary Get Filesystem
 */
export const useGetFilesystem = <TData = Awaited<ReturnType<typeof getFilesystem>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilesystem>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFilesystemQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new folder
 * @summary Create Folder
 */
export const createFolder = (
    slug: string,
    folder: Folder,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Folder>(
      {url: `/v1/projects/${slug}/filesystem/create_folder`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: folder
    },
      options);
    }
  


export const getCreateFolderMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{slug: string;data: Folder}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{slug: string;data: Folder}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFolder>>, {slug: string;data: Folder}> = (props) => {
          const {slug,data} = props ?? {};

          return  createFolder(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<typeof createFolder>>>
    export type CreateFolderMutationBody = Folder
    export type CreateFolderMutationError = HTTPValidationError

    /**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFolder>>, TError,{slug: string;data: Folder}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createFolder>>,
        TError,
        {slug: string;data: Folder},
        TContext
      > => {

      const mutationOptions = getCreateFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create multiple folders
 * @summary Create Folders Batch
 */
export const createFoldersBatch = (
    slug: string,
    folder: Folder[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/create_folders_batch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: folder
    },
      options);
    }
  


export const getCreateFoldersBatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFoldersBatch>>, TError,{slug: string;data: Folder[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createFoldersBatch>>, TError,{slug: string;data: Folder[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFoldersBatch>>, {slug: string;data: Folder[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  createFoldersBatch(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFoldersBatchMutationResult = NonNullable<Awaited<ReturnType<typeof createFoldersBatch>>>
    export type CreateFoldersBatchMutationBody = Folder[]
    export type CreateFoldersBatchMutationError = HTTPValidationError

    /**
 * @summary Create Folders Batch
 */
export const useCreateFoldersBatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFoldersBatch>>, TError,{slug: string;data: Folder[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createFoldersBatch>>,
        TError,
        {slug: string;data: Folder[]},
        TContext
      > => {

      const mutationOptions = getCreateFoldersBatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a folder
 * @summary Delete Folder
 */
export const deleteFolder = (
    slug: string,
    params: DeleteFolderParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/delete_folder`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteFolderMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{slug: string;params: DeleteFolderParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{slug: string;params: DeleteFolderParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFolder>>, {slug: string;params: DeleteFolderParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  deleteFolder(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFolderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFolder>>>
    
    export type DeleteFolderMutationError = HTTPValidationError

    /**
 * @summary Delete Folder
 */
export const useDeleteFolder = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFolder>>, TError,{slug: string;params: DeleteFolderParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFolder>>,
        TError,
        {slug: string;params: DeleteFolderParams},
        TContext
      > => {

      const mutationOptions = getDeleteFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update multiple folders or files
 * @summary Update Filesystem Batch
 */
export const updateFilesystemBatch = (
    slug: string,
    updateFilesystemBatchBodyItem: UpdateFilesystemBatchBodyItem[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/batch`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateFilesystemBatchBodyItem
    },
      options);
    }
  


export const getUpdateFilesystemBatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemBatch>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemBatch>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFilesystemBatch>>, {slug: string;data: UpdateFilesystemBatchBodyItem[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  updateFilesystemBatch(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFilesystemBatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateFilesystemBatch>>>
    export type UpdateFilesystemBatchMutationBody = UpdateFilesystemBatchBodyItem[]
    export type UpdateFilesystemBatchMutationError = HTTPValidationError

    /**
 * @summary Update Filesystem Batch
 */
export const useUpdateFilesystemBatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemBatch>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateFilesystemBatch>>,
        TError,
        {slug: string;data: UpdateFilesystemBatchBodyItem[]},
        TContext
      > => {

      const mutationOptions = getUpdateFilesystemBatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete multiple files or folders
 * @summary Delete Filesystem Batch
 */
export const deleteFilesystemBatch = (
    slug: string,
    deleteFilesystemBatchBody: string[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteFilesystemBatchBody
    },
      options);
    }
  


export const getDeleteFilesystemBatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesystemBatch>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFilesystemBatch>>, TError,{slug: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFilesystemBatch>>, {slug: string;data: string[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  deleteFilesystemBatch(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFilesystemBatchMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFilesystemBatch>>>
    export type DeleteFilesystemBatchMutationBody = string[]
    export type DeleteFilesystemBatchMutationError = HTTPValidationError

    /**
 * @summary Delete Filesystem Batch
 */
export const useDeleteFilesystemBatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFilesystemBatch>>, TError,{slug: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFilesystemBatch>>,
        TError,
        {slug: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getDeleteFilesystemBatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a folder or file
 * @summary Update Filesystem Object
 */
export const updateFilesystemObject = (
    slug: string,
    objId: string,
    updateFilesystemObjectBody: UpdateFilesystemObjectBody,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/${objId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateFilesystemObjectBody
    },
      options);
    }
  


export const getUpdateFilesystemObjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemObject>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemObject>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFilesystemObject>>, {slug: string;objId: string;data: UpdateFilesystemObjectBody}> = (props) => {
          const {slug,objId,data} = props ?? {};

          return  updateFilesystemObject(slug,objId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFilesystemObjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateFilesystemObject>>>
    export type UpdateFilesystemObjectMutationBody = UpdateFilesystemObjectBody
    export type UpdateFilesystemObjectMutationError = HTTPValidationError

    /**
 * @summary Update Filesystem Object
 */
export const useUpdateFilesystemObject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFilesystemObject>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateFilesystemObject>>,
        TError,
        {slug: string;objId: string;data: UpdateFilesystemObjectBody},
        TContext
      > => {

      const mutationOptions = getUpdateFilesystemObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a file to a folder
 * @summary Add File
 */
export const addFile = (
    slug: string,
    file: File,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<File>(
      {url: `/v1/projects/${slug}/filesystem/add_file`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: file
    },
      options);
    }
  


export const getAddFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFile>>, TError,{slug: string;data: File}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addFile>>, TError,{slug: string;data: File}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addFile>>, {slug: string;data: File}> = (props) => {
          const {slug,data} = props ?? {};

          return  addFile(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddFileMutationResult = NonNullable<Awaited<ReturnType<typeof addFile>>>
    export type AddFileMutationBody = File
    export type AddFileMutationError = HTTPValidationError

    /**
 * @summary Add File
 */
export const useAddFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFile>>, TError,{slug: string;data: File}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof addFile>>,
        TError,
        {slug: string;data: File},
        TContext
      > => {

      const mutationOptions = getAddFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a file
 * @summary Delete File
 */
export const deleteFile = (
    slug: string,
    params: DeleteFileParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<FileSystem>(
      {url: `/v1/projects/${slug}/filesystem/delete_file`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFile>>, TError,{slug: string;params: DeleteFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFile>>, TError,{slug: string;params: DeleteFileParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFile>>, {slug: string;params: DeleteFileParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  deleteFile(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFileMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFile>>>
    
    export type DeleteFileMutationError = HTTPValidationError

    /**
 * @summary Delete File
 */
export const useDeleteFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFile>>, TError,{slug: string;params: DeleteFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFile>>,
        TError,
        {slug: string;params: DeleteFileParams},
        TContext
      > => {

      const mutationOptions = getDeleteFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Exact Compare
 */
export const createExactCompare = (
    slug: string,
    exactCompareCreate: ExactCompareCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ExactCompare>(
      {url: `/v1/projects/${slug}/compare/exact/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: exactCompareCreate
    },
      options);
    }
  


export const getCreateExactCompareMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExactCompare>>, TError,{slug: string;data: ExactCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createExactCompare>>, TError,{slug: string;data: ExactCompareCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExactCompare>>, {slug: string;data: ExactCompareCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  createExactCompare(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateExactCompareMutationResult = NonNullable<Awaited<ReturnType<typeof createExactCompare>>>
    export type CreateExactCompareMutationBody = ExactCompareCreate
    export type CreateExactCompareMutationError = HTTPValidationError

    /**
 * @summary Create Exact Compare
 */
export const useCreateExactCompare = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExactCompare>>, TError,{slug: string;data: ExactCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createExactCompare>>,
        TError,
        {slug: string;data: ExactCompareCreate},
        TContext
      > => {

      const mutationOptions = getCreateExactCompareMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Overlay Compare
 */
export const createOverlayCompare = (
    slug: string,
    overlayCompareCreate: OverlayCompareCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<OverlayCompare>(
      {url: `/v1/projects/${slug}/compare/overlay/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: overlayCompareCreate
    },
      options);
    }
  


export const getCreateOverlayCompareMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOverlayCompare>>, TError,{slug: string;data: OverlayCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOverlayCompare>>, TError,{slug: string;data: OverlayCompareCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOverlayCompare>>, {slug: string;data: OverlayCompareCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  createOverlayCompare(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOverlayCompareMutationResult = NonNullable<Awaited<ReturnType<typeof createOverlayCompare>>>
    export type CreateOverlayCompareMutationBody = OverlayCompareCreate
    export type CreateOverlayCompareMutationError = HTTPValidationError

    /**
 * @summary Create Overlay Compare
 */
export const useCreateOverlayCompare = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOverlayCompare>>, TError,{slug: string;data: OverlayCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createOverlayCompare>>,
        TError,
        {slug: string;data: OverlayCompareCreate},
        TContext
      > => {

      const mutationOptions = getCreateOverlayCompareMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Semantic Compare
 */
export const createSemanticCompare = (
    slug: string,
    semanticCompareCreate: SemanticCompareCreate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<SemanticCompare>(
      {url: `/v1/projects/${slug}/compare/semantic_compare/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: semanticCompareCreate
    },
      options);
    }
  


export const getCreateSemanticCompareMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSemanticCompare>>, TError,{slug: string;data: SemanticCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createSemanticCompare>>, TError,{slug: string;data: SemanticCompareCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSemanticCompare>>, {slug: string;data: SemanticCompareCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  createSemanticCompare(slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateSemanticCompareMutationResult = NonNullable<Awaited<ReturnType<typeof createSemanticCompare>>>
    export type CreateSemanticCompareMutationBody = SemanticCompareCreate
    export type CreateSemanticCompareMutationError = HTTPValidationError

    /**
 * @summary Create Semantic Compare
 */
export const useCreateSemanticCompare = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSemanticCompare>>, TError,{slug: string;data: SemanticCompareCreate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createSemanticCompare>>,
        TError,
        {slug: string;data: SemanticCompareCreate},
        TContext
      > => {

      const mutationOptions = getCreateSemanticCompareMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a list of project compares
 * @summary Get Compare
 */
export const getCompare = (
    slug: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Compare[]>(
      {url: `/v1/projects/${slug}/compare/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCompareQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/compare/`] as const;
    }

    
export const getGetCompareQueryOptions = <TData = Awaited<ReturnType<typeof getCompare>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompare>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompareQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompare>>> = ({ signal }) => getCompare(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompare>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCompareQueryResult = NonNullable<Awaited<ReturnType<typeof getCompare>>>
export type GetCompareQueryError = HTTPValidationError

/**
 * @summary Get Compare
 */
export const useGetCompare = <TData = Awaited<ReturnType<typeof getCompare>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompare>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCompareQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a compare by id
 * @summary Get Compare By Id
 */
export const getCompareById = (
    slug: string,
    compareId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<GetCompareById201>(
      {url: `/v1/projects/${slug}/compare/${compareId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCompareByIdQueryKey = (slug: string,
    compareId: string,) => {
    return [`/v1/projects/${slug}/compare/${compareId}`] as const;
    }

    
export const getGetCompareByIdQueryOptions = <TData = Awaited<ReturnType<typeof getCompareById>>, TError = HTTPValidationError>(slug: string,
    compareId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompareById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompareByIdQueryKey(slug,compareId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompareById>>> = ({ signal }) => getCompareById(slug,compareId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && compareId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompareById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCompareByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCompareById>>>
export type GetCompareByIdQueryError = HTTPValidationError

/**
 * @summary Get Compare By Id
 */
export const useGetCompareById = <TData = Awaited<ReturnType<typeof getCompareById>>, TError = HTTPValidationError>(
 slug: string,
    compareId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompareById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCompareByIdQueryOptions(slug,compareId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a compare name by id
 * @summary Update Compare Name
 */
export const updateCompareName = (
    slug: string,
    compareId: string,
    compareResultUpdate: CompareResultUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<Compare>(
      {url: `/v1/projects/${slug}/compare/${compareId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: compareResultUpdate
    },
      options);
    }
  


export const getUpdateCompareNameMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCompareName>>, TError,{slug: string;compareId: string;data: CompareResultUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCompareName>>, TError,{slug: string;compareId: string;data: CompareResultUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompareName>>, {slug: string;compareId: string;data: CompareResultUpdate}> = (props) => {
          const {slug,compareId,data} = props ?? {};

          return  updateCompareName(slug,compareId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCompareNameMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompareName>>>
    export type UpdateCompareNameMutationBody = CompareResultUpdate
    export type UpdateCompareNameMutationError = HTTPValidationError

    /**
 * @summary Update Compare Name
 */
export const useUpdateCompareName = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCompareName>>, TError,{slug: string;compareId: string;data: CompareResultUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCompareName>>,
        TError,
        {slug: string;compareId: string;data: CompareResultUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateCompareNameMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a compare by id
 * @summary Delete Compare
 */
export const deleteCompare = (
    slug: string,
    compareId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/compare/${compareId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCompareMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompare>>, TError,{slug: string;compareId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompare>>, TError,{slug: string;compareId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompare>>, {slug: string;compareId: string}> = (props) => {
          const {slug,compareId} = props ?? {};

          return  deleteCompare(slug,compareId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCompareMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompare>>>
    
    export type DeleteCompareMutationError = HTTPValidationError

    /**
 * @summary Delete Compare
 */
export const useDeleteCompare = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompare>>, TError,{slug: string;compareId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCompare>>,
        TError,
        {slug: string;compareId: string},
        TContext
      > => {

      const mutationOptions = getDeleteCompareMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a compare by id
 * @summary Update Overlay Compare
 */
export const updateOverlayCompare = (
    slug: string,
    compareId: string,
    overlayCompareUpdate: OverlayCompareUpdate,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<OverlayCompare>(
      {url: `/v1/projects/${slug}/compare/overlay/${compareId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: overlayCompareUpdate
    },
      options);
    }
  


export const getUpdateOverlayCompareMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOverlayCompare>>, TError,{slug: string;compareId: string;data: OverlayCompareUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOverlayCompare>>, TError,{slug: string;compareId: string;data: OverlayCompareUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOverlayCompare>>, {slug: string;compareId: string;data: OverlayCompareUpdate}> = (props) => {
          const {slug,compareId,data} = props ?? {};

          return  updateOverlayCompare(slug,compareId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOverlayCompareMutationResult = NonNullable<Awaited<ReturnType<typeof updateOverlayCompare>>>
    export type UpdateOverlayCompareMutationBody = OverlayCompareUpdate
    export type UpdateOverlayCompareMutationError = HTTPValidationError

    /**
 * @summary Update Overlay Compare
 */
export const useUpdateOverlayCompare = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOverlayCompare>>, TError,{slug: string;compareId: string;data: OverlayCompareUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateOverlayCompare>>,
        TError,
        {slug: string;compareId: string;data: OverlayCompareUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateOverlayCompareMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a compare result done by id
 * @summary Update Compare Result Done
 */
export const updateCompareResultDone = (
    slug: string,
    compareId: string,
    compareResultsUpdateList: CompareResultsUpdateList,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<UpdateCompareResultDone200>(
      {url: `/v1/projects/${slug}/compare/${compareId}/result/done`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: compareResultsUpdateList
    },
      options);
    }
  


export const getUpdateCompareResultDoneMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCompareResultDone>>, TError,{slug: string;compareId: string;data: CompareResultsUpdateList}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCompareResultDone>>, TError,{slug: string;compareId: string;data: CompareResultsUpdateList}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompareResultDone>>, {slug: string;compareId: string;data: CompareResultsUpdateList}> = (props) => {
          const {slug,compareId,data} = props ?? {};

          return  updateCompareResultDone(slug,compareId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCompareResultDoneMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompareResultDone>>>
    export type UpdateCompareResultDoneMutationBody = CompareResultsUpdateList
    export type UpdateCompareResultDoneMutationError = HTTPValidationError

    /**
 * @summary Update Compare Result Done
 */
export const useUpdateCompareResultDone = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCompareResultDone>>, TError,{slug: string;compareId: string;data: CompareResultsUpdateList}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCompareResultDone>>,
        TError,
        {slug: string;compareId: string;data: CompareResultsUpdateList},
        TContext
      > => {

      const mutationOptions = getUpdateCompareResultDoneMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a compare result by id
 * @summary Delete Compare Result
 */
export const deleteCompareResult = (
    slug: string,
    compareId: string,
    deleteCompareResultBody: string[],
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<ExactCompare>(
      {url: `/v1/projects/${slug}/compare/${compareId}/results`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteCompareResultBody
    },
      options);
    }
  


export const getDeleteCompareResultMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompareResult>>, TError,{slug: string;compareId: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompareResult>>, TError,{slug: string;compareId: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompareResult>>, {slug: string;compareId: string;data: string[]}> = (props) => {
          const {slug,compareId,data} = props ?? {};

          return  deleteCompareResult(slug,compareId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCompareResultMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompareResult>>>
    export type DeleteCompareResultMutationBody = string[]
    export type DeleteCompareResultMutationError = HTTPValidationError

    /**
 * @summary Delete Compare Result
 */
export const useDeleteCompareResult = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompareResult>>, TError,{slug: string;compareId: string;data: string[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCompareResult>>,
        TError,
        {slug: string;compareId: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getDeleteCompareResultMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Mentions By Type
 */
export const getMentionsByType = (
    params?: GetMentionsByTypeParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Mention[]>(
      {url: `/v1/mentions/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetMentionsByTypeQueryKey = (params?: GetMentionsByTypeParams,) => {
    return [`/v1/mentions/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMentionsByTypeQueryOptions = <TData = Awaited<ReturnType<typeof getMentionsByType>>, TError = HTTPValidationError>(params?: GetMentionsByTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMentionsByType>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMentionsByTypeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMentionsByType>>> = ({ signal }) => getMentionsByType(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMentionsByType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMentionsByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getMentionsByType>>>
export type GetMentionsByTypeQueryError = HTTPValidationError

/**
 * @summary Get Mentions By Type
 */
export const useGetMentionsByType = <TData = Awaited<ReturnType<typeof getMentionsByType>>, TError = HTTPValidationError>(
 params?: GetMentionsByTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMentionsByType>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMentionsByTypeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Learning Center Items
 */
export const getLearningCenterItems = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<LearningCenterItem[]>(
      {url: `/v1/main-page/learning-center`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLearningCenterItemsQueryKey = () => {
    return [`/v1/main-page/learning-center`] as const;
    }

    
export const getGetLearningCenterItemsQueryOptions = <TData = Awaited<ReturnType<typeof getLearningCenterItems>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItems>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLearningCenterItemsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLearningCenterItems>>> = ({ signal }) => getLearningCenterItems(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItems>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLearningCenterItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getLearningCenterItems>>>
export type GetLearningCenterItemsQueryError = unknown

/**
 * @summary Get Learning Center Items
 */
export const useGetLearningCenterItems = <TData = Awaited<ReturnType<typeof getLearningCenterItems>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItems>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLearningCenterItemsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Learning Center Item
 */
export const createLearningCenterItem = (
    learningCenterItem: LearningCenterItem,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<LearningCenterItem>(
      {url: `/v1/main-page/learning-center`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: learningCenterItem
    },
      options);
    }
  


export const getCreateLearningCenterItemMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLearningCenterItem>>, TError,{data: LearningCenterItem}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createLearningCenterItem>>, TError,{data: LearningCenterItem}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLearningCenterItem>>, {data: LearningCenterItem}> = (props) => {
          const {data} = props ?? {};

          return  createLearningCenterItem(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateLearningCenterItemMutationResult = NonNullable<Awaited<ReturnType<typeof createLearningCenterItem>>>
    export type CreateLearningCenterItemMutationBody = LearningCenterItem
    export type CreateLearningCenterItemMutationError = HTTPValidationError

    /**
 * @summary Create Learning Center Item
 */
export const useCreateLearningCenterItem = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLearningCenterItem>>, TError,{data: LearningCenterItem}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof createLearningCenterItem>>,
        TError,
        {data: LearningCenterItem},
        TContext
      > => {

      const mutationOptions = getCreateLearningCenterItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Learning Center Item By Id
 */
export const getLearningCenterItemById = (
    itemId: string,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<LearningCenterItem>(
      {url: `/v1/main-page/learning-center/${itemId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLearningCenterItemByIdQueryKey = (itemId: string,) => {
    return [`/v1/main-page/learning-center/${itemId}`] as const;
    }

    
export const getGetLearningCenterItemByIdQueryOptions = <TData = Awaited<ReturnType<typeof getLearningCenterItemById>>, TError = HTTPValidationError>(itemId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLearningCenterItemByIdQueryKey(itemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLearningCenterItemById>>> = ({ signal }) => getLearningCenterItemById(itemId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(itemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLearningCenterItemByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLearningCenterItemById>>>
export type GetLearningCenterItemByIdQueryError = HTTPValidationError

/**
 * @summary Get Learning Center Item By Id
 */
export const useGetLearningCenterItemById = <TData = Awaited<ReturnType<typeof getLearningCenterItemById>>, TError = HTTPValidationError>(
 itemId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemById>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLearningCenterItemByIdQueryOptions(itemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Learning Center Item
 */
export const updateLearningCenterItem = (
    itemId: string,
    learningCenterItem: LearningCenterItem,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<LearningCenterItem>(
      {url: `/v1/main-page/learning-center/${itemId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: learningCenterItem
    },
      options);
    }
  


export const getUpdateLearningCenterItemMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLearningCenterItem>>, TError,{itemId: string;data: LearningCenterItem}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateLearningCenterItem>>, TError,{itemId: string;data: LearningCenterItem}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLearningCenterItem>>, {itemId: string;data: LearningCenterItem}> = (props) => {
          const {itemId,data} = props ?? {};

          return  updateLearningCenterItem(itemId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateLearningCenterItemMutationResult = NonNullable<Awaited<ReturnType<typeof updateLearningCenterItem>>>
    export type UpdateLearningCenterItemMutationBody = LearningCenterItem
    export type UpdateLearningCenterItemMutationError = HTTPValidationError

    /**
 * @summary Update Learning Center Item
 */
export const useUpdateLearningCenterItem = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLearningCenterItem>>, TError,{itemId: string;data: LearningCenterItem}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateLearningCenterItem>>,
        TError,
        {itemId: string;data: LearningCenterItem},
        TContext
      > => {

      const mutationOptions = getUpdateLearningCenterItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Learning Center Item
 */
export const deleteLearningCenterItem = (
    itemId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<void>(
      {url: `/v1/main-page/learning-center/${itemId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteLearningCenterItemMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLearningCenterItem>>, TError,{itemId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteLearningCenterItem>>, TError,{itemId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLearningCenterItem>>, {itemId: string}> = (props) => {
          const {itemId} = props ?? {};

          return  deleteLearningCenterItem(itemId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLearningCenterItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLearningCenterItem>>>
    
    export type DeleteLearningCenterItemMutationError = HTTPValidationError

    /**
 * @summary Delete Learning Center Item
 */
export const useDeleteLearningCenterItem = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLearningCenterItem>>, TError,{itemId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLearningCenterItem>>,
        TError,
        {itemId: string},
        TContext
      > => {

      const mutationOptions = getDeleteLearningCenterItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Learning Center Items By Type
 */
export const getLearningCenterItemsByType = (
    params: GetLearningCenterItemsByTypeParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/main-page/learning-center/type/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLearningCenterItemsByTypeQueryKey = (params: GetLearningCenterItemsByTypeParams,) => {
    return [`/v1/main-page/learning-center/type/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLearningCenterItemsByTypeQueryOptions = <TData = Awaited<ReturnType<typeof getLearningCenterItemsByType>>, TError = HTTPValidationError>(params: GetLearningCenterItemsByTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemsByType>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLearningCenterItemsByTypeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLearningCenterItemsByType>>> = ({ signal }) => getLearningCenterItemsByType(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemsByType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLearningCenterItemsByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getLearningCenterItemsByType>>>
export type GetLearningCenterItemsByTypeQueryError = HTTPValidationError

/**
 * @summary Get Learning Center Items By Type
 */
export const useGetLearningCenterItemsByType = <TData = Awaited<ReturnType<typeof getLearningCenterItemsByType>>, TError = HTTPValidationError>(
 params: GetLearningCenterItemsByTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLearningCenterItemsByType>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLearningCenterItemsByTypeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Microsoft Token
 */
export const getMicrosoftToken = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/microsoft/get_token`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMicrosoftTokenQueryKey = () => {
    return [`/v1/integrations/microsoft/get_token`] as const;
    }

    
export const getGetMicrosoftTokenQueryOptions = <TData = Awaited<ReturnType<typeof getMicrosoftToken>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMicrosoftTokenQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMicrosoftToken>>> = ({ signal }) => getMicrosoftToken(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMicrosoftTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getMicrosoftToken>>>
export type GetMicrosoftTokenQueryError = unknown

/**
 * @summary Get Microsoft Token
 */
export const useGetMicrosoftToken = <TData = Awaited<ReturnType<typeof getMicrosoftToken>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMicrosoftTokenQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Microsoft Files
 */
export const getMicrosoftFiles = (
    params?: GetMicrosoftFilesParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/microsoft/onedrive/get_files`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetMicrosoftFilesQueryKey = (params?: GetMicrosoftFilesParams,) => {
    return [`/v1/integrations/microsoft/onedrive/get_files`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMicrosoftFilesQueryOptions = <TData = Awaited<ReturnType<typeof getMicrosoftFiles>>, TError = HTTPValidationError>(params?: GetMicrosoftFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMicrosoftFilesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMicrosoftFiles>>> = ({ signal }) => getMicrosoftFiles(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMicrosoftFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getMicrosoftFiles>>>
export type GetMicrosoftFilesQueryError = HTTPValidationError

/**
 * @summary Get Microsoft Files
 */
export const useGetMicrosoftFiles = <TData = Awaited<ReturnType<typeof getMicrosoftFiles>>, TError = HTTPValidationError>(
 params?: GetMicrosoftFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMicrosoftFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMicrosoftFilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Import Microsoft File
 */
export const importMicrosoftFile = (
    slug: string,
    microsoftFile: MicrosoftFile,
    params?: ImportMicrosoftFileParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/microsoft/onedrive/${slug}/import_document`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: microsoftFile,
        params
    },
      options);
    }
  


export const getImportMicrosoftFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importMicrosoftFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportMicrosoftFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof importMicrosoftFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportMicrosoftFileParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importMicrosoftFile>>, {slug: string;data: MicrosoftFile;params?: ImportMicrosoftFileParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  importMicrosoftFile(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportMicrosoftFileMutationResult = NonNullable<Awaited<ReturnType<typeof importMicrosoftFile>>>
    export type ImportMicrosoftFileMutationBody = MicrosoftFile
    export type ImportMicrosoftFileMutationError = HTTPValidationError

    /**
 * @summary Import Microsoft File
 */
export const useImportMicrosoftFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importMicrosoftFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportMicrosoftFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof importMicrosoftFile>>,
        TError,
        {slug: string;data: MicrosoftFile;params?: ImportMicrosoftFileParams},
        TContext
      > => {

      const mutationOptions = getImportMicrosoftFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * " Get available SharePoint sites for the user from sharepoint API, if not available (quite likely), try to get from organization (integrations_settings)
 * @summary Get Sharepoint Sites
 */
export const getSharepointSites = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Site[]>(
      {url: `/v1/integrations/microsoft/sharepoint/get_sites`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSharepointSitesQueryKey = () => {
    return [`/v1/integrations/microsoft/sharepoint/get_sites`] as const;
    }

    
export const getGetSharepointSitesQueryOptions = <TData = Awaited<ReturnType<typeof getSharepointSites>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharepointSites>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharepointSitesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharepointSites>>> = ({ signal }) => getSharepointSites(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSharepointSites>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharepointSitesQueryResult = NonNullable<Awaited<ReturnType<typeof getSharepointSites>>>
export type GetSharepointSitesQueryError = unknown

/**
 * @summary Get Sharepoint Sites
 */
export const useGetSharepointSites = <TData = Awaited<ReturnType<typeof getSharepointSites>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharepointSites>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharepointSitesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Sharepoint Files
 */
export const getSharepointFiles = (
    params: GetSharepointFilesParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/microsoft/sharepoint/get_files`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSharepointFilesQueryKey = (params: GetSharepointFilesParams,) => {
    return [`/v1/integrations/microsoft/sharepoint/get_files`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSharepointFilesQueryOptions = <TData = Awaited<ReturnType<typeof getSharepointFiles>>, TError = HTTPValidationError>(params: GetSharepointFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharepointFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharepointFilesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharepointFiles>>> = ({ signal }) => getSharepointFiles(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSharepointFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharepointFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getSharepointFiles>>>
export type GetSharepointFilesQueryError = HTTPValidationError

/**
 * @summary Get Sharepoint Files
 */
export const useGetSharepointFiles = <TData = Awaited<ReturnType<typeof getSharepointFiles>>, TError = HTTPValidationError>(
 params: GetSharepointFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSharepointFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharepointFilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Import Sharepoint File
 */
export const importSharepointFile = (
    slug: string,
    microsoftFile: MicrosoftFile,
    params?: ImportSharepointFileParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/microsoft/sharepoint/${slug}/import_document`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: microsoftFile,
        params
    },
      options);
    }
  


export const getImportSharepointFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importSharepointFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportSharepointFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof importSharepointFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportSharepointFileParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importSharepointFile>>, {slug: string;data: MicrosoftFile;params?: ImportSharepointFileParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  importSharepointFile(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportSharepointFileMutationResult = NonNullable<Awaited<ReturnType<typeof importSharepointFile>>>
    export type ImportSharepointFileMutationBody = MicrosoftFile
    export type ImportSharepointFileMutationError = HTTPValidationError

    /**
 * @summary Import Sharepoint File
 */
export const useImportSharepointFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importSharepointFile>>, TError,{slug: string;data: MicrosoftFile;params?: ImportSharepointFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof importSharepointFile>>,
        TError,
        {slug: string;data: MicrosoftFile;params?: ImportSharepointFileParams},
        TContext
      > => {

      const mutationOptions = getImportSharepointFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Google Token
 */
export const getGoogleToken = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/google/get_token`, method: 'GET', signal
    },
      options);
    }
  

export const getGetGoogleTokenQueryKey = () => {
    return [`/v1/integrations/google/get_token`] as const;
    }

    
export const getGetGoogleTokenQueryOptions = <TData = Awaited<ReturnType<typeof getGoogleToken>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGoogleToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGoogleTokenQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGoogleToken>>> = ({ signal }) => getGoogleToken(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGoogleToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGoogleTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getGoogleToken>>>
export type GetGoogleTokenQueryError = unknown

/**
 * @summary Get Google Token
 */
export const useGetGoogleToken = <TData = Awaited<ReturnType<typeof getGoogleToken>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGoogleToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGoogleTokenQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Import Document
 */
export const importDocument = (
    slug: string,
    googleFile: GoogleFile,
    params?: ImportDocumentParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/google/${slug}/import_document`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: googleFile,
        params
    },
      options);
    }
  


export const getImportDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importDocument>>, TError,{slug: string;data: GoogleFile;params?: ImportDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof importDocument>>, TError,{slug: string;data: GoogleFile;params?: ImportDocumentParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importDocument>>, {slug: string;data: GoogleFile;params?: ImportDocumentParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  importDocument(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportDocumentMutationResult = NonNullable<Awaited<ReturnType<typeof importDocument>>>
    export type ImportDocumentMutationBody = GoogleFile
    export type ImportDocumentMutationError = HTTPValidationError

    /**
 * @summary Import Document
 */
export const useImportDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importDocument>>, TError,{slug: string;data: GoogleFile;params?: ImportDocumentParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof importDocument>>,
        TError,
        {slug: string;data: GoogleFile;params?: ImportDocumentParams},
        TContext
      > => {

      const mutationOptions = getImportDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Files
 */
export const listFiles = (
    params?: ListFilesParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/google/get_files`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getListFilesQueryKey = (params?: ListFilesParams,) => {
    return [`/v1/integrations/google/get_files`, ...(params ? [params]: [])] as const;
    }

    
export const getListFilesQueryOptions = <TData = Awaited<ReturnType<typeof listFiles>>, TError = HTTPValidationError>(params?: ListFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFilesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFiles>>> = ({ signal }) => listFiles(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type ListFilesQueryResult = NonNullable<Awaited<ReturnType<typeof listFiles>>>
export type ListFilesQueryError = HTTPValidationError

/**
 * @summary List Files
 */
export const useListFiles = <TData = Awaited<ReturnType<typeof listFiles>>, TError = HTTPValidationError>(
 params?: ListFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Autodesk Token
 */
export const getAutodeskToken = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/autodesk/get_token`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAutodeskTokenQueryKey = () => {
    return [`/v1/integrations/autodesk/get_token`] as const;
    }

    
export const getGetAutodeskTokenQueryOptions = <TData = Awaited<ReturnType<typeof getAutodeskToken>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAutodeskTokenQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutodeskToken>>> = ({ signal }) => getAutodeskToken(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAutodeskToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAutodeskTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getAutodeskToken>>>
export type GetAutodeskTokenQueryError = unknown

/**
 * @summary Get Autodesk Token
 */
export const useGetAutodeskToken = <TData = Awaited<ReturnType<typeof getAutodeskToken>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskToken>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAutodeskTokenQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Autodesk Hubs
 */
export const getAutodeskHubs = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/autodesk/get_hubs`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAutodeskHubsQueryKey = () => {
    return [`/v1/integrations/autodesk/get_hubs`] as const;
    }

    
export const getGetAutodeskHubsQueryOptions = <TData = Awaited<ReturnType<typeof getAutodeskHubs>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskHubs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAutodeskHubsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutodeskHubs>>> = ({ signal }) => getAutodeskHubs(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAutodeskHubs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAutodeskHubsQueryResult = NonNullable<Awaited<ReturnType<typeof getAutodeskHubs>>>
export type GetAutodeskHubsQueryError = unknown

/**
 * @summary Get Autodesk Hubs
 */
export const useGetAutodeskHubs = <TData = Awaited<ReturnType<typeof getAutodeskHubs>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskHubs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAutodeskHubsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Autodesk Projects
 */
export const getAutodeskProjects = (
    params: GetAutodeskProjectsParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/autodesk/get_projects`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAutodeskProjectsQueryKey = (params: GetAutodeskProjectsParams,) => {
    return [`/v1/integrations/autodesk/get_projects`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAutodeskProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getAutodeskProjects>>, TError = HTTPValidationError>(params: GetAutodeskProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAutodeskProjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutodeskProjects>>> = ({ signal }) => getAutodeskProjects(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAutodeskProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAutodeskProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getAutodeskProjects>>>
export type GetAutodeskProjectsQueryError = HTTPValidationError

/**
 * @summary Get Autodesk Projects
 */
export const useGetAutodeskProjects = <TData = Awaited<ReturnType<typeof getAutodeskProjects>>, TError = HTTPValidationError>(
 params: GetAutodeskProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAutodeskProjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Autodesk Files
 */
export const getAutodeskFiles = (
    params: GetAutodeskFilesParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/autodesk/get_files`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAutodeskFilesQueryKey = (params: GetAutodeskFilesParams,) => {
    return [`/v1/integrations/autodesk/get_files`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAutodeskFilesQueryOptions = <TData = Awaited<ReturnType<typeof getAutodeskFiles>>, TError = HTTPValidationError>(params: GetAutodeskFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAutodeskFilesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutodeskFiles>>> = ({ signal }) => getAutodeskFiles(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAutodeskFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAutodeskFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getAutodeskFiles>>>
export type GetAutodeskFilesQueryError = HTTPValidationError

/**
 * @summary Get Autodesk Files
 */
export const useGetAutodeskFiles = <TData = Awaited<ReturnType<typeof getAutodeskFiles>>, TError = HTTPValidationError>(
 params: GetAutodeskFilesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAutodeskFiles>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAutodeskFilesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Import Autodesk File
 */
export const importAutodeskFile = (
    slug: string,
    autodeskFile: AutodeskFile,
    params?: ImportAutodeskFileParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/integrations/autodesk/${slug}/import_document`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: autodeskFile,
        params
    },
      options);
    }
  


export const getImportAutodeskFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importAutodeskFile>>, TError,{slug: string;data: AutodeskFile;params?: ImportAutodeskFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof importAutodeskFile>>, TError,{slug: string;data: AutodeskFile;params?: ImportAutodeskFileParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importAutodeskFile>>, {slug: string;data: AutodeskFile;params?: ImportAutodeskFileParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  importAutodeskFile(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportAutodeskFileMutationResult = NonNullable<Awaited<ReturnType<typeof importAutodeskFile>>>
    export type ImportAutodeskFileMutationBody = AutodeskFile
    export type ImportAutodeskFileMutationError = HTTPValidationError

    /**
 * @summary Import Autodesk File
 */
export const useImportAutodeskFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importAutodeskFile>>, TError,{slug: string;data: AutodeskFile;params?: ImportAutodeskFileParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof importAutodeskFile>>,
        TError,
        {slug: string;data: AutodeskFile;params?: ImportAutodeskFileParams},
        TContext
      > => {

      const mutationOptions = getImportAutodeskFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Test
 */
export const test = (
    params?: TestParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/voice-chat/test`, method: 'POST',
        params
    },
      options);
    }
  


export const getTestMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof test>>, TError,{params?: TestParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof test>>, TError,{params?: TestParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof test>>, {params?: TestParams}> = (props) => {
          const {params} = props ?? {};

          return  test(params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TestMutationResult = NonNullable<Awaited<ReturnType<typeof test>>>
    
    export type TestMutationError = HTTPValidationError

    /**
 * @summary Test
 */
export const useTest = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof test>>, TError,{params?: TestParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof test>>,
        TError,
        {params?: TestParams},
        TContext
      > => {

      const mutationOptions = getTestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Upload File With Vdb Research
 */
export const uploadFileWithVdbResearch = (
    slug: string,
    bodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost,
    params: UploadFileWithVdbResearchParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost.file)

      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      options);
    }
  


export const getUploadFileWithVdbResearchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdbResearch>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdbResearch>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFileWithVdbResearch>>, {slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  uploadFileWithVdbResearch(slug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadFileWithVdbResearchMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFileWithVdbResearch>>>
    export type UploadFileWithVdbResearchMutationBody = BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost
    export type UploadFileWithVdbResearchMutationError = HTTPValidationError

    /**
 * @summary Upload File With Vdb Research
 */
export const useUploadFileWithVdbResearch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileWithVdbResearch>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadFileWithVdbResearch>>,
        TError,
        {slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams},
        TContext
      > => {

      const mutationOptions = getUploadFileWithVdbResearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chat With Image
 */
export const chatWithImage = (
    slug: string,
    params: ChatWithImageParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/chat_with_image`, method: 'POST',
        params
    },
      options);
    }
  


export const getChatWithImageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithImage>>, TError,{slug: string;params: ChatWithImageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof chatWithImage>>, TError,{slug: string;params: ChatWithImageParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof chatWithImage>>, {slug: string;params: ChatWithImageParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  chatWithImage(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChatWithImageMutationResult = NonNullable<Awaited<ReturnType<typeof chatWithImage>>>
    
    export type ChatWithImageMutationError = HTTPValidationError

    /**
 * @summary Chat With Image
 */
export const useChatWithImage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof chatWithImage>>, TError,{slug: string;params: ChatWithImageParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof chatWithImage>>,
        TError,
        {slug: string;params: ChatWithImageParams},
        TContext
      > => {

      const mutationOptions = getChatWithImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Document Pages Names
 */
export const updateDocumentPagesNames = (
    slug: string,
    params: UpdateDocumentPagesNamesParams,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/update_document_pages_names`, method: 'POST',
        params
    },
      options);
    }
  


export const getUpdateDocumentPagesNamesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentPagesNames>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDocumentPagesNames>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDocumentPagesNames>>, {slug: string;params: UpdateDocumentPagesNamesParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  updateDocumentPagesNames(slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentPagesNamesMutationResult = NonNullable<Awaited<ReturnType<typeof updateDocumentPagesNames>>>
    
    export type UpdateDocumentPagesNamesMutationError = HTTPValidationError

    /**
 * @summary Update Document Pages Names
 */
export const useUpdateDocumentPagesNames = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentPagesNames>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateDocumentPagesNames>>,
        TError,
        {slug: string;params: UpdateDocumentPagesNamesParams},
        TContext
      > => {

      const mutationOptions = getUpdateDocumentPagesNamesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Files From Blob
 */
export const deleteFilesFromBlob = (
    slug: string,
    params: DeleteFilesFromBlobParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/delete_files_from_blob`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getDeleteFilesFromBlobQueryKey = (slug: string,
    params: DeleteFilesFromBlobParams,) => {
    return [`/v1/projects/${slug}/research/delete_files_from_blob`, ...(params ? [params]: [])] as const;
    }

    
export const getDeleteFilesFromBlobQueryOptions = <TData = Awaited<ReturnType<typeof deleteFilesFromBlob>>, TError = HTTPValidationError>(slug: string,
    params: DeleteFilesFromBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deleteFilesFromBlob>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeleteFilesFromBlobQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof deleteFilesFromBlob>>> = ({ signal }) => deleteFilesFromBlob(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof deleteFilesFromBlob>>, TError, TData> & { queryKey: QueryKey }
}

export type DeleteFilesFromBlobQueryResult = NonNullable<Awaited<ReturnType<typeof deleteFilesFromBlob>>>
export type DeleteFilesFromBlobQueryError = HTTPValidationError

/**
 * @summary Delete Files From Blob
 */
export const useDeleteFilesFromBlob = <TData = Awaited<ReturnType<typeof deleteFilesFromBlob>>, TError = HTTPValidationError>(
 slug: string,
    params: DeleteFilesFromBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deleteFilesFromBlob>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeleteFilesFromBlobQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Upload Vector Db To Blob
 */
export const uploadVectorDbToBlob = (
    slug: string,
    params: UploadVectorDbToBlobParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/upload_vector_db_to_blob`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getUploadVectorDbToBlobQueryKey = (slug: string,
    params: UploadVectorDbToBlobParams,) => {
    return [`/v1/projects/${slug}/research/upload_vector_db_to_blob`, ...(params ? [params]: [])] as const;
    }

    
export const getUploadVectorDbToBlobQueryOptions = <TData = Awaited<ReturnType<typeof uploadVectorDbToBlob>>, TError = HTTPValidationError>(slug: string,
    params: UploadVectorDbToBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploadVectorDbToBlob>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUploadVectorDbToBlobQueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof uploadVectorDbToBlob>>> = ({ signal }) => uploadVectorDbToBlob(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof uploadVectorDbToBlob>>, TError, TData> & { queryKey: QueryKey }
}

export type UploadVectorDbToBlobQueryResult = NonNullable<Awaited<ReturnType<typeof uploadVectorDbToBlob>>>
export type UploadVectorDbToBlobQueryError = HTTPValidationError

/**
 * @summary Upload Vector Db To Blob
 */
export const useUploadVectorDbToBlob = <TData = Awaited<ReturnType<typeof uploadVectorDbToBlob>>, TError = HTTPValidationError>(
 slug: string,
    params: UploadVectorDbToBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploadVectorDbToBlob>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUploadVectorDbToBlobQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents Document Id Annotation Research
 */
export const getProjectsIdDocumentsDocumentIdAnnotationResearch = (
    slug: string,
    documentId: string,
 options?: SecondParameter<typeof customAxios>,) => {
      
      
      return customAxios<unknown>(
      {url: `/v1/projects/${slug}/research/${documentId}/annotation`, method: 'POST'
    },
      options);
    }
  


export const getGetProjectsIdDocumentsDocumentIdAnnotationResearchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>, TError,{slug: string;documentId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>, TError,{slug: string;documentId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>, {slug: string;documentId: string}> = (props) => {
          const {slug,documentId} = props ?? {};

          return  getProjectsIdDocumentsDocumentIdAnnotationResearch(slug,documentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetProjectsIdDocumentsDocumentIdAnnotationResearchMutationResult = NonNullable<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>>
    
    export type GetProjectsIdDocumentsDocumentIdAnnotationResearchMutationError = HTTPValidationError

    /**
 * @summary Get Projects Id Documents Document Id Annotation Research
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationResearch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>, TError,{slug: string;documentId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationResult<
        Awaited<ReturnType<typeof getProjectsIdDocumentsDocumentIdAnnotationResearch>>,
        TError,
        {slug: string;documentId: string},
        TContext
      > => {

      const mutationOptions = getGetProjectsIdDocumentsDocumentIdAnnotationResearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Root
 */
export const root = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/`, method: 'GET', signal
    },
      options);
    }
  

export const getRootQueryKey = () => {
    return [`/`] as const;
    }

    
export const getRootQueryOptions = <TData = Awaited<ReturnType<typeof root>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof root>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRootQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof root>>> = ({ signal }) => root(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof root>>, TError, TData> & { queryKey: QueryKey }
}

export type RootQueryResult = NonNullable<Awaited<ReturnType<typeof root>>>
export type RootQueryError = unknown

/**
 * @summary Root
 */
export const useRoot = <TData = Awaited<ReturnType<typeof root>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof root>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRootQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Login endpoint
 * @summary Login
 */
export const login = (
    params: LoginParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Login200>(
      {url: `/login`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getLoginQueryKey = (params: LoginParams,) => {
    return [`/login`, ...(params ? [params]: [])] as const;
    }

    
export const getLoginQueryOptions = <TData = Awaited<ReturnType<typeof login>>, TError = HTTPValidationError>(params: LoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof login>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLoginQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof login>>> = ({ signal }) => login(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof login>>, TError, TData> & { queryKey: QueryKey }
}

export type LoginQueryResult = NonNullable<Awaited<ReturnType<typeof login>>>
export type LoginQueryError = HTTPValidationError

/**
 * @summary Login
 */
export const useLogin = <TData = Awaited<ReturnType<typeof login>>, TError = HTTPValidationError>(
 params: LoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof login>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLoginQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Health Check
 */
export const healthCheck = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<string>(
      {url: `/health`, method: 'GET', signal
    },
      options);
    }
  

export const getHealthCheckQueryKey = () => {
    return [`/health`] as const;
    }

    
export const getHealthCheckQueryOptions = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthCheckQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({ signal }) => healthCheck(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthCheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheck>>>
export type HealthCheckQueryError = unknown

/**
 * @summary Health Check
 */
export const useHealthCheck = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHealthCheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Returns the current API version
 * @summary Version
 */
export const version = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<string>(
      {url: `/version`, method: 'GET', signal
    },
      options);
    }
  

export const getVersionQueryKey = () => {
    return [`/version`] as const;
    }

    
export const getVersionQueryOptions = <TData = Awaited<ReturnType<typeof version>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof version>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVersionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof version>>> = ({ signal }) => version(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof version>>, TError, TData> & { queryKey: QueryKey }
}

export type VersionQueryResult = NonNullable<Awaited<ReturnType<typeof version>>>
export type VersionQueryError = unknown

/**
 * @summary Version
 */
export const useVersion = <TData = Awaited<ReturnType<typeof version>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof version>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getVersionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Returns the current environment
 * @summary Env
 */
export const env = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<string>(
      {url: `/env`, method: 'GET', signal
    },
      options);
    }
  

export const getEnvQueryKey = () => {
    return [`/env`] as const;
    }

    
export const getEnvQueryOptions = <TData = Awaited<ReturnType<typeof env>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof env>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof env>>> = ({ signal }) => env(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof env>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvQueryResult = NonNullable<Awaited<ReturnType<typeof env>>>
export type EnvQueryError = unknown

/**
 * @summary Env
 */
export const useEnv = <TData = Awaited<ReturnType<typeof env>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof env>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getEnvQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Runs the daily report celery task (the periodic one, incase we want to run it manually)
 * @summary Daily Report
 */
export const dailyReport = (
    params?: DailyReportParams,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<string>(
      {url: `/daily-report`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getDailyReportQueryKey = (params?: DailyReportParams,) => {
    return [`/daily-report`, ...(params ? [params]: [])] as const;
    }

    
export const getDailyReportQueryOptions = <TData = Awaited<ReturnType<typeof dailyReport>>, TError = HTTPValidationError>(params?: DailyReportParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dailyReport>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDailyReportQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof dailyReport>>> = ({ signal }) => dailyReport(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof dailyReport>>, TError, TData> & { queryKey: QueryKey }
}

export type DailyReportQueryResult = NonNullable<Awaited<ReturnType<typeof dailyReport>>>
export type DailyReportQueryError = HTTPValidationError

/**
 * @summary Daily Report
 */
export const useDailyReport = <TData = Awaited<ReturnType<typeof dailyReport>>, TError = HTTPValidationError>(
 params?: DailyReportParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof dailyReport>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDailyReportQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Settings
 */
export const updateSettingsUpdateSettingsGet = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<unknown>(
      {url: `/update-settings`, method: 'GET', signal
    },
      options);
    }
  

export const getUpdateSettingsUpdateSettingsGetQueryKey = () => {
    return [`/update-settings`] as const;
    }

    
export const getUpdateSettingsUpdateSettingsGetQueryOptions = <TData = Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUpdateSettingsUpdateSettingsGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>> = ({ signal }) => updateSettingsUpdateSettingsGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UpdateSettingsUpdateSettingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>>
export type UpdateSettingsUpdateSettingsGetQueryError = unknown

/**
 * @summary Update Settings
 */
export const useUpdateSettingsUpdateSettingsGet = <TData = Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof updateSettingsUpdateSettingsGet>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUpdateSettingsUpdateSettingsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




